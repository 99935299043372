<template>
    <div class = "shipping-address-view" v-loading = 'loading'>
        <div v-if = "!loading">
            <BasicInformationView v-if = "is_editable && !edits_saved && !show_contact_support_to_solve_duplicated_patient"/>
            <ShippingAddressInputsView v-if = "is_editable && !edits_saved && address_needed!=false && !show_contact_support_to_solve_duplicated_patient" />
            <ChangeAddressStatusView v-if = '!is_editable || edits_saved || show_contact_support_to_solve_duplicated_patient' />
            <OpsButtonsBar v-if = "is_editable && !edits_saved && !show_contact_support_to_solve_duplicated_patient"/>
        </div>
    </div>

</template>

<script>
import { useShippingAddressStore } from '@/stores/ShippingAddressStore.ts';
import { useCommonStore } from "@/stores/CommonStore.ts";
import ShippingAddressInputsView from './ShippingAddressInputsView.vue';
import ChangeAddressStatusView from './ChangeAddressStatusView.vue';
import BasicInformationView from "@/components/shippingAddress/BasicInformationView.vue";
import OpsButtonsBar from '@/components/shippingAddress/OpsButtonsBar.vue';
// import { env_version } from '@/components/shippingAddress/shippingAddressData/shippingAddressMockData.js';
import { mapState, mapActions } from 'pinia';
export default{
    components:{
        ShippingAddressInputsView,
        ChangeAddressStatusView,
        BasicInformationView,
        OpsButtonsBar,
    },
    setup(){
        const shippingAddressStore = useShippingAddressStore();
        return {
            shippingAddressStore,
        }
    },
    created(){
        console.log('env_version:', process.env, process.env.VUE_APP_ENV);
        this.setAccessionId(this.$route.query.accession_id);
        this.setSampleId(this.$route.query.sample_id);
        this.storeAllQueryInfomation(this.$route.query);
        // ------------ remove the redirect feature due to null pointer during refresh ----------------------
        // //if token, sample_id or accession_id in ShippingAddressStore are null or empty, means user refresh at the current page:
        // // -> need to get the query from the localStorage, and reset variables in store
        // if(!this.sample_id || !this.accession_id || !this.token){
        //     console.log('getLocalStorageQuery shipping_page_query',localStorage.getItem('shipping_page_query'));
        //     this.storeAllQueryInfomation(JSON.parse(localStorage.getItem('shipping_page_query')));
        // }
        this.getShippingAddressInfo();
        this.getPatientProfileAddress();
    },
    computed:{
        ...mapState(useShippingAddressStore,[
            'sample_id',
            'accession_id',
            'token',
            'is_editable',
            'edits_saved',
            'loading',
            'previous_shipping_address_data',
            'valid_concierge_states',
            'direct_to_blooddraw',
            'is_kit_delivered',
            'address_needed',
            'incomplete_address_in_db',
            'show_contact_support_to_solve_duplicated_patient',
        ]),
    },
    watch:{
        incomplete_address_in_db(newVal){
            if(newVal!=null){
                this.shippingAddressStore.is_apply_to_future_order = newVal;
                if(newVal){
                    console.log('0-1');
                    //matomo tracking 0-1 visiting
                    this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','0-1-visiting', 1);
                }else{
                    console.log('1-1.1');
                    //matomo tracking 1-1.1 visiting
                    this.$matomo && this.$matomo.trackEvent('pns','shipping-address-page','1-1.1-visiting', 1);
                }
            }
            console.log('incomplete_address_in_db change', newVal);
        },
        is_kit_delivered(newVal){
            //if is_editable is false, the kit is delivered
            if(newVal && this.direct_to_blooddraw){
                this.goToNextStep(this.$router);
            }
        }
    },
    methods:{
        ...mapActions(useShippingAddressStore,[
            'storeAllQueryInfomation',
            'getShippingAddressInfo',
            'getPatientProfileAddress',
            'updateIsConciergeValid',
            'goToNextStep'
        ]),
        ...mapActions(useCommonStore, [
            'setAccessionId',
            'setSampleId',
        ])
    }
}
</script>

<style scoped>
.shipping-address-view{
    min-height: calc(100vh - 62px);
    padding-bottom: 114px;
}
@media only screen and (max-width: 767px){
    .shipping-address-view{
        padding-bottom: 104px;
    }
}
</style>