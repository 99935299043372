<template>
  <div id = "page-container" >
    <div class = "header-container">
      <PageHeader :accession_id = "accession_id"/>
    </div>
    <div class = "content-container">
      <RouterView />
    </div>
    <img v-if = "showFooterImage(this.$route.path)" src = '@/assets/footerImage.svg' :class = "['footer-img',{'not-display':!is_editable||edits_saved}]"/>
    <div v-if = "show_vw_loading_animation" class = 'loading-animation-layer'>
      <div class = 'vw-loading-animation-icon-container'>
        <img src = "@/assets/VW_loading_animation_icon.gif"  class = "vw-loading-animation-icon" />
      </div>
    </div>
  </div>
  
</template>

<script>
import PageHeader from "@/components/commons/PageHeader.vue";
import { useCommonStore } from "@/stores/CommonStore.ts";
import { useShippingAddressStore } from '@/stores/ShippingAddressStore.ts';
import { mapState } from "pinia";

export default{
  components:{
    PageHeader,
  },
  computed: {
    ...mapState(useCommonStore, ["sample_id", "accession_id", 'show_vw_loading_animation']),
    ...mapState(useShippingAddressStore,[
      'is_editable',
      'edits_saved',
    ])
  },
  methods:{
    showFooterImage(route_path){
      return route_path == '/uploadShippingAddress'||route_path == '/fedexPickup'||route_path == '/fedexPickup/search';
    }, 
    matchBackgroundColor(route_path){
      // return route_path == "/questionnaire"?'white-bg':'light-blue-bg';
      return route_path == 'white-bg';
    }
  }
}
</script>

<style>
.header-container{
  width:100%;
  position:fixed;
  top:0;
  z-index: 100;
}
.content-container{
  height:100vh;
  /* padding-top is the same as the height of the header */
  padding-top:62px;
}
.footer-img{
  position:absolute;
  right:0;
  bottom:0;
  z-index:0;
}
.not-display{
  display: none;
}
.loading-animation-layer{
  width: 100%;
  height:100%;
  background-color: rgba(0, 0, 0, .5);
  position: fixed; /* Keep it relative to the viewport */
  top:0;
  z-index:1000;
}
.vw-loading-animation-icon-container{
  display: flex;
  width:100%;
  height:100vh;
  align-items: center;
  justify-content: center;
}
.vw-loading-animation-icon{
  width:40%;
}
@media only screen and (max-width:1260px){
  .footer-img{
    width: 200px;
  }
}
@media only screen and (max-width:767px){
  .content-container{
    height:100vh;
    /* padding-top is the same as the height of the header */
    padding-top:40px;
  }
  .footer-img{
    display: none;
  }
  .vw-loading-animation-icon{
    width:100%;
  }
}
</style>