<template>
    <div :class = "['area-code-phone-input',{'is-focus': is_the_whole_input_focus}, {'disabled':disabled}]" ref = "area-code-phone-input">
        <div :class = "['country-selector',{'empty': !selected_country}, {'filter':dropdown_visible}]" 
        :style = "{'--input-width--':`${input_width}px`}">
            <el-select v-model="selected_country" 
            ref = "country-selector"
            @visible-change ="(visible)=>{dropdown_visible = visible}"
            popper-class = "area-code-phone-input-country-selector-dropdown" 
            placeholder = "Country"
            :disabled = "disabled"
            :teleported = "false"
            filterable
            default-first-option>
                <template #prefix>
                    <div class = 'country-selector-content-wrapper'>
                        <img v-if = "selected_country && !dropdown_visible" :src = "country_flag_src" class = "country-flag-icon"/>
                        <img :src = "dropdown_arrow_src" 
                        :class = "['rotate-0',{'rotate-180':dropdown_visible},'area-code-dropdown-arrow']">
                        <div class = "country-selector-divider"></div>
                    </div>
                </template>
                <el-option
                v-for="item in country_options_for_phone"
                :key="item.countryNameTwoBytes"
                :value="item.countryNameTwoBytes">
                    <span>{{ item.countryName}} ({{ item.countryNameTwoBytes }})</span>
                    <span style = "margin-left:10px;">+ {{ country_code_to_area_code_map[item.countryNameTwoBytes] }}</span>
                </el-option>
            </el-select>
        </div>
        <!-- <img src = "@/assets/BloodDraw/vertical_divider_for_area_code.svg" /> -->
        <div :class = "['area-code-div',{'empty':!selected_country}]">
            + {{ area_code ? area_code : '__' }}
        </div>
        <el-input v-model="phone_number" placeholder="Phone Number"
        ref = "phone-number-input" 
        @keydown = "handleTypeInAction($event)"
        @focus = "is_phone_number_input_focused=true"
        @blur = "handlePhoneNumberInputBlur" 
        :disabled = "disabled"/>
        <img v-if = "selected_country || phone_number" src = "@/assets/input_clear_icon.svg" 
        :class = "['clear-icon']"
        @click="handleClearAllValue"/>
    </div>
</template>

<script>
import { country_code_to_area_code_map } from '@/components/bloodDraw/BloodDrawData/AreaCodeMap.js';
import { backup_country_options_for_phone } from "@/components/commons/ComponentsData/countryOptionsForPhone.js";
import gray_dropdown_arrow from '@/assets/gray_dropdown_arrow.svg';
import black_dropdown_arrow from "@/assets/BloodDraw/area_code_dropdown_arrow.svg";
import { debounce } from 'lodash';
export default{
    props:{
        modelValue:{
            //eg.{area_code:"1", phone_number:"2"}
            type:[Object,String],
            default:()=>{}
        },
        country_options:{
            type:Array,
            default:()=>[]
        },
        disabled:{
            type:Boolean,
            default:false,
        }
    },
    created(){
        if(typeof this.modelValue == 'string'){
            this.selected_country = "";
        }else{
            this.selected_country = this.getCountryCodeViaAreaCode(this.modelValue['area_code']);
            //if no matched selected_country, but has area_code:
            if(this.selected_country=='' && this.modelValue['area_code']){
                //clear the area_code
                //sync binding value
                this.$emit('update:modelValue', 
                    {
                        area_code: '',
                        phone_number:this.modelValue['phone_number'],
                    }
                );
                //for input changes
                this.$emit('input', 
                    {
                        area_code: '',
                        phone_number:this.modelValue['phone_number'],
                    }
                );
            }
        }

        this.syncInputFocusStatus = debounce((is_focus)=>{
            console.log('syncInputFocusStatus', is_focus);
            if(is_focus){
                this.$emit('focus',
                    {
                        area_code: this.area_code,
                        phone_number:this.phone_number,
                    }
                );
            }else{
                this.$emit('blur',
                    {
                        area_code: this.area_code,
                        phone_number:this.phone_number,
                    }
                );
            }
        //no need to delay to emit blur and focus event, 
        //as the blur event of phoneNumberInput (handlePhoneNumberInputBlur()) already handles the brief time interval between the blur event of phoneNumberInput and the focus event of countrySelector
        //via update is_phone_number_input_focused to be false after the dropdown is conditionally auto-opened.
        //NOTES: write as debounce, in case any condition need delay in the future.
        }, 0);
    },
    mounted(){
        if(this.$refs['area-code-phone-input']){
            this.input_width = this.$refs['area-code-phone-input'].offsetWidth;
        }
        console.log('area-code-phone-input', this.input_width, this.selected_country, this.phone_number);
    },
    data(){
        return{
            input_width:100,
            dropdown_visible:false,
            selected_country:'', //initialized in created()
            phone_number: typeof this.modelValue == 'string'? this.modelValue : this.modelValue['phone_number'],
            is_phone_number_input_focused:false,

            country_code_to_area_code_map,
            backup_country_options_for_phone,
        }
    },
    computed:{
        country_flag_src(){
            return `https://hatscripts.github.io/circle-flags/flags/${this.selected_country.toLowerCase()}.svg`;
        },
        dropdown_arrow_src(){
            return this.disabled ? gray_dropdown_arrow : black_dropdown_arrow;
        },
        area_code(){
            return this.selected_country ? this.country_code_to_area_code_map[this.selected_country]: "";
        },
        country_options_for_phone(){
            return !this.country_options || !Array.isArray(this.country_options) || this.country_options.length == 0 ? this.backup_country_options_for_phone : this.country_options;
        },
        is_the_whole_input_focus(){
            return this.dropdown_visible || this.is_phone_number_input_focused;
        }
    },
    emits:[
        //for v-model auto-update
        'update:modelValue',
        //for input changes
        'input',
        'focus',
        'blur',
    ],
    watch:{
        modelValue(newVal){
            // console.log('AreaCodePhoneNumberInput modalValue change', newVal);
            if(typeof newVal == 'string'){
                this.selected_country = '';
                this.phone_number = newVal;
            }else{
                this.selected_country = this.getCountryCodeViaAreaCode(newVal['area_code']);
                this.phone_number = newVal['phone_number'];
                //if no matched selected_country, but has area_code:
                if(this.selected_country=='' && newVal['area_code']){
                    //clear the area_code
                    //sync binding value
                    this.$emit('update:modelValue', 
                        {
                            area_code: this.area_code,
                            phone_number:this.phone_number,
                        }
                    );
                    //for input changes
                    this.$emit('input', 
                        {
                            area_code: this.area_code,
                            phone_number:this.phone_number,
                        }
                    );
                }
            }
        },
        selected_country(){
            // console.log('selected_country change', newVal);
            //for v-model auto-update
            this.$emit('update:modelValue', 
                {
                    area_code: this.area_code,
                    phone_number:this.phone_number,
                }
            );
            //for input changes
            this.$emit('input', 
                {
                    area_code: this.area_code,
                    phone_number:this.phone_number,
                }
            );
        },
        dropdown_visible(newVal){
            if(newVal){
            //sync dropdown width everytime when the dropdown appears
                if(this.$refs['area-code-phone-input']){
                    this.input_width = this.$refs['area-code-phone-input'].offsetWidth;
                }
            }else{
                //if has selected_country and no phone number, auto focus 'phone-number-input'
                if(this.selected_country && !this.phone_number && this.$refs['phone-number-input']){
                    this.$refs['phone-number-input'].focus();
                }
            }
        },
        phone_number(){
            // console.log('phone_number change', newVal);
            this.$emit('update:modelValue', 
                {
                    area_code: this.area_code,
                    phone_number:this.phone_number,
                }
            );
            this.$emit('input', 
                {
                    area_code: this.area_code,
                    phone_number:this.phone_number,
                }
            );
        },
        is_the_whole_input_focus(newVal){
            // console.log('is_the_whole_input_focus change', newVal);
            this.syncInputFocusStatus(newVal);
        }
    },
    methods:{
        getCountryCodeViaAreaCode(area_code){
            //if the area_code is 1, default to prefill with US, as multiple countries shares with the same area code.
            if(area_code == '1'){
                return "US";
            }
            for(const country_code in this.country_code_to_area_code_map){
                if(area_code == this.country_code_to_area_code_map[country_code]){
                    return country_code;
                }
            }
            return '';
        },
        handleTypeInAction(event){
            if (event.key === 'Enter') {
                console.log('Enter key pressed: blur phone Number');
                if(this.$refs['phone-number-input']){
                    this.$refs['phone-number-input'].blur();
                }
            }
        },
        handlePhoneNumberInputBlur(){
            // console.log('handlePhoneNumberInputBlur',this.selected_country=='',this.phone_number);
            setTimeout(()=>{
                if(this.phone_number && !this.selected_country && !this.dropdown_visible && this.$refs['country-selector']){
                    this.$refs['country-selector'].toggleMenu();
                }
                this.is_phone_number_input_focused=false;
            // 120 has interactive effect with the setTimeout's time interval to set error related data in checkContentValidation() in ShippingAddressInputUnit
            // at least, the time interval here should be 20 milliseconds more than the one in ShippingAddressInputUnit
            // otherwise, the other event will affect toggleMenu(), so that the dropdown of country-selector will only appears for a brief moment
            },120); 
        },
        handleClearAllValue(){
            this.selected_country = '';
            this.phone_number='';
            this.$emit('blur',
                {
                    area_code: this.area_code,
                    phone_number:this.phone_number,
                }
            );
        }
    }

}
</script>

<style scoped>
.area-code-phone-input{
    display: flex;
    align-items: center;
    width: 100%;
    border:1px solid #BCCCDC;
    border-radius: 4px;
    position: relative;
}
.area-code-phone-input:hover{
    border:1px solid #DEE6ED;
}
.area-code-phone-input.is-focus .clear-icon,
.area-code-phone-input:hover .clear-icon{
    display: block;
}
.clear-icon{
    display: none;
    cursor: pointer;
    opacity: 0.7;
    position: absolute;
    right: 11px;
}
.clear-icon:hover{
    opacity: 1;
}
.area-code-phone-input.is-focus{
    border:1px solid #9BB2CA;
}
.area-code-phone-input.disabled{
    border:1px solid #BCCCDC;
    background-color: #F2F2F2;
    cursor:not-allowed;
}
.area-code-phone-input :deep(.el-input){
    --el-input-border-color:transparent;
    --el-input-focus-border-color:transparent;
    --el-input-hover-border-color:transparent;

}
.area-code-phone-input :deep(.el-input__inner){
    height:fit-content;
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}
.country-selector-content-wrapper{
    display: flex;
    align-items: center;
    gap: 8px;
}
.country-selector{
    width: 81px;
    flex: 0 0 auto;
}
.country-selector.empty{
    width: 114px;
}
.country-selector :deep(.el-select){
    --el-select-border-color-hover: transparent;
    --el-select-input-focus-border-color: transparent;
    width: inherit;
}
.country-selector :deep(.el-select .el-input__suffix){
    display: none;
}
.country-selector :deep(.el-select .el-input__prefix){
    height: fit-content;
}
.country-selector :deep(.el-select .el-input__prefix-inner>:last-child){
    margin-right: 0px;
}
.country-selector :deep(.el-select .el-input__wrapper){
    height: 40px;
    padding: 8px 0px 8px 19px;
}

.area-code-phone-input :deep(.el-input__wrapper){
    padding: 0px 19px 0px 12px;
}
.area-code-phone-input :deep(.el-input.is-disabled .el-input__wrapper){
    box-shadow: 0 0 0 1px transparent inset;
}
.country-selector :deep(.el-select .el-input){
    /* --el-input-height:fit-content; */
    --el-input-border-color: transparent;
}
/* .country-selector :deep(.el-input__inner){
    display: none;
} */
.country-selector.filter :deep(.el-input__prefix),
.country-selector.empty :deep(.el-input__prefix){
    position:absolute;
    right:0px;
}
.country-flag-icon{
    width:24px;
    height:24px;
}
.country-selector-placeholder{
    color: #979797;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
}
.rotate-0{
    transform: rotate(0deg);
    transition: all 0.5s;
}
.rotate-180{
    transform:rotate(-180deg);
}
.country-selector-divider{
    margin-left:4px;
    display: block;
    width: 1px;
    height: 26px;
    background-color: #BCCCDC;
    border-radius: 2px;
}
.area-code-div{
    margin-left:12px;
    flex-shrink: 0;
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.3px;
}
.area-code-div.empty{
    color: #979797;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}

/* country-selector dropdown */
:deep(.area-code-phone-input-country-selector-dropdown.el-select__popper.el-popper){
  border: none;
  box-shadow: none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transform: translateY(-5px);
  width: var(--input-width--);
}
:deep(.area-code-phone-input-country-selector-dropdown.el-select-dropdown){
  border: 1px solid #BCCCDC;
  border-radius: inherit;
}
:deep(.area-code-phone-input-country-selector-dropdown .el-popper__arrow){
  display: none;
}
:deep(.area-code-phone-input-country-selector-dropdown .el-select-dropdown__list){
  padding:8px 11px !important;
}
:deep(.area-code-phone-input-country-selector-dropdown .el-select-dropdown__item){
  padding:12px 8px;
  height:fit-content;
  color: #1C1B1F;;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
  border-radius: 4px;
}
:deep(.area-code-phone-input-country-selector-dropdown .el-select-dropdown__item.hover),
:deep(.area-code-phone-input-country-selector-dropdown .el-select-dropdown__item:hover){
  background: #FAECE6;
  border-radius: 4px;
}
:deep(.area-code-phone-input-country-selector-dropdown .el-select-dropdown__item span){
  width:100%;
  white-space: pre-wrap;
  word-break: break-word;
}

@media only screen and (max-width:767px) {
    .country-selector{
        width:57px;
    }
    .country-selector.empty{
        width:88px;
    }
    .country-selector-content-wrapper{
        display: flex;
        align-items: center;
        gap: 4px;
    }
    .country-selector :deep(.el-select .el-input__wrapper){
        height: 34px;
        padding: 7px 0px 7px 11px;
    }
    .country-flag-icon{
        width:20px;
        height:20px;
    }
    .area-code-dropdown-arrow{
        width:16px;
        height:16px;
    }
    .country-selector-placeholder{
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
    }
    .country-selector-divider{
        margin-left:2px;
        display: block;
        width: 1px;
        height: 20px;
        background-color: #BCCCDC;
        border-radius: 2px;
    }
    .area-code-div{
        margin-left:6px;
        flex-shrink: 0;
        color: #1C1B1F;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; 
        letter-spacing: 0.25px;
    }
    .area-code-div.empty{
        color: #979797;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; 
        letter-spacing: 0.25px;
    }
    .area-code-phone-input :deep(.el-input__inner){
        color: #1C1B1F;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
    }
    .area-code-phone-input :deep(.el-input__wrapper){
        padding: 0px 11px 0px 6px;
    }
    :deep(.area-code-phone-input-country-selector-dropdown .el-select-dropdown__list){
        padding:7px !important;
    }
    :deep(.area-code-phone-input-country-selector-dropdown .el-select-dropdown__item){
        padding:6px 4px;
        height:fit-content;
        color: #1C1B1F;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        border-radius: 4px;
    } 
}
</style>