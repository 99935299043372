<template>
  <div class="paymentOverview">
    <div class="overviewHeader">
      <div>Payment Overview</div>
      <!-- if has billingInfo or payFixedAmount: show amountToPay even 0.00-->
      <div>Total: {{billingInfo || payFixedAmount? formatPrice(amountToPay) : "- - -" }}</div>
    </div>
    <div class="headerDivider" v-if="!payFixedAmount" />
    <div
      class="overviewContent"
      v-if="!payFixedAmount && !isProviderPaid"
    >
      <div class="font-medium">Tests Total</div>
      <div class="font-normal">
        {{ billingInfo ? formatPrice(billingInfo.subtotal) : "- - -" }}
      </div>
    </div>
    <!-- <div
      class="overviewContent"
      v-if="chargeMethod == 'patientPayLater' && !payFixedAmount"
    >
      <div class="font-medium">Consultation Fee</div>
      <div class="font-normal">
        {{ billingInfo ? formatPrice(billingInfo.consultation_fee) : "- - -" }}
      </div>
    </div> -->
    <div
      class="overviewContent"
      v-if="!payFixedAmount && !isProviderPaid"
    >
      <div class="font-medium">Tax</div>
      <div class="font-normal">
        {{ billingInfo ? formatPrice(billingInfo.tax) : "- - -" }}
      </div>
    </div>
    <div
      class="overviewContent"
      v-if="!payFixedAmount && !isProviderPaid"
    >
      <div class="font-medium">Lab Processing Fee</div>
      <div class="font-normal">
        {{ billingInfo ? formatPrice(billingInfo.va_processing_fee) : "- - -" }}
      </div>
    </div>
    <div
      class="overviewContent"
      v-if="!payFixedAmount && !isProviderPaid"
    >
      <div class="font-medium">International Shipping Fee</div>
      <div class="font-normal">
        {{
          billingInfo
            ? formatPrice(billingInfo.international_shipping_fee)
            : "- - -"
        }}
      </div>
    </div>
    <div class="overviewContent" v-if="!payFixedAmount">
      <div class="font-medium">Concierge Blood Draw Fee</div>
      <div class="font-normal">
        {{ billingInfo ? formatPrice(has_concierge_service ? "99.00" : "0.00") : "- - -" }}
      </div>
    </div>
    <div
      class="overviewContent"
      v-if="!payFixedAmount && !isProviderPaid"
    >
      <div class="font-medium">Discount</div>
      <div class="font-normal">
        {{ billingInfo ? formatPrice(billingInfo.discount) : "- - -" }}
      </div>
    </div>
    <div
      class="dashedDivider"
      v-if="!payFixedAmount && Number(billingInfo.consultation_fee)!=0 && !isProviderPaid"
    ></div>
    <div
      class="overviewContent"
      v-if="!payFixedAmount && Number(billingInfo.consultation_fee)!=0 && !isProviderPaid"
    >
      <div class="font-medium">{{ consultation_fee_display_name }}</div>
      <div class="font-normal">
        {{ billingInfo ? formatPrice(billingInfo.consultation_fee) : "- - -" }}
      </div>
    </div>
    <div
      class="dashedDivider"
      v-if="!payFixedAmount && !isProviderPaid"
    ></div>
    <div
      class="overviewContent"
      v-if="!payFixedAmount && !isProviderPaid"
    >
      <div class="font-medium">Applied Payment</div>
      <div class="font-normal">
        {{
          billingInfo
            ? formatPrice(appliedPayment > 0 ? -appliedPayment : appliedPayment)
            : "- - -"
        }}
      </div>
    </div>
    <!-- <div class="contentDivider" />
    <div class="promoContainer">
      <el-input v-model="promoCode" placeholder="Promo Code" class="promoInput">
        <template v-slot:prefix>
          <img src="@/assets/payment/promo.svg" alt="promo" />
        </template>
      </el-input>
      <el-button
        v-loading="loadingPromo"
        class="promoButton"
        @click="submitPromo"
        >Apply</el-button
      >
    </div> -->
  </div>
</template>

<script>
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { mapState, mapActions } from "pinia";
export default {
  name: "PaymentOverview",
  props: {
    payFixedAmount: Boolean,
  },
  data() {
    return {
      promoCode: "",
      // chargeMethod: localStorage.getItem("charge_method"),
      patientId: localStorage.getItem("patient_id"),
      // amountToPay: null,
    };
  },
  created() {
    // this.setAmountToPay();
  },
  computed: {
    ...mapState(usePaymentStore, [
      "billingInfo", 
      "loadingPromo",
      "consultation_fee_name",
      "has_concierge_service",
      "backend_has_concierge_service",
      'always_show_payment',
      'charge_method',
      // temporary use for blood concierge beta 1 launch - blood_concierge
      'blood_concierge_beta_1',
      // temporary use for blood concierge beta 1 launch - blood_concierge ------ end
    ]),
    isProviderPaid(){
      return this.charge_method == 'customerPay';
    },
    formatPrice() {
      return (price) => {
        return Number(price).toLocaleString("en-US", {
          style: "currency",
          currency: this.billingInfo.currency,
          minimumFractionDigits: 2,
        }).replace('CHF','₣');
      };
    },
    amountToPay(){
      const output = localStorage.getItem("amount_to_pay")
        ? parseFloat(
            (Number(localStorage.getItem("amount_to_pay")) / 100).toFixed(2)
          )
        : 
        (
          this.billingInfo
          ? 
          (
            this.need_to_manually_add_concierge_service_fee
            ?
            Number(this.billingInfo.total) + 99
            :
            (
              this.need_to_manually_deduct_concierge_service_fee
              ?
              Number(this.billingInfo.total) - 99
              :
              Number(this.billingInfo.total)
            )
          )
          : null
        );
        console.log('amount_to_pay', output);
        return output;
    },
    appliedPayment() {
      return this.billingInfo && this.billingInfo.applied_payments
        ? this.billingInfo.applied_payments.reduce(
            (sum, payment) => sum + Number(payment.amount),
            0
          )
        : 0;
    },
    consultation_fee_display_name(){
      return this.consultation_fee_name ? this.consultation_fee_name : "Consultation Fee";
    },
    need_to_manually_add_concierge_service_fee(){
      // console.log('need_to_manually_add_concierge_service_fee', this.has_concierge_service,this.backend_has_concierge_service);
      return this.has_concierge_service && !this.backend_has_concierge_service;
    },
    need_to_manually_deduct_concierge_service_fee(){
      // console.log('need_to_manually_deduct_concierge_service_fee', this.has_concierge_service,this.backend_has_concierge_service);
      return !this.has_concierge_service && this.backend_has_concierge_service;
    }
  },
  methods: {
    ...mapActions(usePaymentStore, ["applyPromo"]),
    // setAmountToPay() {
    //   this.amountToPay = localStorage.getItem("amount_to_pay")
    //     ? parseFloat(
    //         (Number(localStorage.getItem("amount_to_pay")) / 100).toFixed(2)
    //       )
    //     : 
    //     (
    //       this.billingInfo
    //       ? 
    //       Number(this.billingInfo.total)
    //       : null);
    // },
    async submitPromo() {
      this.$matomo &&
        this.$matomo.trackEvent("pns", "payment_page", "apply_coupon", 1);
      if (this.promoCode)
        try {
          await this.applyPromo(this.promoCode);
        } catch (error) {
          console.error(error);
        }
      this.promoCode = "";
    },
  },
};
</script>

<style scoped>
.paymentOverview {
  width: 56vw;
  /* min-width: 600px; */
  height: fit-content;
  border-radius: 10px;
  background-color: #0e4672;
  padding: 24px 38px;
}

.paymentOverview > div {
  margin-bottom: 12px;
}

.overviewHeader {
  display: flex;
  justify-content: space-between;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.51px;
  text-align: left;
  color: #ffffff;
}

.headerDivider {
  border: 0.5px solid #e6e6e6;
}

.contentDivider {
  border: 0.5px solid #ffffff;
}

.dashedDivider {
  border: 0.5px dashed #ffffff;
}

.overviewContent {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
  color: #ffffff;
}
.promoContainer {
  display: flex;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 0px !important;
}

.promoButton {
  width: 123px;
  height: 36px;
  border-radius: 4px;
  margin-left: 16px;
  border: 1px solid #20a8c3;
  background: #20a8c3;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
  color: #ffffff;
}

.promoButton:hover {
  background: #30c0db;
  border: 1px solid #30c0db;
}

@media only screen and (max-width: 767px) {
  .paymentOverview {
    width: 90%;
    height: fit-content;
    border-radius: 10px;
    background-color: #0e4672;
    padding: 18px;
    margin-left: 27px !important;
    margin-right: 27px !important;
  }

  .promoContainer {
    height: 30px;
  }

  .promoContainer > * {
    font-size: 12px;
  }

  .promoButton {
    height: 30px;
    width: 114px;
  }

  .overviewContent {
    font-size: 12px;
    line-height: 14px;
  }

  .overviewHeader {
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
  }
}
</style>
