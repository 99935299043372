import { createRouter, createWebHistory } from "vue-router";
import PaymentView from "../components/payment/PaymentView.vue";
import PaymentPage from "../components/payment/PaymentPage.vue";
import QuestionnairePage from "../components/questionnaire/QuestionnairePage.vue";
import QuestionnaireView from "../components/questionnaire/QuestionnaireView.vue";
import QuestionnaireCompleted from "../components/questionnaire/QuestionnaireCompleted.vue";
import QuestionnaireFailed from "../components/questionnaire/QuestionnaireFailed.vue";
import NYFormPage from "../components/NYForm/NYFormPage.vue";
import NYFormView from "../components/NYForm/NYFormView.vue";
import NYFormSucceed from "../components/NYForm/NYFormSucceed.vue";
import NYFormFailed from "../components/NYForm/NYFormFailed.vue";
import PaymentSucceed from "../components/payment/PaymentSucceed.vue";
import PaymentFailed from "../components/payment/PaymentFailed.vue";
import GenericFailed from "../components/payment/GenericFailed.vue";
import PaymentStatus from "../components/payment/PaymentStatus.vue";
import PaymentPending from "../components/payment/PaymentPending.vue";
import OrderCancelled from "../components/payment/OrderCancelled.vue";
import BloodDrawView from "@/components/bloodDraw/BloodDrawView.vue";
// import BloodDrawLandingView from '@/components/bloodDraw/BloodDrawLandingView.vue';
import ShippingAddressView from '../components/shippingAddress/ShippingAddressView.vue';
// import InformationLandingPage from "@/components/shippingAddress/InformationLandingPage.vue";
import CollectStatusView from '@/components/emailCollectStatus/CollectStatusView.vue';

import ConfirmAppointmentView from "@/components/confirmAppointment/ConfirmAppointmentView.vue";
import FailedConfirmAppointmentView from "@/components/confirmAppointment/FailedConfirmAppointmentView.vue";
import SuccessConfirmAppointmentView from "@/components/confirmAppointment/SuccessConfirmAppointmentView.vue";

import CancelAppointmentView from '@/components/cancelAppointment/CancelAppointmentView.vue';
import SuccessCancelAppointmentView from '@/components/cancelAppointment/SuccessCancelAppointmentView.vue';
import FailedCancelAppointmentView from '@/components/cancelAppointment/FailedCancelAppointment.vue';

import FedexPickupPage from '@/components/fedexPickup/FedexPickupPage.vue';
import FedexPickupSearchPage from '@/components/fedexPickup/FedexPickupSearchPage.vue';
import FedexSchedulesDetailsPage from '@/components/fedexPickup/FedexSchedulesDetailsPage.vue';

import HomeView from "../components/Homepage.vue"

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "homeView",
      component: HomeView,
    },
    {
      path: "/payment_status",
      name: "paymentStatus",
      component: PaymentStatus,
      props: (route) => ({
        bnpl: route.query.bnpl,
      }),
    },
    {
      path: "/payment",
      name: "paymentPage",
      component: PaymentPage,
      props: (route) => ({
        sample_id: route.query.sample_id,
        target_email: route.query.target_email,
        accession_id: route.query.accession_id,
        charge_type_id: route.query.charge_type_id,
        charge_type: route.query.charge_type,
        jwtToken: route.query.jwtToken,
        concierge_needed: route.query.concierge_needed,
        direct_to_blooddraw: route.query.direct_to_blooddraw,
        count: route.query.count,
        amount_to_pay: route.query.amount_to_pay,
        always_show_payment: route.query.always_show_payment,
      }),
    },
    {
      path: "/payment_form",
      name: "paymentView",
      component: PaymentView,
    },
    {
      path: "/payment_failed",
      name: "paymentFailed",
      component: PaymentFailed,
    },
    {
      path: "/failed",
      name: "genericFailed",
      component: GenericFailed,
    },
    {
      path: "/payment_succeed",
      name: "paymentSucceed",
      component: PaymentSucceed,
    },
    {
      path: "/payment_pending",
      name: "paymentPending",
      component: PaymentPending,
    },
    {
      path: "/order_cancelled",
      name: "orderCancelled",
      component: OrderCancelled,
    },
    {
      path: "/questionnaire",
      name: "questionnairePage",
      component: QuestionnairePage,
      props: (route) => ({
        sample_id: route.query.sample_id,
        accession_id: route.query.accession_id,
        jwtToken: route.query.jwtToken,
      }),
    },
    {
      path: "/nyform",
      name: "nyForm",
      component: NYFormPage,
      props: (route) => ({
        sample_id: route.query.sample_id,
        accession_id: route.query.accession_id,
        jwtToken: route.query.jwttoken,
      }),
    },
    {
      path: "/ny_form",
      name: "nyFormView",
      component: NYFormView,
      // props: (route) => ({
      //   sample_id: route.query.sample_id,
      //   accession_id: route.query.accession_id,
      //   jwtToken: route.query.jwtToken,
      // }),
    },
    {
      path: "/nyform_success",
      name: "nyFormSuccess",
      component: NYFormSucceed
    },
    {
      path: "/nyform_failed",
      name: "nyFormFailed",
      component: NYFormFailed
    },
    {
      path: "/questionnaire_form",
      name: "questionnaireView",
      component: QuestionnaireView,
    },
    {
      path: "/questionnaire_completed",
      name: "questionnaireCompleted",
      component: QuestionnaireCompleted,
    },
    {
      path: "/questionnaire_failed",
      name: "questionnaireFailed",
      component: QuestionnaireFailed,
    },
    {
      path: "/bloodDraw",
      name: "bloodDrawView",
      component: BloodDrawView,
    },
    {
      path: "/uploadShippingAddress",
      name: "shippingAddressView",
      component: ShippingAddressView,
    },
    {
      path: "/collectStatus",
      name: "collectStatusView",
      component: CollectStatusView,
    },
    {
      path: "/confirmAppointment",
      name: "confirmAppointment",
      component: ConfirmAppointmentView,
    },
    {
      path: "/confirmAppointment/success",
      name: "confirmAppointmentSuccess",
      component: SuccessConfirmAppointmentView,
    },
    {
      path: "/confirmAppointment/failed",
      name: "confirmAppointmentFailed",
      component: FailedConfirmAppointmentView,
    },
    {
      path: "/cancelAppointment",
      name: "cancelAppointment",
      component: CancelAppointmentView,
    },
    {
      path:'/cancelAppointment/success',
      name:'cancelAppointmentSuccess',
      component:SuccessCancelAppointmentView,
    },
    {
      path:'/cancelAppointment/failed',
      name:'cancelAppointmentFailed',
      component:FailedCancelAppointmentView,
    },
    {
      path:'/fedexPickup',
      name:'fedexPickupPage',
      component: FedexPickupPage,
    },
    {
      path:'/fedexPickup/search',
      name: 'fedexPickupSearchPage',
      component: FedexPickupSearchPage,
    },
    {
      path:'/fedexPickup/schedules',
      name: 'fedexSchedulesDetailsPage',
      component: FedexSchedulesDetailsPage,
    }
  ],
});

export default router;
