<template>
    <el-select :model-value = 'modelValue' 
    popper-class = "address-autofill-input-dropdown"
    @change = 'selectedOptionChange'
    fit-input-width
    allow-create
    remote
    filterable
    :remote-method="debounceRemoteSearch"
    :loading="loading"
    :disabled = 'disabled'
    @input = "handleTypeIn($event)"
    @blur = "handleBlur"
    >
        <el-option v-for = "item in options"
        :key = "item.place_id"
        :label="item.description"
        :value="item" />
    </el-select>
</template>

<script>
import lodash from 'lodash';
export default{
    props:{
        modelValue:{
            type:String,
            default:'',
        },
        search_range:{
            type:String,
            deafult:'',
        },
        disabled:{
            type:Boolean,
            default:false,
        }
    },
    created(){
        this.debounceRemoteSearch = lodash.debounce((address)=>this.remoteSearch(address), 500);
    },
    data(){
        return {
            content:'',
            options:[],
            loading:false,
            typed_in_text:'',
        }
    },
    emits:['selected-option-change'],
    methods:{
        handleTypeIn(event){
            this.typed_in_text = event.target.value;
        },
        handleBlur(){
            //if has typed_in_text when blur (generally click outside the component)
            //default select this option (which is the first option of the dropdown)
            if(this.typed_in_text){
                this.selectedOptionChange(this.typed_in_text);
            }
        },
        selectedOptionChange(selected_option){
            console.log('selected-option-change', selected_option);
            if(typeof selected_option == 'string'){
                this.$emit('selected-option-change', selected_option, 'self-input');
            }else{
                this.$emit('selected-option-change', selected_option.place_id, 'google-place-id');
            }
            //clear the cache of the typed_in_text for next use
            this.typed_in_text = '';
        },
        async remoteSearch(text){
            //start searching -> dropdown shows loading 
            this.loading = true;
            // load needed libraries and classes
            if(window.google){
                const { AutocompleteService, PlacesServiceStatus } = await window.google.maps.importLibrary("places");

                const AutocompleteServiceInstance = new AutocompleteService();
                const predictionParams = {
                    input:text,
                    ...(this.search_range && {componentRestrictions: { country: this.search_range }})
                };
                console.log('predictionParams',predictionParams, this.search_range);
                AutocompleteServiceInstance.getPlacePredictions(
                    predictionParams
                    ,
                    (places_predictions, status)=>{
                        if(status === PlacesServiceStatus.OK){
                            console.log('find places', places_predictions);
                            this.options = places_predictions;
                        }else if(status === PlacesServiceStatus.ZERO_RESULTS || status === PlacesServiceStatus.NOT_FOUND){
                            console.log('no matched places');
                            this.options = [];
                        }else{
                            console.log('google find places error:', status);
                            this.option = [];
                        }
                        this.loading = false;
                    }
                )
            }else{
                console.log('no google maps globally');
                this.option = [];
            }
        }
    }
}
</script>

<style scoped>
</style>