import { COUNTRIES } from "@/components/shippingAddress/shippingAddressData/countries.js";
import { getCountryDropwdownOptions } from '@/components/shippingAddress/shippingAddressData/shippingAddressInputsStructure.js';
import moment from "moment";
export const pickup_time_section_inputs_structure_data = [
    {
        input_id:'pickup_date',
        input_display_name:'Pickup Date',
        required:true,
        input_type:'date-selector',
        disabledDateInDateSelector:(cur_date)=>{
            const currentDate = moment(cur_date);
            const today = moment();
            return currentDate.isBefore(today,'day');
        },
        unit_wrapper_classname:'Pickup-Date-unit-wrapper',     
    },
    {
        input_id:'remarks',
        input_display_name:'Instructions & Comments',
        input_type:'input',
        unit_wrapper_classname:'Instructions-Comments-unit-wrapper',
    },
    {
        input_id:'ready_time',
        input_display_name:'Earliest Available Pickup Time',
        required:true,
        input_type:'selector',
        dropdown_options:[
            //dynamically get based on backend response
        ],
        unit_wrapper_classname:'Ready-Time-unit-wrapper',
    },
    {
        input_id:'customer_close_time',
        input_display_name:'Latest Available Pickup Time',
        required:true,
        input_type:'selector',
        dropdown_options:[
            //dynamically get based on backend response
            {
                label:'1',
                value:'1'
            },
            {
                label:'2',
                value:'2'
            },
            {
                label:'3',
                value:'3'
            }
        ],
        unit_wrapper_classname:'Latest-Time-unit-wrapper',
    },
];

export const edit_pickup_address_inputs_structure_data = [
    {
        input_id:'street',
        input_display_name:'Address Line',
        required: true,
        input_type:'customized_input',
        customized_component:'AddressAutoFilledInput',
        //the event for the customized input itself
        event:{
            emit_event:'selected-option-change',
            triggered_parent_event:'autofillAdressComponentSelectedOptionChange',
        },
        unit_wrapper_classname:'Address-Line-unit-wrapper',
        reserve_input_value_when_invalid:true,
        check_input_value_valiation_when_mounted:true,
    },
    {
        input_id:'city',
        input_display_name:'City',
        required:true,
        input_type:'input',
        unit_wrapper_classname:'City-unit-wrapper',
        check_input_value_valiation_when_mounted:true,
    },
    {
        input_id:'state_or_province_code',
        input_display_name:'State',
        // if not US , do not require State
        // if not US , input_type:'input',
        // if US , require State
        // if US , input_type:'selector',
        // dropdown_options:getUSAStateDropwdownOptions(STATES),
        unit_wrapper_classname:'State-unit-wrapper',
        check_input_value_valiation_when_mounted:true,
    },
    {
        input_id:'postal_code',
        input_display_name:'Zip Code',
        required:true,
        input_type:'input',
        unit_wrapper_classname:'Zip-Code-unit-wrapper',
        check_input_value_valiation_when_mounted:true,
    },
    {
        input_id:'country_code',
        input_display_name:'Country',
        required: true,
        input_type:'selector',
        dropdown_options:getCountryDropwdownOptions(COUNTRIES),
        unit_wrapper_classname:'Country-unit-wrapper',
        check_input_value_valiation_when_mounted:true,
    },
];

export const edit_contact_inputs_structure_data = [
    {
        input_id:'email',
        input_display_name:'Email',
        required: true,
        input_type:'email',
        unit_wrapper_classname:'Email-unit-wrapper',
        reserve_input_value_when_invalid:true,
        check_input_value_valiation_when_mounted:true,
    },
    {
        input_id:'phone',
        input_display_name:'Phone',
        required: true,
        input_type:'customized_input',
        customized_component:'AreaCodePhoneNumberInput',
        //the event for the customized input itself
        event:{
            emit_event:'blur',
            triggered_parent_event:'handleAreaCodePhoneNumberInputChange',
        },
        unit_wrapper_classname:'Phone-unit-wrapper',
        reserve_input_value_when_invalid:true,
        check_input_value_valiation_when_mounted:true,
    },
];