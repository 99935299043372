<template>
    <div class = 'input-unit'>
        <div v-if = "input_display_name" :class = "['input-name-div',{'required-input':required}]">
            {{ input_display_name }} <span v-if = "input_display_note" class = "input-note" v-html = "input_display_note"></span>
        </div>
        <div :class = "[
            'input-wrapper',
            {'error-highlighted':has_error}
        ]">
            <el-input v-if = "input_type == 'input'" 
            :placeholder = "placeholder"
            :model-value = "modelValue" @input = "inputContentChagne" :disabled = "disabled" />
            <el-input v-if = "input_type=='date-input-with-auto-format'" 
            :model-value = "modelValue" 
            type='date' 
            @input = "inputContentChagne" 
            @blur = "handleCheckContent(modelValue)"
            :disabled = "disabled" />
            <el-input v-if = "input_type == 'email'" 
            :placeholder = "placeholder"
            v-model = "value" 
            @change = "inputContentChagne" 
            @blur = "handleCheckContent(modelValue)"
            :disabled = "disabled" />
            <el-select v-if = "input_type =='selector'"
            :style = "{'--dropdown-arrow-src-': `url(${dropdown_arrow_src})`}"
            popper-class = 'selector-dropdown'
            fit-input-width
            :modelValue = 'modelValue' @change = 'inputContentChagne'
            :disabled = "disabled" >
                <el-option
                v-for="item in dropdown_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                />
            </el-select>
            <el-date-picker v-if = "input_type == 'date-selector'"
                :modelValue="modelValue"
                :disabled = "disabled"
                type="date"
                placeholder="Select Or Enter MM/DD/YYYY"
                format = "MM/DD/YYYY"
                value-format = "YYYY-MM-DD"
                @update:model-value="inputContentChagne"
                :disabled-date="disabledDateInDateSelector"
                @blur = "handleCheckContent(modelValue)"
            />
        
            <component v-if = "input_type == 'customized_input' && event!=null && event.emit_event!=null && event.triggered_parent_event!=null"
            :is = "customized_component" 
            :modelValue = "modelValue"
            v-bind="customized_props"
            :disabled = "disabled"
            @[event.emit_event]= "(selected_option, type)=>this[event.triggered_parent_event](selected_option,type)" />
<!-- @selected-option-change = "(description)=>this[event.triggered_parent_event](description)" -->
            <!-- @selected-option-change = "(description)=>triggerCorrespondingMethod(description, event.triggered_parent_event)" -->
            <div v-if = "customized_component=='AddressAutoFilledInput'"
            ref = "google-autocomplete-dummy-map"></div>
            <div v-if = "has_error && !disabled" class = "error-text">
                {{ error_text }}
            </div>
        </div> 
    </div>
</template>

<script>
// import all possible needed input component
import AddressAutoFilledInput from '@/components/shippingAddress/AddressAutoFilledInput.vue';
import AreaCodePhoneNumberInput from '@/components/commons/AreaCodePhoneNumberInput.vue';
import dark_gray_dropdown_arrow from "@/assets/FedexPickup/dark_gray_dropdown_arrow.svg";
import dropdown_arrow from "@/assets/FedexPickup/dropdown_arrow.svg";
import { country_code_to_area_code_map } from '@/components/bloodDraw/BloodDrawData/AreaCodeMap.js';
import moment from "moment";
export default{
    components:{
        AddressAutoFilledInput,
        AreaCodePhoneNumberInput,
    },
    props:{
        modelValue:{
            type:[String,Object],
            default:''
        },
        input_id:{
            type:String,
            default:''
        },
        input_display_name:{
            type:String,
            default:''
        },
        input_display_note:{
            type:String,
            default:''
        },
        required:{
            type:Boolean,
            default:false,
        },
        disabled:{
            type:Boolean,
            default:false,
        },
        input_type:{
            type:String,
            default:'input',
        },
        dropdown_options:{
            type:Array,
            default:()=>[]
        },
        placeholder:{
        //for input_type == input, email
            type:String,
            default:'Enter',
        },
        text_length_limit:{
        //only work when modelValue is string, 0 means no limit
            type:Number,
            default:0,
        },
        customized_component:{
            type:String,
            default:'',
        },
        customized_props:{
            type:Object,
            default:()=>{},
        },
        event:{
            type:Object,
            default:()=>{}
        },
        disabledDateInDateSelector:{
        //only work for input_type == 'date-selector'
            type:Function,
            default:()=>{},
        },
        reserve_input_value_when_invalid:{
            type:Boolean,
            default:false,
        },
        check_input_value_valiation_when_mounted:{
            type:Boolean,
            default:false,
        }
    },
    mounted(){
        //check the input's validation when first mounted
        if(this.check_input_value_valiation_when_mounted){
            //if modelValue is string, text_length_limit is not 0, and the modelValue.length is beyond the limit:
            if(this.text_length_limit && typeof this.modelValue =='string' && this.modelValue.length > this.text_length_limit){
                //cut the newVal within the limit and update:modelValue
                const revised_content = this.modelValue.substring(0,this.text_length_limit);
                console.log('revised modelValue:',this.input_id, revised_content);
                this.$emit('update:modelValue', revised_content);
            }else{
            //if the length <= text_length_limit, or text_length_limit==0 (means no limit), or modelValue is not string (the limit doesn't apply):
                // checkContentValidation
                this.checkContentValidation(this.modelValue);
            }
        }
        //store required input fields in store
        if(this.required){
            this.$emit('add-required-input-field', this.input_id);
            // this.addRequiredInputFields(this.input_id);
        }
    },
    data(){
        return{
            //for input_type='email', only want to update content after pressing Enter or input is blurred
            //NOTES: all other inputs that don't use :model-value but use v-model can use this.value
            //with :model-value and @input the content sync immediately to parent, which would cause screen flicker if the input requires valiation check.
            //and :model-value and @change doesn't work as the content always remains the same without immediate sync.
            //but v-model allows typing and edit before sync. and use @change to allow validation check when Enter is pressed or input is blurred.
            value:this.modelValue,

            has_error:false,
            error_text:'',
            google_place_details_to_adrress_dict:{
                street_number:'address',
                route:'address',
                subpremise:'address',
                intersection:'address', //-> eg. Main Street & 5th Avenue
                sublocality:'city', // - > eg. Downtown, SoHo
                locality: 'city',
                administrative_area_level_2: 'city', // => county (area contains multiple cities)
                administrative_area_level_1: 'state',
                country:'country',
                postal_code:'zipcode',
                postal_code_suffix:'zip_code_suffix',
                postal_code_prefix: 'zip_code_prefix',
            },
            address_input_hierarchy:[
                {
                    id:'street_number',
                },
                {
                    id:'route',
                },
                {
                    id:'subpremise'
                },
                {
                    id:'intersection',
                    //optional means, only concate this field when the previous ones are all empty.
                    optional: true, 
                }
            ],
        }
    },
    computed:{
        dropdown_arrow_src(){
            return this.disabled ? dark_gray_dropdown_arrow : dropdown_arrow;
        },
        input_unit_validation_related_properties(){
            return `${this.input_type} ${this.input_id} ${this.input_display_name} ${this.text_length_limit}`;
        },
    },
    watch:{
        // for input_type='email'
        value(){
            this.has_error = false;
        },
        modelValue(newVal){
            //update value for input_type='email'
            this.value = newVal;
            console.log('modelValue:',this.input_id, newVal);
            //if modelValue is string, text_length_limit is not 0, and the modelValue.length is beyond the limit:
            if(this.text_length_limit && typeof newVal =='string' && newVal.length > this.text_length_limit){
                //cut the newVal within the limit and update:modelValue
                const revised_content = newVal.substring(0,this.text_length_limit);
                console.log('revised modelValue:',this.input_id, revised_content);
                this.$emit('update:modelValue', revised_content);
            }else{
            //if the length <= text_length_limit, or text_length_limit==0 (means no limit), or modelValue is not string (the limit doesn't apply):
                // checkContentValidation
                this.checkContentValidation(newVal);
            }
        },
        input_unit_validation_related_properties(){
        //triggered when any validation related property changed (seemingly show a new input)
        //-> need to re-process the limitation and validation check
            //if modelValue is string, text_length_limit is not 0, and the modelValue.length is beyond the limit:
            if(this.text_length_limit && typeof this.modelValue =='string' && this.modelValue.length > this.text_length_limit){
                //cut the newVal within the limit and update:modelValue
                const revised_content = this.modelValue.substring(0,this.text_length_limit);
                console.log('revised modelValue:', this.input_id, revised_content);
                this.$emit('update:modelValue', revised_content);
            }else{
            //if the length <= text_length_limit, or text_length_limit==0 (means no limit), or modelValue is not string (the limit doesn't apply):
                // checkContentValidation
                this.checkContentValidation(this.modelValue);
            }
        },
        required(newVal){
            if(newVal){
                this.$emit('add-required-input-field', this.input_id);
            }else{
                this.$emit('remove-required-input-field', this.input_id);
            }
        },
        has_error(newVal){
            if(newVal){
                this.$emit('invalid-value', this.input_id, this.modelValue);
            }else{
                this.$emit('remove-invalid-value', this.input_id, this.modelValue);
            }
        }
    },
    emits:[
        'update:modelValue', 
        'auto-fill-related-input', 
        'add-required-input-field',
        'remove-required-input-field',
        'invalid-value',
        'remove-invalid-value',
    ],
    methods:{
        inputContentChagne(cur_content){
            console.log('inputcontentchange', cur_content);
            this.$emit('update:modelValue', cur_content);
        }, 
        handleCheckContent(modelValue){
        //triggered when the input loses focus(blur)
            // console.log('handleCheckContent', modelValue);
            this.has_error = false;
            //check whether valid input content
            const is_valid = this.checkContentValidation(modelValue);
            // console.log('handleCheckContent',is_valid);
            //if invalid input content
            if(!is_valid && !this.reserve_input_value_when_invalid){
                if(this.customized_component == 'AreaCodePhoneNumberInput'){
                    this.$emit('update:modelValue',{
                        area_code:'',
                        phone_number:''
                    });
                }else{
                    this.$emit('update:modelValue','');
                }
            }else{
                this.$emit('update:modelValue', modelValue);
            }
            return is_valid;
        },
        checkContentValidation(cur_content){
            //used as immediate return of the function
            var is_valid = true;
            //reset the has_error status
            this.has_error = false;
            // console.log('checkContentValidation', cur_content, this.error_text);
            setTimeout(()=>{
                this.error_text = '';
            }, this.reserve_input_value_when_invalid ? 0 :100); 

            //if reserve_input_value_when_invalid: set error related reative data immediately.
            //if not reserve_input_value_when_invalid: 
            // -> set error related reative data after 100 milliseconds to wait until the content is cleared.
            //NOTES: generally the watcher of modelValue complete the function after 100 milliseconds.
            if((this.input_id.includes('birthdate') || this.input_id.toUpperCase()=='DOB') && cur_content){
                //valid birthdate:less than 200 years old, can't be future date, 
                const date200YearsAgo = moment().subtract(200, 'years');
                console.log('dob',cur_content, moment(cur_content), moment(cur_content).isBefore(date200YearsAgo));
                if(moment(cur_content).isBefore(date200YearsAgo)){
                    is_valid = false;
                    setTimeout(()=>{
                        this.has_error = true;
                        this.error_text = "Age cannot exceed 200 years.";
                    }, this.reserve_input_value_when_invalid ? 0 :100); 
                }else if(moment(cur_content).isAfter(moment())){
                    is_valid = false;
                    setTimeout(()=>{
                        this.has_error = true;
                        this.error_text = "Birthdate cannot be a future date.";
                    }, this.reserve_input_value_when_invalid ? 0 :100); 
                }
            }
            if(this.input_type=='email' && cur_content){
            //NOTES: when cur_content == '', the match alway null. So the result is only reliable when cur_content != ''
                //check whether valid email address
                const match = cur_content.match(/(\w+([.-_^?$&!%*'+-/=`{|]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+)/g);
                //if invalid email:
                if(match == null){
                    console.log('invalid email');
                    is_valid = false;
                    setTimeout(()=>{
                        this.has_error = true;
                        this.error_text = "Invalid email";
                    }, this.reserve_input_value_when_invalid ? 0 :100); 
                }
            }
            if(this.input_type == "customized_input"){
                if(this.customized_component=='AddressAutoFilledInput'){
                    //when cur_content == '': isPoBoxError() always returns null, meaning no error
                    if(this.isPoBoxError(cur_content)){
                        is_valid = false;
                        setTimeout(()=>{
                            console.log('Validation: We DO NOT ship kits to P.O Boxes');
                            this.has_error = true;
                            this.error_text = "We DO NOT ship kits to P.O Boxes";
                        }, this.reserve_input_value_when_invalid ? 0 :100);    
                    }
                }
                if(this.customized_component=='AreaCodePhoneNumberInput'){
                    //check area_code if has phone number
                    if( (cur_content.area_code && !Object.values(country_code_to_area_code_map).includes(cur_content.area_code))
                        ||
                        (!cur_content.area_code && cur_content.phone_number)
                    ){
                        is_valid = false;
                        setTimeout(()=>{
                            this.has_error = true;
                            this.error_text = "Please select a country.";
                        }, this.reserve_input_value_when_invalid ? 0 :100);     
                    }

                    //check phone_number if has area_code (select a country)
                    //phone_number has value and not all digits: show error, or has area_code, but no phone_number or phone numbder not all digits
                    if(cur_content.area_code && !this.isAllDigits(cur_content.phone_number)){
                        is_valid = false;
                        setTimeout(()=>{
                            this.has_error = true;
                            this.error_text += this.error_text ? " And ensure the phone number contains only digits." : "Ensure the phone number contains only digits.";
                        }, this.reserve_input_value_when_invalid ? 0 : 100);
                    }

                    // check whether phone_numbr is extactly 10 digits, if area_code == '1'
                    // NOTES: all phone numbers with the country code +1, follow the 10-digit format
                    if(cur_content.area_code =='1' && this.isAllDigits(cur_content.phone_number)){
                        if(cur_content.phone_number.length!=10){
                            is_valid = false;
                            setTimeout(()=>{
                                this.has_error = true;
                                this.error_text += this.error_text ? " All phone numbers with the country code +1, follow the 10-digit format" : "All phone numbers with the country code +1, follow the 10-digit format";
                            }, this.reserve_input_value_when_invalid ? 0 : 100);
                        }
                    }

                    // check whether all digits' length in range of [5, 15]
                    // NOTES: E.164 standard including the country code -> longest possible phone numbers: 15 digits; shortest possible phone numbers: 5 digits
                    if(cur_content.area_code && this.isAllDigits(cur_content.phone_number)){
                        const phone_len = cur_content.area_code.length + cur_content.phone_number.length;
                        console.log('phone_len', phone_len);
                        if(phone_len<5 || phone_len>15){
                            is_valid = false;
                            setTimeout(()=>{
                                this.has_error = true;
                                this.error_text += this.error_text ? " The phone's digits is within the range of 5 to 15." : "The phone's digits is within the range of 5 to 15.";
                            }, this.reserve_input_value_when_invalid ? 0 : 100);
                        }
                    }
                }
            }
            return is_valid;
        },
        handleAreaCodePhoneNumberInputChange(cur_content){
        //triggered when the input lose focus
            console.log('handleAreaCodePhoneNumberInputChange', cur_content);
            //check the validation of current value and update the bind value accordingly
            this.handleCheckContent(cur_content);
        },
        async autofillAdressComponentSelectedOptionChange(selected_option, type){
            console.log('autofillAdressComponentSelectedOptionChange', selected_option);
            if(type == 'self-input'){
                //check PO box error
                this.handleCheckContent(selected_option);
            }else if (type == 'google-place-id'){
                const { PlacesService, PlacesServiceStatus } = await window.google.maps.importLibrary("places");
                const placServiceInstance = new PlacesService(this.$refs['google-autocomplete-dummy-map']);
                placServiceInstance.getDetails(
                    {
                        placeId:selected_option,
                    },
                    (result,status)=>{
                        if(status === PlacesServiceStatus.OK){
                            console.log('google place result', result);
                            const output = this.filterGooglePlaceDetails(result.address_components, this.google_place_details_to_adrress_dict);
                            //get value to be put in address/street input
                            var filteredAddress = this.concatAddressInfo(output, this.address_input_hierarchy);
                            //check PO box error
                            const is_valid_filtered_address = this.handleCheckContent(filteredAddress);
                            if(is_valid_filtered_address){
                                //autofill other related fields: city, state, country, zip_code, 
                                if(output['locality']){//city-level
                                //locality share the first priority, if not has locality, then go to find other values
                                    this.$emit('auto-fill-related-input',this.google_place_details_to_adrress_dict['locality'], output['locality']);
                                }else if(output['administrative_area_level_2']){
                                    this.$emit('auto-fill-related-input',this.google_place_details_to_adrress_dict['administrative_area_level_2'], output['administrative_area_level_2']);
                                }else{
                                    this.$emit('auto-fill-related-input',this.google_place_details_to_adrress_dict['sublocality'], output['sublocality']);
                                }
                                this.$emit('auto-fill-related-input',this.google_place_details_to_adrress_dict['administrative_area_level_1'], output['administrative_area_level_1']);
                                this.$emit('auto-fill-related-input',this.google_place_details_to_adrress_dict['country'], output['country']);
                                this.$emit('auto-fill-related-input',this.google_place_details_to_adrress_dict['postal_code'], output['postal_code']);
                            }
                        }else{
                            console.log('google find places error:', status);
                        }
                    }
                );
                
            }
        },
        filterGooglePlaceDetails(address_components, dict){
            const output = {};
            for(const component of address_components){
                for(const type of component.types){
                    if(dict[type]){
                        output[type] = component.short_name;
                        break;
                    }
                }
            }
            //cancel postal code suffix and prefix
            // if(output.postal_code_suffix!=null){
            //     output.postal_code = `${output.postal_code}-${output.postal_code_suffix}`;
            // }
            // if(output.postal_code_prefix!=null){
            //     output.postal_code = output.postal_code_prefix + '-' + output.postal_code;
            // }
            return output;
        },
        concatAddressInfo(info, hierarchy){
            var filteredAddress = '';
            for(const level_info of hierarchy){
                if(info[level_info.id] && !level_info.optional){
                    filteredAddress += filteredAddress==''?info[level_info.id]:` ${info[level_info.id]}`;
                }else if(filteredAddress == '' && info[level_info.id] && level_info.optional){
                    filteredAddress += info[level_info.id];
                }
            }
            return filteredAddress;
        },
        isPoBoxError(streetAddress) {
            // console.log('test PO box error', streetAddress);
            const regex =  /.*(P\.?O\.?\s+Box|Post\s+Office\s+Box)\s+((#|number|num)\s*)?\d+/igm;
            if (streetAddress && streetAddress.match(regex)) {
                return true;
            }
            return false
        },
        isAllDigits(val){
            const is_digits_only = /^\d+$/.test(val); // Only digits from start to end
            return is_digits_only;
        }
    }
}
</script>

<style scoped>
.input-unit{
    width:100%;
    display:flex;
    flex-direction:column;
    gap:6px;
}
.input-name-div{
    color: #000;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.175px;
}
.input-note{
    color:#BCCCDC;
}
.input-name-div.required-input:after{
    content:' *';
    color: #FF1D00;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.175px;
}
.error-text{
    margin-top: 8px;
    color: #DF2B25;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
}
/* date-selector */
:deep(.el-date-editor.el-input, .el-date-editor.el-input__wrapper){
    width:100%;
    height:fit-content;
    display:flex;
    flex-direction:column;
    gap:6px;
}
:deep(.el-date-editor.el-input .el-input__prefix){
    display: none;
}
:deep(.el-date-editor.el-input .el-input__suffix-inner){
    content: url('@/assets/FedexPickup/date_icon.svg'); 
    width:24px;
    height:24px;
}
:deep(.el-select .el-input__suffix-inner .el-select__caret.el-icon){
    content: var(--dropdown-arrow-src-,url('@/assets/FedexPickup/dropdown_arrow.svg')); 
    width:24px;
    height:24px;
    transition: all 0.4s;
};
:deep(.el-select .is-disabled .el-input__suffix-inner .el-select__caret.el-icon){
    content:url();
    width:24px;
    height:24px;
    transition: all 0.4s;
}
:deep(.el-select .el-input__suffix-inner .el-select__caret.el-icon.is-reverse),
:deep(.el-select .el-input__suffix-inner .el-select__caret.el-icon.is-reverse){
    transform: rotate(-180deg);
}
@media only screen and (max-width:767px){
    .input-unit{
        width:100%;
        display:flex;
        flex-direction:column;
        gap:4px;
    }
    .input-name-div{
        color: #000;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
    }
    .input-name-div.required-input:after{
        content:' *';
        color: #FF1D00;
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.175px;
    }
    .error-text{
        margin-top: 8px;
        color: #DF2B25;
        font-family: 'Roboto';
        font-size:  14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }
    /* date-selector */
    :deep(.el-date-editor.el-input, .el-date-editor.el-input__wrapper){
        width:100%;
        height:fit-content;
        display:flex;
        flex-direction:column;
        gap:4px;
    }
    :deep(.el-date-editor.el-input .el-input__suffix-inner){ 
        width:20px;
        height:20px;
    }
    :deep(.el-select .el-input__suffix-inner .el-select__caret.el-icon){
        width:20px;
        height:20px;
    }

}
</style>