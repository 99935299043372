<template>
    <el-dialog
    v-model="dialog_visible"
    :width = "dynamic_dialog_width"
    :fullscreen="show_fullscreen"
    class = "fedex-pickup-dialog"
    destroy-on-close
    @close = "handleCloseEvent"
    >
        <template #header="{close}">
            <div class="my-header">
                <span class = "title">{{ title }}</span>
                <img v-if = "!show_fullscreen" src = "@/assets/FedexPickup/dialog_cross_icon.svg" @click="close" class = "close-icon"/>
                <div v-else class = "go-back-btn" @click="close">
                    <img src ="@/assets/FedexPickup/back_arrow.svg" class = "go-back-btn-icon"/>
                    <div class = "go-back-btn-text">
                        Back
                    </div>
                </div>
            </div>
        </template>
        <div class = "section" v-loading = "loading">
            <SimplifiedNotification v-if = "has_error" v-bind = "error_notification_props"/>
            <div class = "guidance-text">
                Are you sure to <span class = "bold underlined">cancel</span> this scheduled pickup?
            </div>
            <div class = "schedule-details-container">
                <div class = "detail-row align-top">
                    <div class = "detail-type">
                        Confirmation Number:
                    </div>
                    <div class = "detail-type-value">
                        {{ schedule_confirmation_number }}
                    </div>
                </div>
                <div class = "detail-row align-top">
                    <div class = "detail-type">
                        Status:
                    </div>
                    <div class = "detail-type-value">
                        {{ status }}
                    </div>
                </div>
                <div class = "detail-row align-top">
                    <div class = "detail-type">
                        Pickup Date:
                    </div>
                    <div class = "detail-type-value">
                        {{ pickup_date }}
                    </div>
                </div>
                <div class = "detail-row align-center">
                    <div class = "detail-type">
                        Earliest Available Pickup Time:
                    </div>
                    <div class = "detail-type-value">
                        {{ earliest_available_time }}
                    </div>
                </div>
                <div class = "detail-row align-center">
                    <div class = "detail-type">
                        Latest Available Pickup Time:
                    </div>
                    <div class = "detail-type-value">
                        {{ latest_available_time }}
                    </div>
                </div>
                <div class = "detail-row align-top">
                    <div class = "detail-type">
                        Address:
                    </div>
                    <div class = "detail-type-value">
                        {{ concate_address }}
                    </div>
                </div>
                <div class = "detail-row align-top">
                    <div class = "detail-type">
                        Contact:
                    </div>
                    <div class = "detail-type-value">
                        <div>
                            {{ phone }}
                        </div>
                        <div>
                            {{ email }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class = "ops-btns">
            <div :class = "['cancel-btn']" @click = "handleCancel">Cancel</div>
            <div :class = "['confirm-btn']" @click = "handleConfirm">Confirm</div>
        </div>
    </el-dialog>
</template>

<script>
import moment from "moment";
import { mapActions } from "pinia";
import { useWindowSize } from '@vueuse/core';
import { useFedexPickupStore } from "@/stores/FedexPickupStore.ts";
import SimplifiedNotification from "@/components/commons/SimplifiedNotification.vue";
export default{
    props:{
        schedule_info:{
            type:Object,
            default:()=>{},
        },
    },
    components:{
        SimplifiedNotification,
    },
    setup(){
        const { width } = useWindowSize();
        return {
            width,
        }
    },
    data(){
        return{
            dialog_visible:true,
            day_list:['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            loading:false,
            has_error:false,
            error_notification_props:{
                title: "Failed",
                content: "Due to the system issue, we were unable to cancel the selected pickup at this time. Please try again, contact FedEx at +1 800-800-3339 or call your local FedEx customer service to cancel it.",
                build_in_theme: "red",
                with_close_btn: false,
            }
        }
    },
    computed:{
        dynamic_dialog_width(){
            return this.width > 820 ? '640': (this.width >767 ? '80%' : "100%");
        },
        show_fullscreen(){
            return this.width <= 767 ? true : false;
        },
        title(){
            return `Cancel Pickup #${this.schedule_confirmation_number}`;
        },
        schedule_confirmation_number(){
        //location_code + confirmation_code
            return this.schedule_info.pickup_info.confirmation_code && this.schedule_info.pickup_info.confirmation_code!= "TBD"
            ? 
            this.schedule_info.pickup_info.location_code + this.schedule_info.pickup_info.confirmation_code
            :
            'TBD'
            ;
        },
        status(){
            return this.schedule_info.status;
        },
        pickup_date(){
            const day = moment(this.schedule_info.pickup_info.pickup_date).day();
            return `${this.day_list[day]}, ${this.schedule_info.pickup_info.pickup_date}`;
        },
        earliest_available_time(){
            return this.schedule_info.pickup_info.ready_time;
        },
        latest_available_time(){
            return this.schedule_info.pickup_info.customer_close_time;
        },
        concate_address(){
            return `${this.schedule_info.pickup_address.street}, ${this.schedule_info.pickup_address.city}, ${this.schedule_info.pickup_address.state_or_province_code}, ${this.schedule_info.pickup_address.postal_code}, ${this.schedule_info.pickup_address.country_code}`;
        },
        phone(){
            return `+${this.schedule_info.contact.phone.area_code} ${this.schedule_info.contact.phone.phone_number}`;
        },
        email(){
            return this.schedule_info.contact.email;
        }
    },
    emits:['close','confirm'],
    methods:{
        ...mapActions(useFedexPickupStore,[
            'cancelExistingSchedule'
        ]),
        handleCloseEvent(){
            this.$emit('close');
        },
        handleCancel(){
            this.dialog_visible = false;
        },
        async handleConfirm(){
            this.has_error = false;
            //call api to cancel schedule
            try{
                this.loading = true;
                const current_schedule_updated_info = await this.cancelExistingSchedule(this.schedule_info);
                this.loading = false;
                this.$emit("confirm", current_schedule_updated_info);
            }catch(err){
                this.has_error = true;
                this.loading = false;
                console.log('handleConfirm in CancelScheduleDialog error:', err);
            }
        }
    }
}
</script>

<style scoped>
.close-icon{
    cursor:pointer;
}
.go-back-btn{
    width: fit-content;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor:pointer;
}
.go-back-btn-icon{
    width:20px;
    height: 20px;
}
.go-back-btn-text{
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}
.section{
    padding: 12px 24px 0px 24px;
    display:flex;
    flex-direction: column;
    row-gap: 24px;
}

.guidance-text{
    color: #1C1B1F;
    font-family:'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}
.bold{
    font-weight: 500;
}
.underlined{
    text-decoration: underline;
}
.detail-row{
    display: flex;
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}
.detail-row.align-center{
    align-items: center;
}
.detail-row.align-top{
    align-items: flex-start;
}
.detail-type{
    padding:6px 10px;
    flex:0 0 auto;
    width:210px;
    white-space: normal;
    word-break: break-word;
}
.detail-type-value{
    padding:6px 10px;
    flex:1;
    white-space: normal;
    word-break: break-word;
}
.ops-btns{
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;
    padding:24px;
}
.cancel-btn{
    width: 99px;
    padding:9px 23px;
    border-radius: 4px;
    border: 1px solid #1E4670;
    background: #FFF;
    color: #1E4670;
    text-align: center;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    cursor:pointer;
}
.confirm-btn{
    width: 99px;
    padding:9px 23px;
    border-radius: 4px;
    border: 1px solid #1E4670;
    background: #1E4670;
    color: #FFF;
    text-align: center;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    cursor:pointer;
}
@media only screen and (max-width:767px){
    .section{
        padding: 4px 28px 0px 28px;
        row-gap: 16px;
    }
    .detail-type{
        padding:4px 8px;
        width:159px;
    }
    .detail-type-value{
        padding:4px;
    }
    .ops-btns{
        margin-top:20px;
        display: flex;
        justify-content: space-between;
        column-gap: 19px;
        padding:12px 28px;
    }
    .cancel-btn{
        max-width: 150px;
        width:calc(50% - 9px);
        padding:7px 20px;
        border-radius: 4px;
        border: 1px solid #1E4670;
        background: #FFF;
        color: #1E4670;
        text-align: center;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        cursor:pointer;
    }
    .confirm-btn{
        max-width: 150px;
        width:calc(50% - 9px);
        padding:7px 3px;
        border-radius: 4px;
        border: 1px solid #1E4670;
        background: #1E4670;
        color: #FFF;
        text-align: center;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        cursor:pointer;
    }
}
</style>