import { defineStore } from "pinia";
import {billingService}  from "@/utils/billingRequest";
import {chargeService} from "@/utils/chargeRequest";
import {patientService} from "@/utils/patientRequest";
import {orderBaseURL} from "@/utils/orderRequest";
import { baseURL } from "@/components/bloodDraw/BloodDrawData/request";
import { payoutBaseURL } from "@/utils/payOutRequest.js";

export const usePaymentStore = defineStore("PaymentStore", {
  state: () => {
    return {
      billingInfo: localStorage.getItem("billingInfo")
        ? JSON.parse(localStorage.getItem("billingInfo"))
        : "",
      loadingPromo: false,
      paymentStatus: "",
      sample_id: localStorage.getItem("sample_id")
        ? localStorage.getItem("sample_id")
        : "",
      accession_id: localStorage.getItem("accession_id")
        ? localStorage.getItem("accession_id")
        : "",
      patient_id: localStorage.getItem("patient_id")
        ? localStorage.getItem("patient_id")
        : "",
      charge_type: localStorage.getItem("charge_type")
        ? localStorage.getItem("charge_type")
        : "",
      charge_type_id: localStorage.getItem("charge_type_id")
        ? localStorage.getItem("charge_type_id")
        : "",
      clinic_name: localStorage.getItem("clinic_name")
        ? localStorage.getItem("clinic_name")
        : "",
      provider_name: localStorage.getItem("provider_name")
        ? localStorage.getItem("provider_name")
        : "",
      customer_id: localStorage.getItem("customer_id")
        ? localStorage.getItem("customer_id")
        : "",
      charge_method: localStorage.getItem("charge_method")
        ? localStorage.getItem("charge_method")
        : "",
      address_needed: localStorage.getItem("address_needed")
        ? localStorage.getItem("address_needed")
        : "",
      blood_draw_needed: localStorage.getItem("blood_draw_needed")
        ? localStorage.getItem("blood_draw_needed")
        : "",
      concierge_needed: localStorage.getItem("concierge_needed")
        ? localStorage.getItem("concierge_needed")
        : "",
      direct_to_blooddraw: localStorage.getItem("direct_to_blooddraw")
        ? localStorage.getItem("direct_to_blooddraw")
        : "",
      count: localStorage.getItem("count") ? localStorage.getItem("count") : "",
      is_markup_order: localStorage.getItem("is_markup_order")
        ? localStorage.getItem("is_markup_order")=='true'
        : "",
      provider_has_revenue: localStorage.getItem("provider_has_revenue")
      ? localStorage.getItem("provider_has_revenue") =='true'
      : "",
      //for ads of concierge service, always show payment page with concierge if is_valid_concierge
      always_show_payment: localStorage.getItem("always_show_payment")
      ? localStorage.getItem("always_show_payment")=='true'
      : null,
      billing_email: "",
      billing_phone: "",
      checkEmptyFields: false,
      hasEmptyFields: [],
      billing_country: "",
      billing_state: "",
      billing_address: "",
      billing_address2: "",
      billing_city: "",
      billing_zipCode: "",
      billing_firstName: "",
      billing_lastName: "",

      patient_address:{},
      customer_info:{},
      is_international_payment:false,
      supported_bnpl_methods_data:null,

      token:null,
      //backend_has_concierge_service stores whether the backend records to have concierge service or not
      backend_has_concierge_service:null,
      //has_concierge_service represents whether the concierge sercive checkbox is checked
      has_concierge_service:false,
      consultation_fee_name: 'Consultation Fee',
      //is_valid_concierge is this order's address (we use provider's address) valid for concierge service
      is_valid_concierge:false,
      customized_pns_logo:'',

      // temporary use for blood concierge beta 1 launch - blood_concierge
      blood_concierge_beta_1: false,
      // temporary use for blood concierge beta 1 launch - blood_concierge ------ end
    };
  },
  actions: {
    setSampleIdViaPaymentStore(val){
      if (val) {
        localStorage.setItem("sample_id", val);
        this.sample_id = val;
      } else {
        localStorage.removeItem("sample_id");
        this.sample_id = "";
      }
    },
    setAccessionIdViaPaymentStore(val){
      if (val) {
        localStorage.setItem("accession_id", val);
        this.accession_id = val;
      } else {
        localStorage.removeItem("accession_id");
        this.accession_id = "";
      }
    },
    setChargeType(val) {
      if (val) {
        localStorage.setItem("charge_type", val);
        this.charge_type = val;
      } else {
        localStorage.removeItem("charge_type");
        this.charge_type = "";
      }
    },
    setChargeTypeId(val) {
      if (val) {
        localStorage.setItem("charge_type_id", val);
        this.charge_type_id = val;
      } else {
        localStorage.removeItem("charge_type_id");
        this.charge_type_id = "";
      }
    },
    setCount(val) {
      if (val) {
        localStorage.setItem("count", val);
        this.count = val;
      } else {
        localStorage.removeItem("count");
        this.count = "";
      }
    },
    setIsMarkupOrder(val) {
      if (val !== null && val !== undefined && val !== "") {
        localStorage.setItem("is_markup_order", val);
        this.is_markup_order = val;
      } else {
        localStorage.removeItem("is_markup_order");
        this.is_markup_order = "";
      }
    },
    setProviderHasRevenue(val) {
      if (val !== null && val !== undefined && val !== "") {
        localStorage.setItem("provider_has_revenue", val);
        this.provider_has_revenue = val;
      } else {
        localStorage.removeItem("provider_has_revenue");
        this.provider_has_revenue = "";
      }
    },
    setJwtToken(val) {
      console.log('setJwtToken PaymentStore', val);
      if (val) {
        this.token = val;
        localStorage.setItem("pnsToken", val);
      } else {
        console.log('remove pnsToken PaymentStore');
        localStorage.removeItem("pnsToken");
      }
    },
    setCheckEmptyFields(val) {
      this.checkEmptyFields = val;
    },
    setConciergeNeeded(val) {
      if (val) {
        localStorage.setItem("concierge_needed", val);
        this.concierge_needed = val;
      } else {
        localStorage.removeItem("concierge_needed");
        this.concierge_needed = "";
      }
    },
    setDirectToBloodDraw(val) {
      if (val) {
        localStorage.setItem("direct_to_blooddraw", val);
        this.direct_to_blooddraw = val;
      } else {
        localStorage.removeItem("direct_to_blooddraw");
        this.direct_to_blooddraw = "";
      }
    },
    setFixedAmountToPay(val) {
      if (val) {
        localStorage.setItem("amount_to_pay", val);
      } else {
        localStorage.removeItem("amount_to_pay");
      }
    },
    setAlwaysShowPayment(val){
      if (val) {
        localStorage.setItem("always_show_payment", val);
        this.always_show_payment = val=='true';
      } else {
        localStorage.removeItem("always_show_payment");
        this.always_show_payment = null;
      }
    },
    initHasEmptyFields() {
      this.hasEmptyFields = [];
    },
    updateHasEmptyFields(val) {
      this.hasEmptyFields.push(val);
    },
    setBillingEmail(val) {
      if (val) {
        this.billing_email = val;
      }
    },
    setBillingPhone(val) {
      this.billing_phone = val;
    },
    setBillingCountry(val) {
      this.billing_country = val;
    },
    setBillingState(val) {
      this.billing_state = val;
    },
    setBillingAddress(val) {
      this.billing_address = val;
    },
    setBillingAddress2(val) {
      this.billing_address2 = val;
    },
    setBillingCity(val) {
      this.billing_city = val;
    },
    setBillingZipCode(val) {
      this.billing_zipCode = val;
    },
    setBillingFirstName(val) {
      this.billing_firstName = val;
    },
    setBillingLastName(val) {
      this.billing_lastName = val;
    },
    setBackendHasConciergeService(val){
      this.backend_has_concierge_service = val;
    },
    setHasConciergeService(val){
      this.has_concierge_service = val;
    },
    async fetchCustomizedConsultationFeeNameAndCustomizedPNSLogo(){
      try{
        //get valid token from backend
        const token_api_params = {
          sample_id: this.sample_id,
        }
        const valid_token_res = await baseURL.get('/utility/getSettingToken', {
          headers:{ 
            Authorization: `Bearer ${localStorage.getItem('pnsToken')?localStorage.getItem('pnsToken'):this.token}`
          },
          params: token_api_params,
        });
        //if get the valid token, call to get consultation_fee_name and full provider address
        if(valid_token_res && valid_token_res.data.body){
          const res = await baseURL.get("/utility/getSetting",{
            headers:{ 
              Authorization: `Bearer ${valid_token_res.data.body}`,
            },
          });
          this.consultation_fee_name = res.data.consultation_fee_name;
          this.customized_pns_logo = res.data.pns_brand_logo;
        }
        return Promise.resolve(this.consultation_fee_name);
      }catch(err){
        this.consultation_fee_name = "Consultation Fee";
        return Promise.reject(err);
      }
    },
    async fetchClinicInfo() {
      const params = {
        accessionId: Number(this.accession_id),
      };
      try {
        const res = await patientService.get("/getProviderAndClinicName", {
          params: params,
        });
        this.clinic_name = res.data.clinicName;
        localStorage.setItem("clinic_name", res.data.clinicName);
        this.provider_name = res.data.providerName;
        localStorage.setItem("provider_name", res.data.providerName);
        this.customer_id = res.data.customerId;
        localStorage.setItem("customer_id", res.data.customerId);
        this.charge_method = res.data.chargeMethod;
        localStorage.setItem("charge_method", res.data.chargeMethod);
        this.address_needed = res.data.addressCheckNeeded;
        localStorage.setItem("address_needed", res.data.addressCheckNeeded);
        this.blood_draw_needed = res.data.bloodDrawNeeded;
        localStorage.setItem("blood_draw_needed", res.data.bloodDrawNeeded);
        this.patient_id = res.data.patientId;
        localStorage.setItem("patient_id", res.data.patientId);
        localStorage.setItem(
          "beta_program_enabled",
          res.data.beta_program_enabled
        );
        localStorage.setItem("beta_programs", res.data.beta_programs);
        //whether is it valid for concierge service in general (consider both patient and provider address)
        this.is_valid_concierge = res.data.is_concierge_valid_general;
        return Promise.resolve(res.data);
      } catch (err) {
        console.log("failed to fetch clinic info", err);
        localStorage.removeItem("clinic_name");
        localStorage.removeItem("provider_name");
        localStorage.removeItem("customer_id");
        localStorage.removeItem("charge_method");
        localStorage.removeItem("address_needed");
        localStorage.removeItem("blood_draw_needed");
        localStorage.removeItem("patient_id");
        localStorage.removeItem("beta_program_enabled");
        localStorage.removeItem("beta_programs");
        return Promise.reject(err);
      }
    },
    async fetchPatientAddressInfo(){
      try{
        const res = await orderBaseURL.get('/orderTest/patient', {
            headers:{ 
              Authorization: `Bearer ${localStorage.getItem('pnsToken') ? localStorage.getItem('pnsToken') : this.token}`
            },
            params:{
              patientId:this.patient_id
            },
        });
        
        localStorage.setItem('patient_details', JSON.stringify(res.data));
        this.patient_address = res.data.patient_address && res.data.patient_address.length>0 ? res.data.patient_address[0]:{};

        return res.data.patient_address && res.data.patient_address.length>0
        ? Promise.resolve(res.data.patient_address[0]):Promise.resolve({});
      }catch(err){
        localStorage.removeItem('patient_details');
        console.log("fetchPatientAddressInfo error:", err);
        return Promise.reject(err);
      }
    },
    async fetchCustomerInfo(){
      const token = localStorage.getItem('pnsToken') ? localStorage.getItem('pnsToken') : this.token;
      const params = {
        jwtToken: token,
      };
      try{
        const res = await orderBaseURL.post('/customer/getCustomerInfo', params,
          {
            headers:{ 
                Authorization: `Bearer ${token}`
              },
          }
        );
        this.customer_info = res.data;
        return Promise.resolve(res.data);
      }catch(err){
        console.log("fetchCustomerInfo error:", err);
        return Promise.reject(err);
      }
    },
    async getBillingInfo() {
      const params = {
        charge_type_id: this.charge_type_id,
        charge_type: this.charge_type,
      };
      try {
        const res = await billingService.get("/invoice", { params: params });
        this.billingInfo = res.data;
        console.log("fetch billing info", res.data);
        localStorage.setItem("billingInfo", JSON.stringify(res.data));
        return Promise.resolve(res.data);
      } catch (err) {
        console.log("failed to retrieve billing info", err);
        this.billingInfo = "";
        localStorage.removeItem("billingInfo");
        return Promise.reject(err);
      }
    },
    async fetchSupportedBnplMethods(){
      try{
        const res = await payoutBaseURL.get('/recipient/getEligibilityOfBNPL', {
          params:{
            currency: this.billingInfo.currency,
            order_id: this.charge_type_id,
          },
          headers:{
            Authorization: `Bearer ${this.token}`
          }
        });
        this.supported_bnpl_methods_data = res.data;
        // eg. {
        //   klarna_eligible:true,
        //   afterpay_clearpay_eligible:true,
        //   //need to used for bnpl charging api
        //   connect_account_id:null,
        // };
        return Promise.resolve(res.data);
      }catch(err){
        console.log("fetchSupportedBNPLMethods error", err);
        this.supported_bnpl_methods_data=null;
        return Promise.reject(err);
      }
    },
    async applyPromo(promoCode) {
      const params = {
        coupon_code: promoCode,
        need_apply: true,
        charge_url: this.charge_type + "::" + this.charge_type_id,
      };
      this.loadingPromo = true;
      try {
        const res = await billingService.post("/verifyCouponCode", params);
        this.loadingPromo = false;
        this.getBillingInfo();
        return Promise.resolve(res.data);
      } catch (err) {
        this.loadingPromo = false;
        return Promise.reject(err);
      }
    },
    async generateBnplIntent(provider) {
      const params = {
        account_id: this.billingInfo
          ? Number(this.billingInfo.account_id)
          : null,
        account_type: this.billingInfo ? this.billingInfo.account_type : null,
        amount: this.billingInfo ? Number(this.billingInfo.total) : null,
        currency: this.billingInfo.currency,
        charge_type: this.charge_type,
        charge_type_id: this.charge_type_id,
        bnpl_provider: provider,
        new_sample: false,
        sample_id: Number(this.sample_id),
        token_platform: this.provider_has_revenue == true ? "stripe_zb" : "stripe",
        connect_account_id: this.supported_bnpl_methods_data.connect_account_id,
      };
      try {
        const res = await chargeService.post("/transaction/payLater", params);
        return Promise.resolve(res.data.client_secret);
      } catch (err) {
        console.log("failed to generate bnpl intent", err);
        return Promise.reject(err);
      }
    },
    async generateStripeCardPayIntent(isHSACard) {
      let amountToPay;
      if (localStorage.getItem("amount_to_pay")) {
        amountToPay = parseFloat(
          (Number(localStorage.getItem("amount_to_pay")) / 100).toFixed(2)
        );
      } else {
        if (this.billingInfo) {
          amountToPay = Number(this.billingInfo.total);
        } else {
          amountToPay = null;
        }
      }
      const params = {
        account_id: this.billingInfo
          ? Number(this.billingInfo.account_id)
          : null,
        account_type: this.billingInfo ? this.billingInfo.account_type : null,
        amount: amountToPay,
        currency: this.billingInfo.currency,
        charge_type: this.charge_type,
        charge_type_id: this.charge_type_id,
        new_sample: false,
        sample_id: Number(this.sample_id),
        token_platform: this.provider_has_revenue == true ? "stripe_zb" : "stripe",
        type: "card",
      };
      if (isHSACard) {
        try {
          const res = await chargeService.post(
            "/transaction/payWithHFSA",
            params
          );
          return Promise.resolve(res.data.client_secret);
        } catch (err) {
          console.log("failed to generate stripe card pay intent", err);
          return Promise.reject(err);
        }
      } else {
        try {
          const res = await chargeService.post(
            "/transaction/pay",
            params
          );
          return Promise.resolve(res.data.client_secret);
        } catch (err) {
          console.log("failed to generate stripe card pay intent", err);
          return Promise.reject(err);
        }
      }
    },
    async generateStripeDigitalWalletPayIntent() {
      const params = {
        account_id: this.billingInfo
          ? Number(this.billingInfo.account_id)
          : null,
        account_type: this.billingInfo ? this.billingInfo.account_type : null,
        amount: this.billingInfo ? Number(this.billingInfo.total) : null,
        currency: this.billingInfo.currency,
        charge_type: this.charge_type,
        charge_type_id: this.charge_type_id,
        new_sample: false,
        sample_id: Number(this.sample_id),
        token_platform: this.provider_has_revenue == true ? "stripe_zb" : "stripe",
      };
      console.log("generate stripe card pay intent", params);
      try {
        const res = await chargeService.post(
          "/transaction/payWithDigitalWallets",
          params
        );
        return Promise.resolve(res.data.client_secret);
      } catch (err) {
        console.log("failed to generate stripe digital pay intent", err);
        return Promise.reject(err);
      }
    },
    async getPaymentStatus() {
      const chargeTypeId = this.charge_type_id;
      const chargeType = this.charge_type;
      try {
        const res = await chargeService.get(
          `/transaction/${chargeTypeId}/type/${chargeType}`
        );
        console.log("payment status", res.data);
        this.paymentStatus = res.data.status;
        return Promise.resolve(res.data);
      } catch (err) {
        console.log("failed to get payment status", err);
        return Promise.reject(err);
      }
    },
    async payByCard(token) {
      let amountToPay;
      if (localStorage.getItem("amount_to_pay")) {
        amountToPay = parseFloat(
          (Number(localStorage.getItem("amount_to_pay")) / 100).toFixed(2)
        );
      } else {
        if (this.billingInfo) {
          amountToPay = Number(this.billingInfo.total);
        } else {
          amountToPay = null;
        }
      }
      const params = {
        account_id: this.billingInfo
          ? Number(this.billingInfo.account_id)
          : null,
        account_type: this.billingInfo ? this.billingInfo.account_type : null,
        amount: amountToPay,
        currency: this.billingInfo.currency,
        type: "card",
        token_platform: "stax",
        payment_token: token,
        charge_type: this.charge_type,
        charge_type_id: this.charge_type_id,
        notes: "",
        new_sample: false,
        sample_id: Number(this.sample_id),
      };
      console.log('params', params);
      try {
        // console.log('payByCard', localStorage.getItem('pnsToken'), this.token);
        //debug missing token
        if(localStorage.getItem('pnsToken')){
          const res = await chargeService.post("/transaction/pay", params);
          return Promise.resolve(res.data);
        }else{
          const res = await chargeService.post("/transaction/pay", params,
            {
              headers:{ 
                  Authorization: `Bearer ${this.token}`
                },
            }
          );
          return Promise.resolve(res.data);
        }
      } catch (err) {
        console.log("failed to pay by card", err);
        return Promise.reject(err);
      }
    },
    //to ask backend to record or cancel the concierge service if needed
    //if has_concierge_service is not the same value as the backend_has_concierge_service: need to update at backend
    async updateConciergeServiceViaBackend(){
      //if the concierge service checkbox is checked but the backend has no record for concierge service
      if(this.has_concierge_service && !this.backend_has_concierge_service){
        //call the api to add concierge service at the backend
        try{
          const params = {
            accessionId: Number(this.accession_id),
            amount:"99",
          }
          const res = await orderBaseURL.post('/orderTest/recordConciergeFee',
            params,
            {
              headers:{
                Authorization:`Bearer ${this.token}`
              }
            }
          );
          //after successfully added, update the billingInfo.total by plusing 99 and backend_has_concierge_service
          this.billingInfo.total = String(Number(this.billingInfo.total)+ 99);
          this.backend_has_concierge_service = true;
          return Promise.resolve(
            {
              success:true,
              real_operation:true
            }
          );
        }catch(err){
          console.log('/orderTest/recordConciergeFee', err);
          return Promise.reject(err);
        }
      }else if(!this.has_concierge_service && this.backend_has_concierge_service){
      //if the concierge service checkbox isn't checked but the backend has a record for concierge service
      //call api to cancel concierge fee
        try{
          const params = {
            accessionId: Number(this.accession_id),
          }
          const res = await orderBaseURL.post('/orderTest/cancelSelectedConciergeFee',
            params,
            {
              headers:{
                Authorization:`Bearer ${this.token}`
              }
            }
          );
          //after successfully added, update the billingInfo.total by plusing 99 and backend_has_concierge_service
          this.billingInfo.total = String(Number(this.billingInfo.total)- 99);
          this.backend_has_concierge_service = false;
          return Promise.resolve(
            {
              success:true,
              real_operation:true
            }
          );
        }catch(err){
          console.log('/orderTest/cancelSelectedConciergeFee', err);
          return Promise.reject(err);
        }
      }else{
        return Promise.resolve(
          {
            success:true,
            real_operation:false,
          }
        );
      }
    },
    // temporary use for blood concierge beta 1 launch - blood_concierge
    checkWhetherBloodConciergeBeta1(){
      if(localStorage && localStorage.getItem("beta_program_enabled")=='true' && localStorage.getItem("beta_programs").includes('blood_concierge')){
        this.blood_concierge_beta_1 = true;
      }else{
        this.blood_concierge_beta_1 = false;
      }
    }
    // saveBillingInfo() {
    //   const params = {
    //     email: this.billing_email,
    //     charge_type: this.charge_type,
    //     charge_type_id: this.charge_type_id,
    //     first_name: this.billing_firstName,
    //     last_name: this.billing_lastName,
    //     phone: this.billing_phone,
    //     address_1: this.billing_address,
    //     address_2: this.billing_address2,
    //     address_city: this.billing_city,
    //     address_state: this.billing_state,
    //     address_zip: this.billing_zipCode,
    //     address_country: this.billing_country,
    //   };
    //   console.log("save billing info", params);
    //   chargeService
    //     .post("/billingInfo", params)
    //     .then((res) => {
    //       console.log("save billing info", res.data);
    //     })
    //     .catch((err) => {
    //       console.log("failed to save billing info", err);
    //     });
    // },
  },
});
