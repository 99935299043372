export const backup_country_options_for_phone = [
    {
        "vibrantCountryId": 1,
        "countryName": "Afghanistan",
        "countryNameTwoBytes": "AF"
    },
    {
        "vibrantCountryId": 2,
        "countryName": "Åland Islands",
        "countryNameTwoBytes": "AX"
    },
    {
        "vibrantCountryId": 3,
        "countryName": "Albania",
        "countryNameTwoBytes": "AL"
    },
    {
        "vibrantCountryId": 4,
        "countryName": "Algeria",
        "countryNameTwoBytes": "DZ"
    },
    {
        "vibrantCountryId": 5,
        "countryName": "American Samoa",
        "countryNameTwoBytes": "AS"
    },
    {
        "vibrantCountryId": 6,
        "countryName": "Andorra",
        "countryNameTwoBytes": "AD"
    },
    {
        "vibrantCountryId": 7,
        "countryName": "Angola",
        "countryNameTwoBytes": "AO"
    },
    {
        "vibrantCountryId": 8,
        "countryName": "Anguilla",
        "countryNameTwoBytes": "AI"
    },
    {
        "vibrantCountryId": 9,
        "countryName": "Antarctica",
        "countryNameTwoBytes": "AQ"
    },
    {
        "vibrantCountryId": 10,
        "countryName": "Antigua & Barbuda",
        "countryNameTwoBytes": "AG"
    },
    {
        "vibrantCountryId": 11,
        "countryName": "Argentina",
        "countryNameTwoBytes": "AR"
    },
    {
        "vibrantCountryId": 12,
        "countryName": "Armenia",
        "countryNameTwoBytes": "AM"
    },
    {
        "vibrantCountryId": 13,
        "countryName": "Aruba",
        "countryNameTwoBytes": "AW"
    },
    {
        "vibrantCountryId": 14,
        "countryName": "Australia",
        "countryNameTwoBytes": "AU"
    },
    {
        "vibrantCountryId": 15,
        "countryName": "Austria",
        "countryNameTwoBytes": "AT"
    },
    {
        "vibrantCountryId": 16,
        "countryName": "Azerbaijan",
        "countryNameTwoBytes": "AZ"
    },
    {
        "vibrantCountryId": 17,
        "countryName": "Bahama",
        "countryNameTwoBytes": "BS"
    },
    {
        "vibrantCountryId": 18,
        "countryName": "Bahrain",
        "countryNameTwoBytes": "BH"
    },
    {
        "vibrantCountryId": 19,
        "countryName": "Bangladesh",
        "countryNameTwoBytes": "BD"
    },
    {
        "vibrantCountryId": 20,
        "countryName": "Barbados",
        "countryNameTwoBytes": "BB"
    },
    {
        "vibrantCountryId": 21,
        "countryName": "Belarus",
        "countryNameTwoBytes": "BY"
    },
    {
        "vibrantCountryId": 22,
        "countryName": "Belgium",
        "countryNameTwoBytes": "BE"
    },
    {
        "vibrantCountryId": 23,
        "countryName": "Belize",
        "countryNameTwoBytes": "BZ"
    },
    {
        "vibrantCountryId": 24,
        "countryName": "Benin",
        "countryNameTwoBytes": "BJ"
    },
    {
        "vibrantCountryId": 25,
        "countryName": "Bermuda",
        "countryNameTwoBytes": "BM"
    },
    {
        "vibrantCountryId": 26,
        "countryName": "Bhutan",
        "countryNameTwoBytes": "BT"
    },
    {
        "vibrantCountryId": 27,
        "countryName": "Bolivia",
        "countryNameTwoBytes": "BO"
    },
    {
        "vibrantCountryId": 28,
        "countryName": "Bonaire, Sint Eustatius and Saba",
        "countryNameTwoBytes": "BQ"
    },
    {
        "vibrantCountryId": 29,
        "countryName": "Bosnia-Herzegovina",
        "countryNameTwoBytes": "BA"
    },
    {
        "vibrantCountryId": 30,
        "countryName": "Botswana",
        "countryNameTwoBytes": "BW"
    },
    {
        "vibrantCountryId": 31,
        "countryName": "Bouvet Island",
        "countryNameTwoBytes": "BV"
    },
    {
        "vibrantCountryId": 32,
        "countryName": "Brazil",
        "countryNameTwoBytes": "BR"
    },
    {
        "vibrantCountryId": 33,
        "countryName": "British Indian Ocean Territory",
        "countryNameTwoBytes": "IO"
    },
    {
        "vibrantCountryId": 34,
        "countryName": "British Virgin Islands",
        "countryNameTwoBytes": "VG"
    },
    {
        "vibrantCountryId": 35,
        "countryName": "Brunei Darussalam",
        "countryNameTwoBytes": "BN"
    },
    {
        "vibrantCountryId": 36,
        "countryName": "Bulgaria",
        "countryNameTwoBytes": "BG"
    },
    {
        "vibrantCountryId": 37,
        "countryName": "Burkina Faso",
        "countryNameTwoBytes": "BF"
    },
    {
        "vibrantCountryId": 38,
        "countryName": "Burundi",
        "countryNameTwoBytes": "BI"
    },
    {
        "vibrantCountryId": 39,
        "countryName": "Cabo Verde",
        "countryNameTwoBytes": "CV"
    },
    {
        "vibrantCountryId": 40,
        "countryName": "Cambodia",
        "countryNameTwoBytes": "KH"
    },
    {
        "vibrantCountryId": 41,
        "countryName": "Cameroon",
        "countryNameTwoBytes": "CM"
    },
    {
        "vibrantCountryId": 42,
        "countryName": "Canada",
        "countryNameTwoBytes": "CA"
    },
    {
        "vibrantCountryId": 43,
        "countryName": "Cayman Islands",
        "countryNameTwoBytes": "KY"
    },
    {
        "vibrantCountryId": 44,
        "countryName": "Central African Republic",
        "countryNameTwoBytes": "CF"
    },
    {
        "vibrantCountryId": 45,
        "countryName": "Chad",
        "countryNameTwoBytes": "TD"
    },
    {
        "vibrantCountryId": 46,
        "countryName": "Chile",
        "countryNameTwoBytes": "CL"
    },
    {
        "vibrantCountryId": 47,
        "countryName": "China",
        "countryNameTwoBytes": "CN"
    },
    {
        "vibrantCountryId": 48,
        "countryName": "Christmas Island",
        "countryNameTwoBytes": "CX"
    },
    {
        "vibrantCountryId": 49,
        "countryName": "Cocos (Keeling) Islands",
        "countryNameTwoBytes": "CC"
    },
    {
        "vibrantCountryId": 50,
        "countryName": "Colombia",
        "countryNameTwoBytes": "CO"
    },
    {
        "vibrantCountryId": 51,
        "countryName": "Comoros",
        "countryNameTwoBytes": "KM"
    },
    {
        "vibrantCountryId": 52,
        "countryName": "Congo",
        "countryNameTwoBytes": "CG"
    },
    {
        "vibrantCountryId": 53,
        "countryName": "Congo, Dem Rep Of",
        "countryNameTwoBytes": "CD"
    },
    {
        "vibrantCountryId": 54,
        "countryName": "Cook Islands",
        "countryNameTwoBytes": "CK"
    },
    {
        "vibrantCountryId": 55,
        "countryName": "Costa Rica",
        "countryNameTwoBytes": "CR"
    },
    {
        "vibrantCountryId": 56,
        "countryName": "Côte D'ivoire (Ivory Coast)",
        "countryNameTwoBytes": "CI"
    },
    {
        "vibrantCountryId": 57,
        "countryName": "Croatia",
        "countryNameTwoBytes": "HR"
    },
    {
        "vibrantCountryId": 58,
        "countryName": "Cuba",
        "countryNameTwoBytes": "CU"
    },
    {
        "vibrantCountryId": 59,
        "countryName": "Curaçao",
        "countryNameTwoBytes": "CW"
    },
    {
        "vibrantCountryId": 60,
        "countryName": "Cyprus",
        "countryNameTwoBytes": "CY"
    },
    {
        "vibrantCountryId": 61,
        "countryName": "Czech Republic",
        "countryNameTwoBytes": "CZ"
    },
    {
        "vibrantCountryId": 62,
        "countryName": "Denmark",
        "countryNameTwoBytes": "DK"
    },
    {
        "vibrantCountryId": 63,
        "countryName": "Djibouti",
        "countryNameTwoBytes": "DJ"
    },
    {
        "vibrantCountryId": 64,
        "countryName": "Dominica",
        "countryNameTwoBytes": "DM"
    },
    {
        "vibrantCountryId": 65,
        "countryName": "Dominican Republic",
        "countryNameTwoBytes": "DO"
    },
    {
        "vibrantCountryId": 66,
        "countryName": "East Timor",
        "countryNameTwoBytes": "TL"
    },
    {
        "vibrantCountryId": 67,
        "countryName": "Ecuador",
        "countryNameTwoBytes": "EC"
    },
    {
        "vibrantCountryId": 68,
        "countryName": "Egypt",
        "countryNameTwoBytes": "EG"
    },
    {
        "vibrantCountryId": 69,
        "countryName": "El Salvador",
        "countryNameTwoBytes": "SV"
    },
    {
        "vibrantCountryId": 70,
        "countryName": "Equatorial Guinea",
        "countryNameTwoBytes": "GQ"
    },
    {
        "vibrantCountryId": 71,
        "countryName": "Eritrea",
        "countryNameTwoBytes": "ER"
    },
    {
        "vibrantCountryId": 72,
        "countryName": "Estonia",
        "countryNameTwoBytes": "EE"
    },
    {
        "vibrantCountryId": 73,
        "countryName": "Ethiopia",
        "countryNameTwoBytes": "ET"
    },
    {
        "vibrantCountryId": 74,
        "countryName": "Falkland Islands (Malvinas)",
        "countryNameTwoBytes": "FK"
    },
    {
        "vibrantCountryId": 75,
        "countryName": "Faroe Islands",
        "countryNameTwoBytes": "FO"
    },
    {
        "vibrantCountryId": 76,
        "countryName": "Fiji",
        "countryNameTwoBytes": "FJ"
    },
    {
        "vibrantCountryId": 77,
        "countryName": "Finland",
        "countryNameTwoBytes": "FI"
    },
    {
        "vibrantCountryId": 78,
        "countryName": "France",
        "countryNameTwoBytes": "FR"
    },
    {
        "vibrantCountryId": 79,
        "countryName": "French Guiana",
        "countryNameTwoBytes": "GF"
    },
    {
        "vibrantCountryId": 80,
        "countryName": "French Polynesia",
        "countryNameTwoBytes": "PF"
    },
    {
        "vibrantCountryId": 81,
        "countryName": "French Southern Territories",
        "countryNameTwoBytes": "TF"
    },
    {
        "vibrantCountryId": 82,
        "countryName": "Gabon",
        "countryNameTwoBytes": "GA"
    },
    {
        "vibrantCountryId": 83,
        "countryName": "Gambia",
        "countryNameTwoBytes": "GM"
    },
    {
        "vibrantCountryId": 84,
        "countryName": "Georgia",
        "countryNameTwoBytes": "GE"
    },
    {
        "vibrantCountryId": 85,
        "countryName": "Germany",
        "countryNameTwoBytes": "DE"
    },
    {
        "vibrantCountryId": 86,
        "countryName": "Ghana",
        "countryNameTwoBytes": "GH"
    },
    {
        "vibrantCountryId": 87,
        "countryName": "Gibraltar",
        "countryNameTwoBytes": "GI"
    },
    {
        "vibrantCountryId": 88,
        "countryName": "Greece",
        "countryNameTwoBytes": "GR"
    },
    {
        "vibrantCountryId": 89,
        "countryName": "Greenland",
        "countryNameTwoBytes": "GL"
    },
    {
        "vibrantCountryId": 90,
        "countryName": "Grenada",
        "countryNameTwoBytes": "GD"
    },
    {
        "vibrantCountryId": 91,
        "countryName": "Guadeloupe",
        "countryNameTwoBytes": "GP"
    },
    {
        "vibrantCountryId": 92,
        "countryName": "Guam",
        "countryNameTwoBytes": "GU"
    },
    {
        "vibrantCountryId": 93,
        "countryName": "Guatemala",
        "countryNameTwoBytes": "GT"
    },
    {
        "vibrantCountryId": 94,
        "countryName": "Guernsey",
        "countryNameTwoBytes": "GG"
    },
    {
        "vibrantCountryId": 95,
        "countryName": "Guinea",
        "countryNameTwoBytes": "GN"
    },
    {
        "vibrantCountryId": 96,
        "countryName": "Guinea-Bissau",
        "countryNameTwoBytes": "GW"
    },
    {
        "vibrantCountryId": 97,
        "countryName": "Guyana",
        "countryNameTwoBytes": "GY"
    },
    {
        "vibrantCountryId": 98,
        "countryName": "Haiti",
        "countryNameTwoBytes": "HT"
    },
    {
        "vibrantCountryId": 99,
        "countryName": "Heard Island and McDonald Islands",
        "countryNameTwoBytes": "HM"
    },
    {
        "vibrantCountryId": 100,
        "countryName": "Holy See",
        "countryNameTwoBytes": "VA"
    },
    {
        "vibrantCountryId": 101,
        "countryName": "Honduras",
        "countryNameTwoBytes": "HN"
    },
    {
        "vibrantCountryId": 102,
        "countryName": "Hong Kong",
        "countryNameTwoBytes": "HK"
    },
    {
        "vibrantCountryId": 103,
        "countryName": "Hungary",
        "countryNameTwoBytes": "HU"
    },
    {
        "vibrantCountryId": 104,
        "countryName": "Iceland",
        "countryNameTwoBytes": "IS"
    },
    {
        "vibrantCountryId": 105,
        "countryName": "India",
        "countryNameTwoBytes": "IN"
    },
    {
        "vibrantCountryId": 106,
        "countryName": "Indonesia",
        "countryNameTwoBytes": "ID"
    },
    {
        "vibrantCountryId": 107,
        "countryName": "Iran (Islamic Republic of)",
        "countryNameTwoBytes": "IR"
    },
    {
        "vibrantCountryId": 108,
        "countryName": "Iraq",
        "countryNameTwoBytes": "IQ"
    },
    {
        "vibrantCountryId": 109,
        "countryName": "Ireland",
        "countryNameTwoBytes": "IE"
    },
    {
        "vibrantCountryId": 110,
        "countryName": "Isle of Man",
        "countryNameTwoBytes": "IM"
    },
    {
        "vibrantCountryId": 111,
        "countryName": "Israel",
        "countryNameTwoBytes": "IL"
    },
    {
        "vibrantCountryId": 112,
        "countryName": "Italy",
        "countryNameTwoBytes": "IT"
    },
    {
        "vibrantCountryId": 113,
        "countryName": "Jamaica",
        "countryNameTwoBytes": "JM"
    },
    {
        "vibrantCountryId": 114,
        "countryName": "Japan",
        "countryNameTwoBytes": "JP"
    },
    {
        "vibrantCountryId": 115,
        "countryName": "Jersey",
        "countryNameTwoBytes": "JE"
    },
    {
        "vibrantCountryId": 116,
        "countryName": "Jordan",
        "countryNameTwoBytes": "JO"
    },
    {
        "vibrantCountryId": 117,
        "countryName": "Kazakhstan",
        "countryNameTwoBytes": "KZ"
    },
    {
        "vibrantCountryId": 118,
        "countryName": "Kenya",
        "countryNameTwoBytes": "KE"
    },
    {
        "vibrantCountryId": 119,
        "countryName": "Kiribati",
        "countryNameTwoBytes": "KI"
    },
    {
        "vibrantCountryId": 120,
        "countryName": "Korea (Democratic People's Republic of)",
        "countryNameTwoBytes": "KP"
    },
    {
        "vibrantCountryId": 121,
        "countryName": "Kuwait",
        "countryNameTwoBytes": "KW"
    },
    {
        "vibrantCountryId": 122,
        "countryName": "Kyrgyzstan",
        "countryNameTwoBytes": "KG"
    },
    {
        "vibrantCountryId": 123,
        "countryName": "Laos",
        "countryNameTwoBytes": "LA"
    },
    {
        "vibrantCountryId": 124,
        "countryName": "Latvia",
        "countryNameTwoBytes": "LV"
    },
    {
        "vibrantCountryId": 125,
        "countryName": "Lebanon",
        "countryNameTwoBytes": "LB"
    },
    {
        "vibrantCountryId": 126,
        "countryName": "Lesotho",
        "countryNameTwoBytes": "LS"
    },
    {
        "vibrantCountryId": 127,
        "countryName": "Liberia",
        "countryNameTwoBytes": "LR"
    },
    {
        "vibrantCountryId": 128,
        "countryName": "Libya",
        "countryNameTwoBytes": "LY"
    },
    {
        "vibrantCountryId": 129,
        "countryName": "Liechtenstein",
        "countryNameTwoBytes": "LI"
    },
    {
        "vibrantCountryId": 130,
        "countryName": "Lithuania",
        "countryNameTwoBytes": "LT"
    },
    {
        "vibrantCountryId": 131,
        "countryName": "Luxembourg",
        "countryNameTwoBytes": "LU"
    },
    {
        "vibrantCountryId": 132,
        "countryName": "Macau",
        "countryNameTwoBytes": "MO"
    },
    {
        "vibrantCountryId": 133,
        "countryName": "Macedonia",
        "countryNameTwoBytes": "MK"
    },
    {
        "vibrantCountryId": 134,
        "countryName": "Madagascar",
        "countryNameTwoBytes": "MG"
    },
    {
        "vibrantCountryId": 135,
        "countryName": "Malawi",
        "countryNameTwoBytes": "MW"
    },
    {
        "vibrantCountryId": 136,
        "countryName": "Malaysia",
        "countryNameTwoBytes": "MY"
    },
    {
        "vibrantCountryId": 137,
        "countryName": "Maldives",
        "countryNameTwoBytes": "MV"
    },
    {
        "vibrantCountryId": 138,
        "countryName": "Mali",
        "countryNameTwoBytes": "ML"
    },
    {
        "vibrantCountryId": 139,
        "countryName": "Malta",
        "countryNameTwoBytes": "MT"
    },
    {
        "vibrantCountryId": 140,
        "countryName": "Marshall Islands",
        "countryNameTwoBytes": "MH"
    },
    {
        "vibrantCountryId": 141,
        "countryName": "Martinique",
        "countryNameTwoBytes": "MQ"
    },
    {
        "vibrantCountryId": 142,
        "countryName": "Mauritania",
        "countryNameTwoBytes": "MR"
    },
    {
        "vibrantCountryId": 143,
        "countryName": "Mauritius",
        "countryNameTwoBytes": "MU"
    },
    {
        "vibrantCountryId": 144,
        "countryName": "Mayotte",
        "countryNameTwoBytes": "YT"
    },
    {
        "vibrantCountryId": 145,
        "countryName": "Mexico",
        "countryNameTwoBytes": "MX"
    },
    {
        "vibrantCountryId": 146,
        "countryName": "Micronesia",
        "countryNameTwoBytes": "FM"
    },
    {
        "vibrantCountryId": 147,
        "countryName": "Moldova, Republic of",
        "countryNameTwoBytes": "MD"
    },
    {
        "vibrantCountryId": 148,
        "countryName": "Monaco",
        "countryNameTwoBytes": "MC"
    },
    {
        "vibrantCountryId": 149,
        "countryName": "Mongolia",
        "countryNameTwoBytes": "MN"
    },
    {
        "vibrantCountryId": 150,
        "countryName": "Monserrat",
        "countryNameTwoBytes": "MS"
    },
    {
        "vibrantCountryId": 151,
        "countryName": "Montenegro",
        "countryNameTwoBytes": "ME"
    },
    {
        "vibrantCountryId": 152,
        "countryName": "Morocco",
        "countryNameTwoBytes": "MA"
    },
    {
        "vibrantCountryId": 153,
        "countryName": "Mozambique",
        "countryNameTwoBytes": "MZ"
    },
    {
        "vibrantCountryId": 154,
        "countryName": "Myanmar",
        "countryNameTwoBytes": "MM"
    },
    {
        "vibrantCountryId": 155,
        "countryName": "Namibia",
        "countryNameTwoBytes": "NA"
    },
    {
        "vibrantCountryId": 156,
        "countryName": "Nauru",
        "countryNameTwoBytes": "NR"
    },
    {
        "vibrantCountryId": 157,
        "countryName": "Nepal",
        "countryNameTwoBytes": "NP"
    },
    {
        "vibrantCountryId": 158,
        "countryName": "Netherlands",
        "countryNameTwoBytes": "NL"
    },
    {
        "vibrantCountryId": 159,
        "countryName": "New Caledonia",
        "countryNameTwoBytes": "NC"
    },
    {
        "vibrantCountryId": 160,
        "countryName": "New Zealand",
        "countryNameTwoBytes": "NZ"
    },
    {
        "vibrantCountryId": 161,
        "countryName": "Nicaragua",
        "countryNameTwoBytes": "NI"
    },
    {
        "vibrantCountryId": 162,
        "countryName": "Niger",
        "countryNameTwoBytes": "NE"
    },
    {
        "vibrantCountryId": 163,
        "countryName": "Nigeria",
        "countryNameTwoBytes": "NG"
    },
    {
        "vibrantCountryId": 164,
        "countryName": "Niue",
        "countryNameTwoBytes": "NU"
    },
    {
        "vibrantCountryId": 165,
        "countryName": "Norfolk Island",
        "countryNameTwoBytes": "NF"
    },
    {
        "vibrantCountryId": 166,
        "countryName": "Northern Mariana Islands",
        "countryNameTwoBytes": "MP"
    },
    {
        "vibrantCountryId": 167,
        "countryName": "Norway",
        "countryNameTwoBytes": "NO"
    },
    {
        "vibrantCountryId": 168,
        "countryName": "Oman",
        "countryNameTwoBytes": "OM"
    },
    {
        "vibrantCountryId": 169,
        "countryName": "Pakistan",
        "countryNameTwoBytes": "PK"
    },
    {
        "vibrantCountryId": 170,
        "countryName": "Palau",
        "countryNameTwoBytes": "PW"
    },
    {
        "vibrantCountryId": 171,
        "countryName": "Palestine Authority",
        "countryNameTwoBytes": "PS"
    },
    {
        "vibrantCountryId": 172,
        "countryName": "Panama",
        "countryNameTwoBytes": "PA"
    },
    {
        "vibrantCountryId": 173,
        "countryName": "Papua New Guinea",
        "countryNameTwoBytes": "PG"
    },
    {
        "vibrantCountryId": 174,
        "countryName": "Paraguay",
        "countryNameTwoBytes": "PY"
    },
    {
        "vibrantCountryId": 175,
        "countryName": "Peru",
        "countryNameTwoBytes": "PE"
    },
    {
        "vibrantCountryId": 176,
        "countryName": "Philippines",
        "countryNameTwoBytes": "PH"
    },
    {
        "vibrantCountryId": 177,
        "countryName": "Pitcairn",
        "countryNameTwoBytes": "PN"
    },
    {
        "vibrantCountryId": 178,
        "countryName": "Poland",
        "countryNameTwoBytes": "PL"
    },
    {
        "vibrantCountryId": 179,
        "countryName": "Portugal",
        "countryNameTwoBytes": "PT"
    },
    {
        "vibrantCountryId": 180,
        "countryName": "Puerto Rico",
        "countryNameTwoBytes": "PR"
    },
    {
        "vibrantCountryId": 181,
        "countryName": "Qatar",
        "countryNameTwoBytes": "QA"
    },
    {
        "vibrantCountryId": 182,
        "countryName": "Réunion",
        "countryNameTwoBytes": "RE"
    },
    {
        "vibrantCountryId": 183,
        "countryName": "Romania",
        "countryNameTwoBytes": "RO"
    },
    {
        "vibrantCountryId": 184,
        "countryName": "Russian Federation",
        "countryNameTwoBytes": "RU"
    },
    {
        "vibrantCountryId": 185,
        "countryName": "Rwanda",
        "countryNameTwoBytes": "RW"
    },
    {
        "vibrantCountryId": 186,
        "countryName": "Saint Barthélemy",
        "countryNameTwoBytes": "BL"
    },
    {
        "vibrantCountryId": 187,
        "countryName": "Saint Helena, Ascension and Tristan da Cunha",
        "countryNameTwoBytes": "SH"
    },
    {
        "vibrantCountryId": 188,
        "countryName": "Saint Lucia",
        "countryNameTwoBytes": "LC"
    },
    {
        "vibrantCountryId": 189,
        "countryName": "Saint Pierre and Miquelon",
        "countryNameTwoBytes": "PM"
    },
    {
        "vibrantCountryId": 190,
        "countryName": "Samoa",
        "countryNameTwoBytes": "WS"
    },
    {
        "vibrantCountryId": 191,
        "countryName": "San Marino",
        "countryNameTwoBytes": "SM"
    },
    {
        "vibrantCountryId": 192,
        "countryName": "Sao Tome and Principe",
        "countryNameTwoBytes": "ST"
    },
    {
        "vibrantCountryId": 193,
        "countryName": "Saudi Arabia",
        "countryNameTwoBytes": "SA"
    },
    {
        "vibrantCountryId": 194,
        "countryName": "Senegal",
        "countryNameTwoBytes": "SN"
    },
    {
        "vibrantCountryId": 195,
        "countryName": "Serbia",
        "countryNameTwoBytes": "RS"
    },
    {
        "vibrantCountryId": 196,
        "countryName": "Seychelles",
        "countryNameTwoBytes": "SC"
    },
    {
        "vibrantCountryId": 197,
        "countryName": "Sierra Leone",
        "countryNameTwoBytes": "SL"
    },
    {
        "vibrantCountryId": 198,
        "countryName": "Singapore",
        "countryNameTwoBytes": "SG"
    },
    {
        "vibrantCountryId": 199,
        "countryName": "Slovakia",
        "countryNameTwoBytes": "SK"
    },
    {
        "vibrantCountryId": 200,
        "countryName": "Slovenia",
        "countryNameTwoBytes": "SI"
    },
    {
        "vibrantCountryId": 201,
        "countryName": "Solomon Islands",
        "countryNameTwoBytes": "SB"
    },
    {
        "vibrantCountryId": 202,
        "countryName": "Somalia",
        "countryNameTwoBytes": "SO"
    },
    {
        "vibrantCountryId": 203,
        "countryName": "South Africa",
        "countryNameTwoBytes": "ZA"
    },
    {
        "vibrantCountryId": 204,
        "countryName": "South Georgia and the South Sandwich Islands",
        "countryNameTwoBytes": "GS"
    },
    {
        "vibrantCountryId": 205,
        "countryName": "South Korea",
        "countryNameTwoBytes": "KR"
    },
    {
        "vibrantCountryId": 206,
        "countryName": "South Sudan",
        "countryNameTwoBytes": "SS"
    },
    {
        "vibrantCountryId": 207,
        "countryName": "Spain",
        "countryNameTwoBytes": "ES"
    },
    {
        "vibrantCountryId": 208,
        "countryName": "Sri Lanka",
        "countryNameTwoBytes": "LK"
    },
    {
        "vibrantCountryId": 209,
        "countryName": "St. Kitts and Nevis",
        "countryNameTwoBytes": "KN"
    },
    {
        "vibrantCountryId": 210,
        "countryName": "St. Maarten",
        "countryNameTwoBytes": "SX"
    },
    {
        "vibrantCountryId": 211,
        "countryName": "St. Martin",
        "countryNameTwoBytes": "MF"
    },
    {
        "vibrantCountryId": 212,
        "countryName": "St. Vincent & the Grenadines",
        "countryNameTwoBytes": "VC"
    },
    {
        "vibrantCountryId": 213,
        "countryName": "Sudan",
        "countryNameTwoBytes": "SD"
    },
    {
        "vibrantCountryId": 214,
        "countryName": "Suriname",
        "countryNameTwoBytes": "SR"
    },
    {
        "vibrantCountryId": 215,
        "countryName": "Svalbard and Jan Mayen",
        "countryNameTwoBytes": "SJ"
    },
    {
        "vibrantCountryId": 216,
        "countryName": "Swaziland",
        "countryNameTwoBytes": "SZ"
    },
    {
        "vibrantCountryId": 217,
        "countryName": "Sweden",
        "countryNameTwoBytes": "SE"
    },
    {
        "vibrantCountryId": 218,
        "countryName": "Switzerland",
        "countryNameTwoBytes": "CH"
    },
    {
        "vibrantCountryId": 219,
        "countryName": "Syrian Arab Republic",
        "countryNameTwoBytes": "SY"
    },
    {
        "vibrantCountryId": 220,
        "countryName": "Taiwan, Province of China",
        "countryNameTwoBytes": "TW"
    },
    {
        "vibrantCountryId": 221,
        "countryName": "Tajikistan",
        "countryNameTwoBytes": "TJ"
    },
    {
        "vibrantCountryId": 222,
        "countryName": "Tanzania, United Republic of",
        "countryNameTwoBytes": "TZ"
    },
    {
        "vibrantCountryId": 223,
        "countryName": "Thailand",
        "countryNameTwoBytes": "TH"
    },
    {
        "vibrantCountryId": 224,
        "countryName": "Togo",
        "countryNameTwoBytes": "TG"
    },
    {
        "vibrantCountryId": 225,
        "countryName": "Tokelau",
        "countryNameTwoBytes": "TK"
    },
    {
        "vibrantCountryId": 226,
        "countryName": "Tonga",
        "countryNameTwoBytes": "TO"
    },
    {
        "vibrantCountryId": 227,
        "countryName": "Trinidad & Tobago",
        "countryNameTwoBytes": "TT"
    },
    {
        "vibrantCountryId": 228,
        "countryName": "Tunisia",
        "countryNameTwoBytes": "TN"
    },
    {
        "vibrantCountryId": 229,
        "countryName": "Turkey",
        "countryNameTwoBytes": "TR"
    },
    {
        "vibrantCountryId": 230,
        "countryName": "Turkmenistan",
        "countryNameTwoBytes": "TM"
    },
    {
        "vibrantCountryId": 231,
        "countryName": "Turks & Caicos Islands",
        "countryNameTwoBytes": "TC"
    },
    {
        "vibrantCountryId": 232,
        "countryName": "Tuvalu",
        "countryNameTwoBytes": "TV"
    },
    {
        "vibrantCountryId": 233,
        "countryName": "U.S. Virgin Islands",
        "countryNameTwoBytes": "VI"
    },
    {
        "vibrantCountryId": 234,
        "countryName": "Uganda",
        "countryNameTwoBytes": "UG"
    },
    {
        "vibrantCountryId": 235,
        "countryName": "Ukraine",
        "countryNameTwoBytes": "UA"
    },
    {
        "vibrantCountryId": 236,
        "countryName": "United Arab Emirates",
        "countryNameTwoBytes": "AE"
    },
    {
        "vibrantCountryId": 237,
        "countryName": "United Kingdom (Great Britain)",
        "countryNameTwoBytes": "GB"
    },
    {
        "vibrantCountryId": 238,
        "countryName": "United States",
        "countryNameTwoBytes": "US"
    },
    {
        "vibrantCountryId": 239,
        "countryName": "United States Minor Outlying Islands",
        "countryNameTwoBytes": "UM"
    },
    {
        "vibrantCountryId": 240,
        "countryName": "Uruguay",
        "countryNameTwoBytes": "UY"
    },
    {
        "vibrantCountryId": 241,
        "countryName": "Uzbekistan",
        "countryNameTwoBytes": "UZ"
    },
    {
        "vibrantCountryId": 242,
        "countryName": "Vanuatu",
        "countryNameTwoBytes": "VU"
    },
    {
        "vibrantCountryId": 243,
        "countryName": "Venezuela",
        "countryNameTwoBytes": "VE"
    },
    {
        "vibrantCountryId": 244,
        "countryName": "Vietnam",
        "countryNameTwoBytes": "VN"
    },
    {
        "vibrantCountryId": 245,
        "countryName": "Wallis & Futuna",
        "countryNameTwoBytes": "WF"
    },
    {
        "vibrantCountryId": 246,
        "countryName": "Western Sahara",
        "countryNameTwoBytes": "EH"
    },
    {
        "vibrantCountryId": 247,
        "countryName": "Yemen",
        "countryNameTwoBytes": "YE"
    },
    {
        "vibrantCountryId": 248,
        "countryName": "Zambia",
        "countryNameTwoBytes": "ZM"
    },
    {
        "vibrantCountryId": 249,
        "countryName": "Zimbabwe",
        "countryNameTwoBytes": "ZW"
    }
];