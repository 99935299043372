<template>
    <div :class = "['area-code-phone-number-input',{'alert-border':has_alert}]">
        <div class = 'area-code-selector'>
            <el-select v-model="selectedCountry" 
            @visible-change ="(visible)=>{dropdownVisible = visible}" 
            teleported>
                <template #prefix>
                    <div class = 'country-selector-wrapper'>
                        <img :src = "countryFlagSrc" class = "country-flag-icon"/>
                        <img src = "@/assets/BloodDraw/area_code_dropdown_arrow.svg" 
                        :class = "['rotate-0',{'rotate-180':dropdownVisible}]">
                    </div>
                </template>
                <el-option
                v-for="item in country_options_for_phone"
                :key="item.countryNameTwoBytes"
                label=" "
                :value="item.countryNameTwoBytes">
                    <span>{{ item.countryName}} ({{ item.countryNameTwoBytes }})</span>
                    <span style = "margin-left:10px;">+ {{ country_code_to_area_code_map[item.countryNameTwoBytes] }}</span>
                </el-option>
<!--  v-model="selectedCountry" -->
            </el-select>
        </div>
        <img src = "@/assets/BloodDraw/vertical_divider_for_area_code.svg" />
        <div class = "area-code-div">
            + {{ areaCode }}
        </div>
        <!-- <div class = 'phone-number-input'> -->
        <el-input v-model="phoneNumber" placeholder="Phone Number" />
        <!-- </div> -->
    </div>
    <div v-if = 'show_sent_notice' class = "sent_notice-div">Message has sent</div>
    <div v-if = 'has_alert' class = "alert-text-div">Message sent failed</div>
</template>

<script>
import { mapState } from 'pinia';
import { country_code_to_area_code_map } from './BloodDrawData/AreaCodeMap';
import { useBloodDrawStore } from '@/stores/BloodDrawStore.ts';
export default{
    props:{
        modelValue:String,
    },
    data(){
        return{
            dropdownVisible:false,
            phoneNumber:'',
            selectedCountry:'US',
            has_alert:false,
            show_sent_notice:false,

            country_code_to_area_code_map,
        }
    },
    computed:{
        ...mapState(useBloodDrawStore,[
                'country_options_for_phone',
            ]
        ),
        countryFlagSrc(){
            return `https://hatscripts.github.io/circle-flags/flags/${this.selectedCountry.toLowerCase()}.svg`;
        },
        areaCode(){
            return country_code_to_area_code_map[this.selectedCountry];
        }
    },
    emits:['update:modelValue'],
    watch:{
        selectedCountry(){
            this.$emit('update:modelValue', this.areaCode + this.phoneNumber);
            if(this.has_alert){
                this.has_alert = false;
            }
        },
        phoneNumber(){
            this.$emit('update:modelValue', this.areaCode + this.phoneNumber);
            if(this.has_alert){
                this.has_alert = false;
            }
        }
    }

}
</script>

<style scoped>
.area-code-phone-number-input{
    display: flex;
    align-items: center;
    width: 100%;
    border:1px solid #BCCCDC;
    border-radius: 4px;
}
.alert-border{
    border: 1px solid #DF2B25;
}
.alert-text-div{
    margin-top:4px;
    color: #DF2B25;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3px;
}
.sent_notice-div{
    margin-top:4px;
    color: #20A8C3;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 16px;
    letter-spacing: 0.3px;
    animation: fadeInOut 3.5s;
}
@keyframes fadeInOut{
    0%{
        opacity:0;
    }
    40%{
        opacity:1;
    }
    60%{
        opacity:1;
    }
    100%{
        opacity:0;
    }
}
.country-selector-wrapper{
    display: flex;
    align-items: center;
    gap: 6px;

}
.area-code-selector{
    width: 81px;
}
.country-flag-icon{
    width:24px;
    height:24px;
}
.rotate-0{
    transform: rotate(0deg);
    transition: all 0.5s;
}
.rotate-180{
    transform:rotate(-180deg);
}
.area-code-div{
    margin-left:21px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.3px;
}
.phone-number-input{
    width:100%;
    margin-left:12px;
}
@media only screen and (max-width:767px) {
    .area-code-selector{
        width:68px;
    }
}
</style>