<template>
    <div class = "shipping-address-inputs-view" v-if = 'is_editable'>
        <div class = "shipping-address-view-title">
            {{ address_page_title_content }}
        </div>
        <div class = 'all-units-wrapper'>
            <div v-for = 'input_unit_info in shipping_address_inputs_structure_data'
            :key = "`${input_unit_info.input_id}-shipping-address-input-unit`"
            :class = "input_unit_info.unit_wrapper_classname">
                <ShippingAddressInputUnit 
                v-model = 'current_shipping_address_data[input_unit_info.input_id]' 
                v-bind = 'input_unit_info' :disabled = "conditionalDisableInput(input_unit_info)"
                @add-required-input-field = "(input_id)=>addRequiredInputFields(input_id)"
                @remove-required-input-field="(input_id)=>removeRequiredInputFields(input_id)"
                @auto-fill-related-input = "(input_id, value)=>updateCurrentShippingAddress(input_id,value)"/>
                <!--@auto-fill-related-input is specifically used for autofill input -->
            </div>
        </div>
        <div class = "checkbox-container" v-if = '!is_current_shipping_address_same_with_patient_profile_address'>
            <el-checkbox v-model="shippingAddressStore.is_apply_to_future_order" 
            :checked = "incomplete_address_in_db"
            :disabled = "incomplete_address_in_db"
            class="apply-to-all-future-order-checkbox">
                <div class = "bold">
                    <p>Apply to All Future Orders</p>
                </div>
                <div class = "regular">
                    <p>By checking "Apply to All Future Orders", the patient address entered here will update the patient's information on file.</p>
                </div>
            </el-checkbox>
        </div>
        <div class = "checkbox-container same-address-notification-container" v-else>
            <img src="@/assets/ChangeShippingAddress/green_check_icon.svg"/>
            <div class = "bold">
                <p>Your address is already same as your profile address</p>
            </div>
        </div>
    </div>
</template>

<script>
import ShippingAddressInputUnit from '@/components/shippingAddress/ShippingAddressInputUnit.vue';
// import { env_version } from '@/components/shippingAddress/shippingAddressData/shippingAddressMockData.js';
import { mapState, mapActions} from 'pinia';
import { useShippingAddressStore } from "@/stores/ShippingAddressStore.ts";
// import { beta_launch_customer_ids } from "@/utils/betaLaunchData.js";
export default{
    setup(){
        const shippingAddressStore = useShippingAddressStore();
        return {
            shippingAddressStore,
        }
    },
    components:{
        ShippingAddressInputUnit,
    },
    data(){
        return{
            // beta_launch_customer_ids,
        }
    },
    computed:{
        ...mapState(useShippingAddressStore, [
            'is_editable',
            'shipping_address_inputs_structure_data',
            'previous_shipping_address_data',
            'current_shipping_address_data',
            'incomplete_address_in_db',
            'patient_profile_address'
        ]),
        address_page_title_content(){
            return this.incomplete_address_in_db ? 'Please Input Your Address' : 'Edit Your Shipping Address If Needed';
        },
        is_current_shipping_address_same_with_patient_profile_address(){
            const concat_cur_address = `${this.current_shipping_address_data.street}, ${this.current_shipping_address_data.city}, ${this.current_shipping_address_data.state} ${this.current_shipping_address_data.zipcode}, ${this.current_shipping_address_data.country}`;
            var profile_full_street = this.patient_profile_address.street_address ? this.patient_profile_address.street_address:'';
            if(this.patient_profile_address.apt_opt){
                if(profile_full_street){
                    profile_full_street += ` ${this.patient_profile_address.apt_opt}`;
                }else{
                    profile_full_street = `${this.patient_profile_address.apt_opt}`;
                }
            }
            const concat_profile_address = `${profile_full_street}, ${this.patient_profile_address.city}, ${this.patient_profile_address.state} ${this.patient_profile_address.zipcode}, ${this.patient_profile_address.country}`;
            const output = concat_cur_address == concat_profile_address;
            this.updateIsCurrentShippingAddressSameWithPatientProfileAddress(output);
            return output;
        }
    }, 
    watch:{
        current_shipping_address_data:{
            handler(){
                // console.log('current_shipping_address_data', newVal);
            },
            deep:true,
        },
    },
    methods:{
        ...mapActions(useShippingAddressStore,[
            'updateCurrentShippingAddress',
            'addRequiredInputFields',
            'removeRequiredInputFields',
            'updateIsCurrentShippingAddressSameWithPatientProfileAddress',
        ]),
        conditionalDisableInput(input_unit_info){
            //if the structure indicates the disabled field is true, it means that this field is always disabled
            if(input_unit_info.disabled){
                return true;
            }else{
                //if country field has value originally, disable it.
                if(input_unit_info.input_id == "country" && this.previous_shipping_address_data['country']){
                    return true;
                }
            }
            return false;
        },
    }
    
}
</script>

<style scoped>
.shipping-address-inputs-view{
    padding-left:314px;
    padding-right:318px;
    margin-top:36px;
    min-height: 100%;
}
.shipping-address-view-title{
    padding-bottom:24px;
    width:100%;

    color: #0E4672;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
}
.all-units-wrapper{
    width:100%;

    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    row-gap:16px;
    column-gap:18px;
}
.checkbox-container{
    margin-top:18px;
}
.same-address-notification-container{
    display: flex;
    align-items: center;
    column-gap: 10px;
}
/* checkbox */
.apply-to-all-future-order-checkbox{
    align-items: baseline;
    height:fit-content;
    --el-checkbox-input-height:18px;
    --el-checkbox-input-width:18px;
    --el-checkbox-input-border:2px solid #000;
    --el-checkbox-checked-icon-color: #FFF;
    --el-checkbox-checked-bg-color:#84BC3B;
    --el-checkbox-checked-input-border-color:#84BC3B;
}
:deep(.apply-to-all-future-order-checkbox .el-checkbox__input.is-checked .el-checkbox__inner::after){
    transform: rotate(45deg) scaleY(1.5) scaleX(1.5) translate(1px);
}
:deep(.apply-to-all-future-order-checkbox .el-checkbox__input){
    transform:translateY(3px);
}
:deep(.apply-to-all-future-order-checkbox .el-checkbox__label){
    padding-left:12px;
    display: flex;
    flex-direction: column;
    gap:4px;
}
.same-address-notification-container .bold,
.apply-to-all-future-order-checkbox .bold{
    width:100%;
    color: #000;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    white-space:pre-wrap;
    word-break: break-word;
}
.apply-to-all-future-order-checkbox .regular{
    width:100%;
    color: #000;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    white-space:pre-wrap;
    word-break: break-word;
}
/* unit wrapper width */
.Name-unit-wrapper,
.Address-Line-unit-wrapper{
    width:100%;
}

.Zip-Code-unit-wrapper,
.City-unit-wrapper,
.State-unit-wrapper,
.Country-unit-wrapper{
    width:48%;
}

@media only screen and (max-width: 1260px){
    .shipping-address-inputs-view{
        padding-left:157px;
        padding-right:159px;
        margin-top:36px;
    }
}

@media only screen and (max-width: 767px){
    .shipping-address-inputs-view{
        padding-left:28px;
        padding-right:27px;
        margin-top:24px;
    }
    .shipping-address-view-title{
        padding-bottom: 12px;
        width: 100%;
        color: #0E4672;
        font-family:'Roboto';
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
    }
    .checkbox-container{
        margin-top:24px;
    }
    :deep(.apply-to-all-future-order-checkbox .el-checkbox__label){
        gap:2px;
    }
    .same-address-notification-container .bold,
    .apply-to-all-future-order-checkbox .bold{
        width:100%;
        color: #000;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        white-space:pre-wrap;
        word-break: break-word;
    }
    .apply-to-all-future-order-checkbox .regular{
        width:100%;
        color: #000;
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        white-space:pre-wrap;
        word-break: break-word;
    }
    
    .all-units-wrapper{
        width:100%;
        margin-top:12px;

        display:flex;
        flex-wrap:wrap;
        justify-content:space-between;
        row-gap:8px;
        column-gap:18px;
    }
    .Name-unit-wrapper,
    .Address-Line-unit-wrapper,
    .Zip-Code-unit-wrapper,
    .City-unit-wrapper,
    .State-unit-wrapper,
    .Country-unit-wrapper{
        width:100%;
    }
}


</style>