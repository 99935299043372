<template>
    <el-dialog
    v-model="dialog_visible"
    :width = "dynamic_dialog_width"
    :fullscreen="show_fullscreen"
    class = "fedex-pickup-dialog"
    destroy-on-close
    @close = "handleCloseEvent"
    >
        <template #header="{close}">
            <div class="my-header">
                <span class = "title">{{ title }}</span>
                <img v-if = "!show_fullscreen" src = "@/assets/FedexPickup/dialog_cross_icon.svg" @click="close" class = "close-icon"/>
            </div>
        </template>
        <SchedulePickupContent
        :show_fullscreen="show_fullscreen"
        :schedule_info="current_schedule_data"
        :is_editing_existing_schedule = "is_editing_existing_schedule"
        @cancel-edits="cancelSchedulePickupEdits"
        @confirm-edits="confirmSchedulePickupEdits" />
    </el-dialog>
</template>

<script>
import SchedulePickupContent from '@/components/fedexPickup/SchedulePickupContent.vue';
import { useWindowSize } from '@vueuse/core';
export default{
    props:{
        title:{
            type:String,
            default:'',
        },
        is_editing_existing_schedule:{
            type:Boolean,
            default:false,
        },
        default_schedule_info:{
            type:Object,
            default:()=>{},
        }
    },
    components:{
        SchedulePickupContent,
    },
    setup(){
        const { width } = useWindowSize();
        return {
            width,
        }
    },
    data(){
        return{
            // for all views
            // always shows inputs-view when open the EditAddressInputsSection.vue
            view_history_stack:['schedule-pickup-view'],
            dialog_visible:true,
            current_schedule_data: this.default_schedule_info,
            //default to be true, as the existing_schedule has valid address and contact for sure.
            is_current_address_valid:true,
            is_current_contact_valid:true,
        }
    },
    computed:{
        dynamic_dialog_width(){
            return this.width > 820 ? '808': (this.width >767 ? '90%' : "100%");
        },
        show_fullscreen(){
            return this.width <= 767 ? true : false;
        },
        current_view(){
            return this.view_history_stack[this.view_history_stack.length - 1];
        },
    },
    emits:['close', 'confirm-edits'],
    methods:{
        handleCloseEvent(){
            this.$emit('close');
        },
        cancelSchedulePickupEdits(){
        //triggered when click 'Cancel' btn in home-view
            this.dialog_visible = false;
        },
        confirmSchedulePickupEdits(edited_schedule_data){
        //triggered when everything required for schedule a pickup is filled and valid, 
        //and the user has already successfully saved this new schedule or saved the edits on this existing schedule.
            // the param is new schedule's data
            this.$emit('confirm-edits', edited_schedule_data);
        }
    }
}
</script>

<style scoped>
.close-icon{
    cursor:pointer;
}
</style>