<template>
    <div class = "schedule-card" v-loading = "loading">
        <div class = "title-bar">
            <div class = "schedule-id">
                Pick up #{{ schedule_confirmation_number }}
            </div>
            <div :class = "['schedule-status', status.replaceAll(' ','-')]">
                {{ status }}
            </div>
        </div>
        <div class = "schedule-info-details">
            <SamplifiedNotification v-if = "disabled_operation_btn && status != 'Cancelled' && status != 'Expired'" v-bind = "disabled_operation_notification_props"/>
            <div class = "detail-row">
                <img src = "@/assets/FedexPickup/date_icon.svg" class = "detail-icon">
                <div class ="detail-text day-and-date">
                    {{ pickup_date}}
                </div>
            </div>
            <div class = "detail-row">
                <img src = "@/assets/FedexPickup/clock_icon.svg" class = "detail-icon">
                <div class ="detail-text time-range">
                    {{ start_time}} - {{ end_time }}
                </div>
            </div>
            <div class = "detail-row">
                <img src = "@/assets/FedexPickup/address_icon.svg" class = "detail-icon">
                <div class ="detail-text address">
                    {{ concate_address }}
                </div>
            </div>
            <div class = "detail-row">
                <img src = "@/assets/FedexPickup/person_icon.svg" class = "detail-icon">
                <div class ="detail-text phone-and-email">
                    {{ phone }} / {{ email }}
                </div>
            </div>
        </div>
        <div class = "ops-btns-container">
            <div :class = "[
                'button', 
                {'disabled': disabled_operation_btn},
                'cancel-btn',
            ]"
            @click = "handleClickCancelSchedule">
                Cancel
            </div>
            <div :class = "[
                'button', 
                {'disabled': disabled_operation_btn},
                'edit-btn',
            ]"
            @click="handleClickEditSchedule">
                Edit
            </div>
        </div>
        <CancelScheduleDialog v-if = "is_cancel_schedule_dialog_visible" 
        :schedule_info="schedule_info"
        @close = "is_cancel_schedule_dialog_visible = false"
        @confirm = "(target_schedule_info)=>handleConfirmCancelSchedule(target_schedule_info)"/>
        <SchedulePickupDialog v-if = "is_edit_schedule_pickup_dialog_visible"
        :title = "`Edit pickup #${schedule_confirmation_number}`"
        :is_editing_existing_schedule = "true"
        :default_schedule_info="schedule_info"
        @close = "handleEditSchedulePickupClose()"
        @confirm-edits="(edited_schedule_data)=>handleConfirmEditSchedule(edited_schedule_data)"/>
    </div>
</template>

<script>
import CancelScheduleDialog from '@/components/fedexPickup/CancelScheduleDialog.vue';
import SchedulePickupDialog from "@/components/fedexPickup/SchedulePickupDialog.vue";
import SamplifiedNotification from "@/components/commons/SimplifiedNotification.vue";
import { useFedexPickupStore } from "@/stores/FedexPickupStore.ts";
import { mapActions } from "pinia";
import moment from "moment";
export default{
    components:{
        CancelScheduleDialog,
        SchedulePickupDialog,
        SamplifiedNotification,
    },
    props:{
        schedule_info:{
            type:Object,
            default:()=>{}
        },
        index:{
            type:Number,
            default:-1,
        }
    },
    data(){
        return {
            is_cancel_schedule_dialog_visible:false,
            is_edit_schedule_pickup_dialog_visible:false,
            loading: false,
            day_list:['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            disabled_operation_notification_props:{
                content: "If you want to cancel this pickup please contact FedEx at +1 800-800-3339 or call your local FedEx customer service.",
                build_in_theme: "yellow",
                with_close_btn: false,
            }
        }
    },
    computed:{
        schedule_confirmation_number(){
        //location_code + confirmation_code
            return this.schedule_info.pickup_info.confirmation_code && this.schedule_info.pickup_info.confirmation_code!= "TBD"
            ? 
            this.schedule_info.pickup_info.location_code + this.schedule_info.pickup_info.confirmation_code
            :
            'TBD'
            ;
        },
        status(){
            return this.schedule_info.status;
        },
        pickup_date(){
            const day = moment(this.schedule_info.pickup_info['pickup_date']).day();
            return `${this.day_list[day]}, ${this.schedule_info.pickup_info['pickup_date']}`;
        },
        start_time(){
            return this.schedule_info.pickup_info['ready_time'];
        },
        end_time(){
            return this.schedule_info.pickup_info['customer_close_time'];
        },
        concate_address(){
            return `${this.schedule_info.pickup_address.street}, ${this.schedule_info.pickup_address.city}, ${this.schedule_info.pickup_address.state_or_province_code}, ${this.schedule_info.pickup_address.postal_code}, ${this.schedule_info.pickup_address.country_code}`;
        },
        phone(){
            return `+${this.schedule_info.contact.phone.area_code} ${this.schedule_info.contact.phone.phone_number}`;
        },
        email(){
            return this.schedule_info.contact.email;
        },
        disabled_operation_btn(){
            //if status is 'Expired' or 'Canceled'
            if(this.status == 'Expired' || this.status == 'Cancelled'){
                return true;
            }
            // if the pickup_request_submitted_at is not empty and the current date is after the pickup_request_submitted_at's date
            //eg. "pickup_request_submitted_at": "2024-11-07T19:52:10.000Z"; 
            //     if current_date:"2024-11-07T23:59:10.000Z" -> not disabled
            //     if current_date:"2024-11-08T00:00:10.000Z" -> disabled
            if(this.schedule_info.pickup_request_submitted_at && !moment(this.schedule_info.pickup_request_submitted_at).isSame(moment(),'day')){
                return true;
            }
            return false;
        }
    },
    methods:{
        ...mapActions(useFedexPickupStore,[
            'cancelExistingSchedule',
            'addNewScheduleInfoInSchedules',
            'addNewGlobalNotification',
            'setPatientInfoOnFile',
            'initNewScheduleInfo',
            'updateExistingScheduleInfoInSchedules'
        ]),
        // for cancel feature
        handleClickCancelSchedule(){
            if(!this.disabled_operation_btn){
                //open CancelScheduleDialog
                this.is_cancel_schedule_dialog_visible = true;
            }
        },
        async handleConfirmCancelSchedule(current_schedule_updated_info){
        //triggered when the user has successfully canceled this schedule
            console.log('handleConfirmCancelSchedule', current_schedule_updated_info);
            //succeed: 
            //1. front render: update the target schedule with the current_schedule_updated_info
            //NOTES:target_schedule_info and this.schedule_info shares the same reference, so the change of target_schedule_info is the change of this.schedule_info.
            //and this.schedule_info is the corresponding element in this.schedules (in FedexPickupStore), so the change is also applied this.schedules (in FedexPickupStore)
            this.updateExistingScheduleInfoInSchedules(current_schedule_updated_info, this.index);
            //2. close CancelScheduleDialog.vue
            this.is_cancel_schedule_dialog_visible = false;
        },
        // for edit feature
        handleClickEditSchedule(){
            if(!this.disabled_operation_btn){
                //open CancelScheduleDialog
                this.is_edit_schedule_pickup_dialog_visible = true;
            }
        },
        handleEditSchedulePickupClose(){
            this.is_edit_schedule_pickup_dialog_visible = false;
        },
        async handleConfirmEditSchedule(edited_schedule_data){
        //triggered when the user has successfully created the new schedule, edited_schedule_data is the data of the new schedule
            //1. close SchedulePickupDialog.vue for editing
            this.is_edit_schedule_pickup_dialog_visible = false;
            //2. we need to cancel the previous schedule after creating the new one:
            //NOTES: need to cancel and updateExistingScheduleInfoInSchedule() before addNewScheduleInfoInSchedules()
            //since addNewScheduleInfoInSchedules() will change the index as we insert the new one at the beginning
            try{
                this.loading = true;
                const canceled_schedule_updated_info = await this.cancelExistingSchedule(this.schedule_info);
                this.updateExistingScheduleInfoInSchedules(canceled_schedule_updated_info, this.index);
                this.addNewGlobalNotification({
                    title: "Success",
                    content: `We have successfully rescheduled the FedEx pickup at ${edited_schedule_data.pickup_info.pickup_date} ${edited_schedule_data.pickup_info.ready_time} as requested, and have canceled the original pickup at ${this.schedule_info.pickup_info.pickup_date} ${this.schedule_info.pickup_info.ready_time}.`,
                    build_in_theme: "green",
                    with_close_btn: true,
                });
                this.loading = false;
            }catch(err){
                console.log("handleConfirmEditSchedule error", err);
                this.addNewGlobalNotification({
                    title: "Partialy Success",
                    content: `We have successfully rescheduled the FedEx pickup at ${edited_schedule_data.pickup_info.pickup_date} ${edited_schedule_data.pickup_info.ready_time} as requested. However, due to the system issue, we were unable to cancel the original pickup at ${this.schedule_info.pickup_info.pickup_date} ${this.schedule_info.pickup_info.ready_time} at this time. Please contact FedEx at +1 800-800-3339 or call your local FedEx customer service to cancel it.`,
                    build_in_theme: "yellow",
                    with_close_btn: true,
                });
                this.loading = false;
            }
            //3. add the newly created schedule in schedules in the store after updateExistingScheduleInfoInSchedules()
            this.addNewScheduleInfoInSchedules(edited_schedule_data);
            //4. updated the prefilled info based on most recent created schedule's pickup_address and contact info (which is the one just created)
            const prefilled_info = {
                person_name: edited_schedule_data.person_name,
                address: {
                    street: edited_schedule_data.pickup_address.street,
                    city: edited_schedule_data.pickup_address.city,
                    postal_code: edited_schedule_data.pickup_address.postal_code,
                    state_or_province_code: edited_schedule_data.pickup_address.state_or_province_code,
                    country_code: edited_schedule_data.pickup_address.country_code,
                },
                contact:{
                    email: edited_schedule_data.contact_email,
                    phone:{
                        area_code: edited_schedule_data.contact_phone.area_code,
                        phone_number: edited_schedule_data.contact_phone.phone_number,
                    }
                }
            }
            this.setPatientInfoOnFile(prefilled_info);
            //5. reset new_schedule_info to the default value based on patient_info_on_file
            this.initNewScheduleInfo();
            //6.auto scroll schedules-container in the parent component to the top to show the new schedule
            this.$nextTick(()=>{
                if(this.$parent.$refs['schedules-container']){
                    this.$parent.$refs['schedules-container'].scrollTop = 0;
                }
            })
        }
    }
}
</script>

<style scoped>
.schedule-card{
    /* min-width: 386px; */
    width: 100%;
    box-sizing: content-box;
    height: fit-content;
    border-radius: 8px;
    background: #FFF;
    border: 1px solid #BCCCDC;
}
.title-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 12px 24px;
    border-radius: 8px 8px 0px 0px;
    background: #EFF7FF;
}
.schedule-id{
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.17px;
}
.schedule-status{
    width: fit-content;
    padding: 5px 15px;
    
    border-radius: 100px;
    border: 1px solid #979797;
    background: #FFF;

    color: #979797;
    font-family:'Roboto';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
}
.schedule-status.Scheduled{
    border: 1px solid #20A8C3;
    color: #20A8C3;
}
.schedule-status.Picked-Up{
    border: 1px solid #84BC3B;
    color: #84BC3B;
}
.schedule-status.Cancelled{
    border: 1px solid #979797;
    color: #979797;
}
.schedule-status.Expired{
    border: 1px solid #DE583F;
    color: #DE583F;
}
.schedule-info-details{
    display: flex;
    flex-direction: column;
    row-gap:4px;
    padding:12px 24px 0px 24px;
}
.detail-row{
    display: flex;
    align-items: center;
}
.detail-text{
    padding:8px 10px;
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.ops-btns-container{
    padding:20px 36px;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
}
.button{
    width:calc(50% - 10px);
    padding:9px 23px;
    border-radius: 4px;
    background: #FFF;

    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
}
.button.cancel-btn{
    border: 1px solid #1E4670;
    color: #1E4670;
}
.button.edit-btn{
    border: 1px solid #DE583F;
    color: #DE583F;
}
.button.disabled{
    border: 1px solid #979797 !important;
    color: #979797 !important;
    background: #F2F2F2 !important;
    cursor: not-allowed !important;
}
@media only screen and (max-width:767px){
    /* .schedule-card{
        min-width:317px;
    } */
    .title-bar{
        padding:12px;
    }
    .schedule-id{
        font-size: 16px;
        line-height: 19px;
    }
    .schedule-status{
        padding: 3px 11px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.5px;
    }
    .schedule-info-details{
        padding:12px 11px 0px 11px;
    }
    .detail-row{
        display: flex;
        align-items: center;
    }
    .detail-icon{
        width:20px;
        height: 20px;
    }
    .ops-btns-container{
        padding:12px;
    }
    .button{
        width: calc(50% - 10px);
        line-height: 16px;
    }
}
</style>