import axios from 'axios';
const wrapURL = (URL) => {
    const baseURL = axios.create({
        baseURL:URL
    });

    baseURL.interceptors.response.use(
        (response) => {
            if (response.status !== 200 && response.status !== 201) {
                return Promise.reject("error");
            } else {
                return response;
            }
        },
        (error) => {
            // const { status } = error.response;
            // if (status === 404) {
            // } else if (status >= 500 && status < 600) {
            // } else {
            // }
            return Promise.reject(error);
            
        }
    );
    return baseURL;
}

export default wrapURL;