<template>
    <div class = "edit-address-content">
        <div class = "go-back-btn" @click="clickGoBack()">
            <img src ="@/assets/FedexPickup/back_arrow.svg" class = "go-back-btn-icon"/>
            <div class = "go-back-btn-text">
                Back
            </div>
        </div>
        <div  v-if = "current_edit_address_section_view == 'inputs-view'" class = "section edit-pickup-address">
            <div class = "section-title-bar">
                <div class = "section-title">
                    <div class = "section-title-name">
                        Edit Pickup Address
                    </div>
                </div>
            </div>
            <div class = "section-content" v-loading = "loading">
                <SimplifiedNotification v-if = "has_error" v-bind="error_notification_props" 
                @close = "has_error = false"/>
                <SimplifiedNotification v-if = "disabled_save_btn" v-bind = "invalid_address_notification_props" />
                <div v-for = 'input_unit_info in adjusted_edit_pickup_address_inputs_structure_data'
                :key = "`${input_unit_info.input_id}-edit-pickup-address-input-unit`"
                :class = "input_unit_info.unit_wrapper_classname">
                    <ShippingAddressInputUnit 
                    v-model = "edit_address_section_inputs_data[input_unit_info.input_id]"
                    v-bind = 'input_unit_info'
                    @add-required-input-field = "(input_id)=>handleAddRequiredField(input_id)"
                    @remove-required-input-field="(input_id)=>handleRemoveRequiredField(input_id)"
                    @invalid-value = "(input_id, value) => handleAddInvalidInputValue(input_id)"
                    @remove-invalid-value = "(input_id, value) => handleRemoveInvalidInputValue(input_id)"
                    @auto-fill-related-input = "(input_id, value)=>updateCurrentPickupAddress(input_id,value)" />
                    <el-checkbox v-if = "show_no_state_applicable_checkbox(input_unit_info.input_id)"
                    v-model="check_no_state_applicable">
                        State / Province not applicable.
                    </el-checkbox>
                </div>
            </div>
        </div>
        <div  v-if = "current_edit_address_section_view == 'recommend-address'" class = "section recommand-and-confirm-address">
            <div class = "section-title-bar">
                <div class = "section-title">
                    <div class = "section-title-name">
                        Confirm your Pickup Address
                    </div>
                </div>
            </div>
            <div class = "section-content">
                <div class = "guidance-text">
                    Review the recommended changes
                </div>
                <div class = "options-container">
                    <el-radio-group v-model="checked_option">
                        <div class = 'unit-option-container'>
                            <el-radio :label="concate_entered_address">
                           
                                <div class = "option-type">
                                    Your entered
                                </div>
                                <div class = "option-details">
                                    {{ concate_entered_address}}
                                </div>
                            </el-radio>
                        </div>
                        
                        <div class = 'unit-option-container'>
                            <el-radio :label="concate_recommended_address">
                                <div class = "option-type">
                                    Recommended
                                </div>
                                <div class = "option-details">
                                    {{ concate_recommended_address}}
                                </div>
                            </el-radio>
                        </div>
                        
                    </el-radio-group>
                </div>
            </div>
        </div>
        <div  v-if = "current_edit_address_section_view == 'invalid-address'" class = "section invalid-address">
            <div class = "section-title-bar">
                <div class = "section-title">
                    <div class = "section-title-name">
                        We're having problems verifying your address
                    </div>
                </div>
            </div>
            <div class = "section-content">
                <div class = "guidance-text">
                    Our system was unable to confirm your entered address or find any alternatives. Please go back and edit your address to try again.
                </div>
                <div class = "options-container">
                    <el-radio-group v-model="checked_option">
                        <div class = 'unit-option-container'>
                            <el-radio :label="concate_entered_address" :disabled = "true">
                                <div class = "option-type">
                                    Your entered
                                </div>
                                <div class = "option-details">
                                    {{ concate_entered_address}}
                                </div>
                            </el-radio>
                        </div>
                    </el-radio-group>
                </div>
            </div>
        </div>
    </div>
    <div v-if = "current_edit_address_section_view == 'inputs-view'" class = "ops-btns">
        <div class = "cancel-btn"
        @click = "cancelAddressInputsEdits">
            Cancel
        </div>
        <div :class = "['confirm-btn',{'disabled':disabled_save_btn}]" 
        @click = "saveAddressInputsEdits">
            Save
        </div>
    </div>
    <div v-if = "current_edit_address_section_view == 'recommend-address'" class = "ops-btns">
        <div class = "cancel-btn"
        @click = "cancelConfirmAddress">
            Cancel
        </div>
        <div :class = "['confirm-btn']" 
        @click = "confirmAddress">
            Confirm
        </div>
    </div>
    <div v-if = "current_edit_address_section_view == 'invalid-address'" class = "ops-btns invalid-address">
        <div class = "cancel-btn"
        @click = "clickGoBack">
            Back
        </div>
    </div>
</template>

<script>
import ShippingAddressInputUnit from "@/components/shippingAddress/ShippingAddressInputUnit.vue";
import SimplifiedNotification from "@/components/commons/SimplifiedNotification.vue";
import { edit_pickup_address_inputs_structure_data } from "@/components/fedexPickup/FedexPickupData/fedexPickupInputsStructure.js";
import { useFedexPickupStore } from "@/stores/FedexPickupStore.ts";
import { mapState, mapActions } from "pinia";
import { STATES } from "@/components/shippingAddress/shippingAddressData/usStates.js";
import { getUSAStateDropwdownOptions } from '@/components/shippingAddress/shippingAddressData/shippingAddressInputsStructure.js'
export default{
    components:{
        ShippingAddressInputUnit,
        SimplifiedNotification,
    },
    props:{
        address_info:{
            type:Object,
            default:()=>{}
        }
    },
    setup(){
        const fedexPickupStore = useFedexPickupStore();
        return{
            fedexPickupStore,
        }
    },
    unmounted(){
        //clear edit_pickup_address_invalid_value_input_fields
        this.fedexPickupStore.edit_pickup_address_invalid_value_input_fields = [];
        //clear edit_pickup_address_required_input_fields
        this.fedexPickupStore.edit_pickup_address_required_input_fields = [];
    }, 
    data(){
        return{
            // for all views
            // always shows inputs-view when open the EditAddressInputsSection.vue
            view_history_stack:['inputs-view'],
            loading:false,

            // for inputs-view view
            has_error:false,
            error_message:'',
            edit_address_section_inputs_data: JSON.parse(JSON.stringify(this.address_info)),
            check_no_state_applicable:false,
            edit_pickup_address_inputs_structure_data,
            
            // for recommend-address view
            checked_option:'',
            recommended_address:{},

            // for invalid-address view
            show_invalid_address_error:false,
        }
    },
    computed:{
        ...mapState(useFedexPickupStore,[
            'edit_pickup_address_required_input_fields',
            'edit_pickup_address_invalid_value_input_fields'
        ]),
        current_edit_address_section_view(){
            //this.view_history_stack won't be empty
            return this.view_history_stack[this.view_history_stack.length-1];
        },
        concate_recommended_address(){
            return `${this.recommended_address.street}, ${this.recommended_address.city}, ${this.recommended_address.state_or_province_code}, ${this.recommended_address.postal_code}, ${this.recommended_address.country_code}`;
        },
        concate_entered_address(){
            return `${this.edit_address_section_inputs_data.street}, ${this.edit_address_section_inputs_data.city}, ${this.edit_address_section_inputs_data.state_or_province_code}, ${this.edit_address_section_inputs_data.postal_code}, ${this.edit_address_section_inputs_data.country_code}`;
        },
        error_notification_props(){
            return {
                build_in_theme:'red',
                title:"Error",
                content: this.error_message,
            }
        },
        invalid_address_notification_props(){
            return   {
                title: "Address Invalid",
                content: "Please confirm the accuracy of your Pickup Address to proceed to selecting a Pickup Time and setting a Reminder.",
                build_in_theme: "red",
                with_close_btn: false,
            };
        },
        adjusted_edit_pickup_address_inputs_structure_data(){
            var output;
            // if US , state: required, input_type:'selector',
            if(this.edit_address_section_inputs_data.country_code == "US"){
                output = this.edit_pickup_address_inputs_structure_data.map((input_data)=>{
                    if(input_data.input_id == "state_or_province_code"){
                        return {
                            input_id:'state_or_province_code',
                            input_display_name:'State/Province',
                            required:true,
                            input_type:'selector',
                            dropdown_options:getUSAStateDropwdownOptions(STATES),
                            unit_wrapper_classname:'State-unit-wrapper',
                        }
                    }else{
                        return input_data;
                    }
                });
            }else{
            // if country not US , state: not require, input_type:'input',
                output = this.edit_pickup_address_inputs_structure_data.map((input_data)=>{
                    if(input_data.input_id == "state_or_province_code"){
                        return {
                            input_id:'state_or_province_code',
                            input_display_name:'State/Province',
                            required: true,
                            input_type:'input',
                            placeholder:'Limited to a 2-letters abbreviation.',
                            text_length_limit:2,
                            unit_wrapper_classname:'State-unit-wrapper',
                            //only disabled the input, if the no state applicable checkbox is checked
                            disabled: this.check_no_state_applicable,
                        }
                    }else{
                        return input_data;
                    }
                })
            }
            return output;
        },
        is_country_non_US(){
            return this.edit_address_section_inputs_data['country_code']!='US';
        },
        show_no_state_applicable_checkbox(){
        //the callback function structured computed data cannot be watched
        //to see the visibility change of no state applicable checkbox, we watch is_country_non_US instead
            return (input_id)=>{   
                //show no state applicable checkbox when the country is non US
                return input_id=='state_or_province_code' && this.is_country_non_US;
            };
        },
        all_required_inputs_filled(){
            for(const field of this.edit_pickup_address_required_input_fields){
                if(!this.edit_address_section_inputs_data[field]){
                    return false;
                }
            }
            return true;
        },
        no_invalid_values(){
            return this.edit_pickup_address_invalid_value_input_fields.length == 0;
        },
        disabled_save_btn(){
            return !this.all_required_inputs_filled || !this.no_invalid_values;
        }
    },
    watch:{
        edit_address_section_inputs_data:{
            deep:true,
            handler(newVal){
                console.log('edit_address_section_inputs_data change', newVal);
            }
        },
        recommended_address(){
            this.checked_option = this.concate_recommended_address;
        },
        is_country_non_US(){
        //if is_country_non_US changes, the visibility of no state applicable checkbox also changes.
            //if the visibility of the no state applicable checkbox changed, reset it unchecked if it is not.
            if(this.check_no_state_applicable){
                this.check_no_state_applicable = false;
            }
        },
        check_no_state_applicable(newVal){
            //if check:
            if(newVal){
                //1. fill the state_or_province_code field in edit_address_section_inputs_data to be 'NA'
                this.edit_address_section_inputs_data['state_or_province_code'] = 'NA';
                //2. disable the state_or_province_code input (directly sync via computed data adjusted_edit_pickup_address_inputs_structure_data)
            }else{
            //if uncheck:
                //enable the state_or_province_code input (directly sync via computed data adjusted_edit_pickup_address_inputs_structure_data)
                //clear the state_or_province_code field in edit_address_section_inputs_data to be '';
                this.edit_address_section_inputs_data['state_or_province_code'] = '';
            }
        }
    },
    emits:['cancel-edits', 'save-edits'],
    methods:{
        ...mapActions(useFedexPickupStore,[
            'updateEditPickupAddressRequiredInputFields',
            'updateEditPickupAddressInvalidValueInputFields',
            'checkAddressValidation',
        ]),
        // for all views
        clickGoBack(){
            console.log('view_history_stack in EditAddressSection',this.view_history_stack);
            //if view_history_stack record more than one history
            if(this.view_history_stack.length >1){
                //go back to previous view
                this.view_history_stack.pop();
            }else{
            //if view_history_stack record only has one history record: already at the first view
                //close this section component
                this.$emit('cancel-edits');
            }
        },
        // for recommend-address
        cancelConfirmAddress(){
        //triggered when click cancel btn in recommend-address view
            //go back to previous view
            this.view_history_stack.pop();
        },
        confirmAddress(){
        //triggered when click confirm btn in recommend-address view
            //if choose entered value, pass the corresponding address object this.edit_address_section_inputs_data to the parent
            if(this.checked_option == this.concate_entered_address){
                this.$emit('save-edits', this.edit_address_section_inputs_data);
            }else{
            //if chosse recommended value, pass the corresponding address object this.recommended_address to the parent
                this.$emit('save-edits', this.recommended_address);
            }
        },
        // for inputs-view
        handleAddRequiredField(input_id){
            if(!this.edit_pickup_address_required_input_fields.includes(input_id)){
                this.updateEditPickupAddressRequiredInputFields(input_id, 'add');
            }
        },
        handleRemoveRequiredField(input_id){
            if(this.edit_pickup_address_required_input_fields.includes(input_id)){
                this.updateEditPickupAddressRequiredInputFields(input_id, 'remove')
            }
        },
        handleAddInvalidInputValue(input_id){
            if(!this.edit_pickup_address_invalid_value_input_fields.includes(input_id)){
                this.updateEditPickupAddressInvalidValueInputFields(input_id,'add');
            }
        },
        handleRemoveInvalidInputValue(input_id){
            if(this.edit_pickup_address_invalid_value_input_fields.includes(input_id)){
                this.updateEditPickupAddressInvalidValueInputFields(input_id, 'remove');
            }
        },
        updateCurrentPickupAddress(key, val){
            if(key == 'state'){
                this.edit_address_section_inputs_data['state_or_province_code'] = val;
            }else if(key == "zipcode"){
                this.edit_address_section_inputs_data['postal_code'] = val;
            }else if(key == "country"){
                this.edit_address_section_inputs_data['country_code'] = val;
            }else{
                this.edit_address_section_inputs_data[key] = val;
            }
        },
        cancelAddressInputsEdits(){
            this.$emit('cancel-edits');
        },
        async saveAddressInputsEdits(){
            if(!this.disabled_save_btn){
                this.has_error = false;
                try{
                    //1. call backend to check address validation
                    console.log("call backend to check address validation...");
                    this.loading = true;
                    const {is_current_address_valid, has_recommended_address, recommended_address} = await this.checkAddressValidation(this.edit_address_section_inputs_data);
                    //2.1 if valid address: close edit address section, back to dialog home view -> update current_schedule_data.address with new values
                    if(is_current_address_valid){
                        if(has_recommended_address){
                            console.log('recommended_address',recommended_address);
                            this.recommended_address = recommended_address;
                            this.view_history_stack.push('recommend-address');
                        }else{
                            this.$emit('save-edits', this.edit_address_section_inputs_data);
                        }
                    }else{
                    //2.2 if invalid address: show error in edit address section
                        this.view_history_stack.push('invalid-address');
                    }
                    this.loading = false;
                }catch(err){
                    console.log("EditAddressInputsSection saveEdits error ...");
                    this.has_error = true;
                    this.error_message = "Save address failed. Please try again.";
                    this.loading = false;
                }
            }
        }
    }
}
</script>

<style scoped>
.edit-address-content{
    display: flex;
    row-gap: 12px;
    flex-direction: column;
    padding:12px 28px 0px 28px;
}
.go-back-btn{
    width: fit-content;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor:pointer;
}
.go-back-btn-icon{
    width:20px;
    height: 20px;
}
.go-back-btn-text{
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}
.section{
    display: flex;
    flex-direction: column;
    row-gap:12px;
}
.section-title-bar{
    padding:12px 30px 11px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap:8px;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid  #BCCCDC;
}
.section-title{
    display: flex;
    column-gap: 8px;
    align-items: center;
}
.section-title-name{
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.175px;
}
.section-content{
    padding:0px 30px;
}
.edit-pickup-address .section-content{
    display:flex;
    flex-wrap: wrap;
    row-gap:16px;
    column-gap: 12px;
}
/* unit wrapper width */
/* for edit pickup address section inputs*/
.Address-Line-unit-wrapper{
    width:100%;
}
.Country-unit-wrapper,
.State-unit-wrapper,
.City-unit-wrapper,
.Zip-Code-unit-wrapper{
    width:calc(50% - 6px);
}
.no-state-applicable-checkbox-container{
    margin-top:8px;
}
/* checkbox */
:deep(.el-checkbox){
    margin-right:0px;
    --el-checkbox-input-width:18px;
    --el-checkbox-input-height:18px;
    --el-checkbox-border-radius: 4px;
    --el-checkbox-input-border: 2px solid #1C1B1F;
    --el-checkbox-bg-color: #FFF;
    --el-checkbox-checked-input-border-color:#20A8C3;
    --el-checkbox-input-border-color-hover:#1C1B1F;
    --el-checkbox-checked-bg-color:#20A8C3;
    --el-checkbox-checked-icon-color: #fff;
    --el-checkbox-checked-text-color: #1C1B1F;
    --el-checkbox-disabled-border-color:#979797;
    --el-checkbox-disabled-input-fill:#F2F2F2;
    --el-checkbox-disabled-checked-input-border-color:#979797;
    --el-checkbox-disabled-checked-input-fill:#979797;
    --el-checkbox-disabled-checked-icon-color:#fff;
    height:fit-content;
    padding:4px 0px;
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner::after){
    transform:rotate(45deg) scale(1.5) translate3D(1px,0px,0px);
}
:deep(.el-checkbox__label){
    padding:4px 8px;
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}
:deep(.el-checkbox.is-disabled.is-cheked .el-checkbox__label),
:deep(.el-checkbox.is-disabled .el-checkbox__label){
    color: #555555;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: 0.25px;
}
.ops-btns{
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;
    padding:24px;
}
.cancel-btn{
    width: 96px;
    padding:9px 23px;
    border-radius: 4px;
    border: 1px solid #1E4670;
    background: #FFF;
    color: #1E4670;
    text-align: center;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    cursor:pointer;
}
.confirm-btn{
    width: 96px;
    padding:9px 23px;
    border-radius: 4px;
    border: 1px solid #1E4670;
    background: #1E4670;
    color: #FFF;
    text-align: center;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    cursor:pointer;
}
.confirm-btn.disabled{
    background: #979797;
    border: 1px solid  #979797;
    cursor: not-allowed;
}

/* recommend-address view */
.guidance-text{
    color: #1C1B1F;
    font-family:'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}
.options-container{
    margin-top:12px;
}
:deep(.el-radio-group){
    width:100%;
    display: flex;
    align-items: stretch;
    justify-content:space-between;
    flex-wrap: nowrap;
    column-gap:12px;
}
:deep(.el-radio){
    width:100%;
    padding:11px 19px;
    border: 1px solid #BCCCDC;
    border-radius: 4px;

    display: flex;
    align-items:flex-start;
    height:100%;
    margin-right:0px;
}
:deep(.el-radio.is-checked){
    padding:10px 18px;
    border: 2px solid #20A8C3;
}
:deep(.el-radio__input){
    flex:0 0 auto;
    align-items: center;
    height:32px;
}
:deep(.el-radio__inner){
    border: 1.5px solid #1C1B1F;
    height:18px;
    width:18px;
}
:deep(.el-radio__inner::after){
    background-color: #20A8C3;
    width:9px;
    height:9px;
}
:deep(.el-radio__input.is-checked .el-radio__inner){
    border-color:#20A8C3;
    background: #FFFFFF;
}

:deep(.el-radio__label){
    flex:1;
    padding-left:0px;
}
.unit-option-container{
    width:calc(50% - 6px);
}
.invalid-address .unit-option-container{
    width:100%;
}
.option-type{
    padding:4px 8px;
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    white-space: normal;
    word-break: break-word;
}
.option-details{
    margin-top:4px;
    padding:8px 2px;
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    white-space: normal;
    word-break: break-word;
}
@media only screen and (max-width:767px){
    /* general layout */
    .edit-address-content{
        row-gap: 16px;
        padding-top:4px;
    }
    .section{
        row-gap:8px;
    }
    .section-title-bar{
        padding:8px 12px 7px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap:8px;
        border-radius: 4px 4px 0px 0px;
        border-bottom: 1px solid #D8D8D8;
    }
    .section-title{
        display: flex;
        column-gap: 8px;
        align-items: center;
    }
    .section-ops-btn{
        line-height: 20px;
        letter-spacing: 0.25px;
    }
    .section-content{
        padding:0px 12px;
    }
    .edit-pickup-address .section-content{
        padding:4px 12px;
        display:flex;
        flex-direction: column;
        row-gap:8px;
    }
    /* unit wrapper width */
    /* for edit pickup address section inputs*/
    .Address-Line-unit-wrapper,
    .Country-unit-wrapper,
    .State-unit-wrapper,
    .City-unit-wrapper,
    .Zip-Code-unit-wrapper{
        width:100%;
    }
    .no-state-applicable-checkbox-container{
        margin-top:4px;
    }
    .ops-btns{
        margin-top:20px;
        display: flex;
        justify-content: space-between;
        column-gap: 19px;
        padding:12px 28px;
    }
    .cancel-btn{
        width: 150px;
        padding:7px 20px;
        border-radius: 4px;
        border: 1px solid #1E4670;
        background: #FFF;
        color: #1E4670;
        text-align: center;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        cursor:pointer;
    }
    .invalid-address .cancel-btn{
        width:100%;
    }
    .confirm-btn{
        width: 150px;
        padding:7px 20px;
        border-radius: 4px;
        border: 1px solid #1E4670;
        background: #1E4670;
        color: #FFF;
        text-align: center;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        cursor:pointer;
    }
    /* recommend-address view */
    .options-container{
        margin-top:12px;
    }
    :deep(.el-radio-group){
        width:100%;
        display: flex;
        align-items: flex-start;
        justify-content:flex-start;
        flex-direction: column;
        row-gap:12px;
    }
    .unit-option-container{
        width:100%;
    }
    :deep(.el-radio){
        width:100%;
        padding:7px 11px;
    }
    :deep(.el-radio.is-checked){
        padding:6px 10px;
    }
    .option-details{
        margin-top:0px;
        padding:8px 4px;
    }
}
</style>