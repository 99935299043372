<template>
    <div class = "edit-contact-content">
        <div class = "go-back-btn" @click="clickGoBack()">
            <img src ="@/assets/FedexPickup/back_arrow.svg" class = "go-back-btn-icon"/>
            <div class = "go-back-btn-text">
                Back
            </div>
        </div>
        <div class = "section edit-pickup-address">
            <div class = "section-title-bar">
                <div class = "section-title">
                    <div class = "section-title-name">
                        Edit Contact
                    </div>
                </div>
            </div>
            <div class = "section-content">
                <SimplifiedNotification v-if = "disabled_save_btn" v-bind = "invalid_contact_notification_props" />
                <div v-for = 'input_unit_info in adjusted_edit_contact_inputs_structure_data'
                :key = "`${input_unit_info.input_id}-edit-contact-input-unit`"
                :class = "input_unit_info.unit_wrapper_classname">
                <!-- as the required fields are fixed in EditContactInputsSection.vue, no need to use  @remove-required-input-field-->
                    <ShippingAddressInputUnit 
                    v-model = "edit_contact_section_inputs_data[input_unit_info.input_id]"
                    v-bind = 'input_unit_info'
                    @add-required-input-field = "(input_id)=>handleAddRequiredField(input_id)"
                    @invalid-value = "(input_id, value) => handleAddInvalidInputValue(input_id)"
                    @remove-invalid-value = "(input_id, value) => handleRemoveInvalidInputValue(input_id)"/>
                </div>
            </div>
        </div>
    </div>
    <div class = "ops-btns">
        <div class = "cancel-btn" @click = "cancelEdits">Cancel</div>
        <div :class = "['confirm-btn',{'disabled':disabled_save_btn}]" @click = "saveEdits">Save</div>
    </div>
</template>

<script>
import ShippingAddressInputUnit from "@/components/shippingAddress/ShippingAddressInputUnit.vue";
import SimplifiedNotification from "@/components/commons/SimplifiedNotification.vue";
import { edit_contact_inputs_structure_data } from "@/components/fedexPickup/FedexPickupData/fedexPickupInputsStructure.js";
import { mapState, mapActions } from "pinia";
import { useFedexPickupStore } from "@/stores/FedexPickupStore.ts";
export default{
    components:{
        ShippingAddressInputUnit,
        SimplifiedNotification,
    },
    props:{
        contact_info:{
            type:Object,
            default:()=>{}
        }
    },
    setup(){
        const fedexPickupStore = useFedexPickupStore();
        return{
            fedexPickupStore,
        }
    },
    unmounted(){
        //clear edit_contact_invalid_value_input_fields
        this.fedexPickupStore.edit_contact_invalid_value_input_fields = [];
        //clear edit_contact_required_input_fields
        this.fedexPickupStore.edit_contact_required_input_fields = [];
    }, 
    data(){
        return{
            //for all views
            //NOTES:currently, EditContactInputsSection only have one view -- 'home-view'
            view_history_stack:['home-view'],
            edit_contact_section_inputs_data:JSON.parse(JSON.stringify(this.contact_info)),
            loading:false,
            edit_contact_inputs_structure_data,
        }
    },
    computed:{
        ...mapState(useFedexPickupStore,[
            'edit_contact_required_input_fields',
            'edit_contact_invalid_value_input_fields',
            'country_options_for_phone_input',
        ]),
        invalid_contact_notification_props(){
            return {
                title: "Contact Invalid",
                content: "Please confirm the accuracy of your Contact Information to proceed to selecting a Pickup Time and setting a Reminder.",
                build_in_theme: "red",
                with_close_btn: false,
            };
        },
        adjusted_edit_contact_inputs_structure_data(){
            //the inputs' structures are fixed, so directly reture edit_contact_inputs_structure_data, except for country options
            //NOTES: if any dynamic input's property in the future, add the logic here accordingly.
            //1. dynamic country options get from backend: phone is the second element in the structure array
            //NOTES: deep copy reactive data this.edit_contact_inputs_structure_data to obey the immutability expected in computed properties.
            //Computed properties should derive and return new values without altering the original data. 
            const output = JSON.parse(JSON.stringify(this.edit_contact_inputs_structure_data));
            output[1]['customized_props'] = {
                country_options: this.country_options_for_phone_input,
            };
            return output;
        },
        all_required_inputs_filled(){
            for(const field of this.edit_contact_required_input_fields){
                if(field == 'phone' && (!this.edit_contact_section_inputs_data.phone.phone_number || !this.edit_contact_section_inputs_data.phone.area_code)){
                    return false;
                }else if(!this.edit_contact_section_inputs_data[field]){
                    return false;
                }
            }
            return true;
        },
        no_invalid_values(){
            return this.edit_contact_invalid_value_input_fields.length == 0;
        },
        disabled_save_btn(){
            return !this.all_required_inputs_filled || !this.no_invalid_values;
        }
    },
    // watch:{
    //     edit_contact_section_inputs_data:{
    //         deep:true,
    //         handler(newVal){
    //             console.log('edit_contact_section_inputs_data change', newVal);
    //         }
    //     }
    // },
    emits:['cancel-edits', 'save-edits'],
    methods:{
        ...mapActions(useFedexPickupStore,[
            'updateEditContactRequiredInputFields',
            'updateEditContactInvalidValueInputFields',
        ]),
        // for all views
        clickGoBack(){
            console.log('view_history_stack in EditContactSection',this.view_history_stack);
            //if view_history_stack record more than one history
            if(this.view_history_stack.length >1){
                //go back to previous view
                this.view_history_stack.pop();
            }else{
            //if view_history_stack record only has one history record: already at the first view
                //close this section component
                this.$emit('cancel-edits');
            }
        },
        handleAddRequiredField(input_id){
            if(!this.edit_contact_required_input_fields.includes(input_id)){
                this.updateEditContactRequiredInputFields(input_id,'add');
            }
        },
        handleAddInvalidInputValue(input_id){
            if(!this.edit_contact_invalid_value_input_fields.includes(input_id)){
                this.updateEditContactInvalidValueInputFields(input_id,'add');
            }
        },
        handleRemoveInvalidInputValue(input_id){
            if(this.edit_contact_invalid_value_input_fields.includes(input_id)){
                this.updateEditContactInvalidValueInputFields(input_id,'remove');
            }
        },
        cancelEdits(){
            this.$emit('cancel-edits');
        },
        saveEdits(){
            //150 milliseconds delay, in case, user finish typing without pressing Enter or click outside the component
            //wait for the input's blur before save edits
            //NOTES: the AreaCodePhoneNumberInput's unfocus update time interval is 120 milliseconds, so this delay of save must longer than that.
            setTimeout(()=>{
                if(!this.disabled_save_btn){
                    this.$emit('save-edits', this.edit_contact_section_inputs_data);
                }
            },150);
        }
    }
}
</script>

<style scoped>
.edit-contact-content{
    display: flex;
    row-gap: 12px;
    flex-direction: column;
    padding:12px 28px 0px 28px;
}
.go-back-btn{
    width: fit-content;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor:pointer;
}
.go-back-btn-icon{
    width:20px;
    height: 20px;
}
.go-back-btn-text{
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}
.section{
    display: flex;
    flex-direction: column;
    row-gap:12px;
}
.section-title-bar{
    padding:12px 30px 11px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap:8px;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid  #BCCCDC;
}
.section-title{
    display: flex;
    column-gap: 8px;
    align-items: center;
}
.section-title-name{
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.175px;
}
.section-content{
    padding:0px 30px;
}
.edit-pickup-address .section-content{
    display:flex;
    flex-wrap: wrap;
    row-gap:16px;
    column-gap: 12px;
}
/* unit wrapper width */
/* for edit pickup address section inputs*/
.Email-unit-wrapper,
.Phone-unit-wrapper{
    width:calc(50% - 6px);
}
.ops-btns{
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;
    padding:24px;
}
.cancel-btn{
    width: 96px;
    padding:9px 23px;
    border-radius: 4px;
    border: 1px solid #1E4670;
    background: #FFF;
    color: #1E4670;
    text-align: center;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    cursor:pointer;
}
.confirm-btn{
    width: 96px;
    padding:9px 23px;
    border-radius: 4px;
    border: 1px solid #1E4670;
    background: #1E4670;
    color: #FFF;
    text-align: center;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    cursor:pointer;
}
.confirm-btn.disabled{
    background: #979797;
    border: 1px solid  #979797;
    cursor: not-allowed;
}
@media only screen and (max-width:767px){
    /* general layout */
    .edit-contact-content{
        row-gap: 16px;
        padding-top:4px;
    }
    .section{
        row-gap:8px;
    }
    .section-title-bar{
        padding:8px 12px 7px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap:8px;
        border-radius: 4px 4px 0px 0px;
        border-bottom: 1px solid #D8D8D8;
    }
    .section-title{
        display: flex;
        column-gap: 8px;
        align-items: center;
    }
    .section-ops-btn{
        line-height: 20px;
        letter-spacing: 0.25px;
    }
    .section-content{
        padding:0px 12px;
    }
    .edit-pickup-address .section-content{
        padding:4px 12px;
        display:flex;
        flex-direction: column;
        row-gap:8px;
    }
    /* unit wrapper width */
    /* for edit pickup address section inputs*/
    .Email-unit-wrapper,
    .Phone-unit-wrapper{
        width:100%;
    }
    .ops-btns{
        margin-top:20px;
        display: flex;
        justify-content: space-between;
        column-gap: 19px;
        padding:12px 28px;
    }
    .cancel-btn{
        width: 150px;
        padding:7px 20px;
        border-radius: 4px;
        border: 1px solid #1E4670;
        background: #FFF;
        color: #1E4670;
        text-align: center;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        cursor:pointer;
    }
    .confirm-btn{
        width: 150px;
        padding:7px 20px;
        border-radius: 4px;
        border: 1px solid #1E4670;
        background: #1E4670;
        color: #FFF;
        text-align: center;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        cursor:pointer;
    }
}
</style>