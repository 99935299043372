<template>
    <div v-if = "current_view == 'home-view'" v-loading = "whole_view_loading">
        <div :class = "['schedule-pickup-dialog-content',{'with-fixed-position-ops-btns':is_even_patietnt_id}]">
            <div v-if = "show_fullscreen" class = "go-back-btn" @click="()=>clickGoBack()">
                <img src ="@/assets/FedexPickup/back_arrow.svg" class = "go-back-btn-icon"/>
                <div class = "go-back-btn-text">
                    Back
                </div>
            </div>
            <SamplifiedNotification v-if = "show_confirm_error" ref="confirm_error_notification" 
            v-bind = "confirm_error_notification_props" 
            @close = "show_confirm_error=false"/>
            <div class = "section address-section">
                <div class = "section-title-bar">
                    <div class = "section-title">
                        <div class = "section-title-icon">
                            <img src = "@/assets/FedexPickup/address_icon.svg" />
                        </div>
                        <div class = "section-title-name">
                            Pickup Address
                        </div>
                    </div>
                    <div class = "section-ops-btn" @click = "editAddress">
                        Edit Address
                    </div>
                </div>
                <div class = "section-content">
                    <SamplifiedNotification v-if = "!is_current_address_valid" v-bind = "pickup_address_notification_props" />
                    <div class = "section-content-row">
                        {{ concate_address }}
                    </div>
                </div>
            </div>
            <div class = "section contact-section">
                <div class = "section-title-bar">
                    <div class = "section-title">
                        <div class = "section-title-icon">
                            <img src = "@/assets/FedexPickup/person_icon.svg" />
                        </div>
                        <div class = "section-title-name">
                            Contact
                        </div>
                    </div>
                    <div class = "section-ops-btn" @click = "editContact">
                        Edit Contact
                    </div>
                </div>
                <div class = "section-content">
                    <SamplifiedNotification v-if = "!is_current_contact_valid" v-bind = "pickup_contact_notification_props" />
                    <div class = "sync-to-reminder-checkbox-container">
                        <el-checkbox v-model="apply_contact_info_to_reminder">
                            Use same contact information for Reminders
                        </el-checkbox>
                    </div>
                    <div class = "section-content-row email">
                        <div class = "section-content-type">
                            Email:
                        </div>
                        <div class = "section-content-type-value">
                            {{ email ? email : "- - - - - -"}}
                        </div>
                    </div>
                    <div class = "section-content-row phone">
                        <div class = "section-content-type">
                            Phone:
                        </div>
                        <div class = "section-content-type-value">
                            {{ phone ? phone : "- - - - - -"}}
                        </div>
                    </div>
                </div>
            </div>
            <div class = "section pickup-time-section">
                <div class = "section-title-bar">
                    <div class = "section-title">
                        <div class = "section-title-icon">
                            <img src = "@/assets/FedexPickup/clock_icon.svg" />
                        </div>
                        <div class = "section-title-name">
                            Pickup Time
                        </div>
                    </div>
                </div>
                <div class = "section-content" v-loading = "pickup_time_section_loading">
                    <div v-for = 'input_unit_info in adjusted_pickup_time_section_inputs_structure_data'
                    :key = "`${input_unit_info.input_id}-fedex-pickup-time-input-unit`"
                    :class = "input_unit_info.unit_wrapper_classname">
                        <ShippingAddressInputUnit 
                        :ref = "input_unit_info.input_id"
                        v-model = "current_schedule_data.pickup_info[input_unit_info.input_id]"
                        v-bind = 'input_unit_info' :disabled="conditionalDisabledInput(input_unit_info.input_id)"
                        @add-required-input-field="(input_id)=>updatePickupTimeRequiredInputFields(input_id, 'add')"
                        @remove-required-input-field="(input_id)=>updatePickupTimeRequiredInputFields(input_id, 'remove')"
                        />
                    </div>
                </div>
            </div>
            <div class = "section reminder-section">
                <div class = "section-title-bar">
                    <div class = "section-title">
                        <div class = "section-title-icon">
                            <img src = "@/assets/FedexPickup/reminder_icon.svg" />
                        </div>
                        <div class = "section-title-name">
                            Reminder
                        </div>
                    </div>
                </div>
                <div class = "section-content">
                    <div class = "reminder-checkbox-wrapper">
                        <el-checkbox v-model="current_schedule_data.no_reminders_needed" :disabled = "reminder_checkbox_disbaled">
                            No reminders needed
                        </el-checkbox>
                    </div>
                    <div v-if = "!current_schedule_data.no_reminders_needed" class = "reminder-methods-container">
                        <div class = "method-section" >
                            <div class = "method-type">
                                Email
                            </div>
                            <div class = "method-type-data">
                                <ShippingAddressInputUnit 
                                v-model = "current_schedule_data.reminder.email" 
                                v-bind = "reminder_email_input_props" 
                                :disabled="reminder_checkbox_disbaled"
                                @invalid-value="(input_id,value)=>updateReminderSectionInvalidValueInputFields(input_id,'add')"
                                @remove-invalid-value="(input_id,value)=>updateReminderSectionInvalidValueInputFields(input_id,'remove')"/>
                            </div>
                            <div class = "alert-options-section">
                                <div class = "alert-options">
                                    <el-checkbox v-model="current_schedule_data.remind_by_email_one_day_before_pickup" :disabled = "reminder_checkbox_disbaled">
                                        1 day
                                    </el-checkbox>
                                    <el-checkbox v-model="current_schedule_data.remind_by_email_one_hour_before_pickup" :disabled = "reminder_checkbox_disbaled">
                                        1 hour
                                    </el-checkbox>
                                </div>
                                <div class = "more-text">
                                    before pickup
                                </div>
                            </div>
                        </div>
                        <div class = "method-section">
                            <div class = "method-type">
                                Phone
                            </div>
                            <div class = "method-type-data">
                                <ShippingAddressInputUnit 
                                v-model = "current_schedule_data.reminder.phone" 
                                v-bind = "reminder_phone_input_props" 
                                :disabled="reminder_checkbox_disbaled"
                                @invalid-value="(input_id,value)=>updateReminderSectionInvalidValueInputFields(input_id,'add')"
                                @remove-invalid-value="(input_id,value)=>updateReminderSectionInvalidValueInputFields(input_id,'remove')"/>
                            </div>
                            <div class = "alert-options-section">
                                <div class = "alert-options">
                                    <el-checkbox v-model="current_schedule_data.remind_by_phone_one_day_before_pickup" :disabled = "reminder_checkbox_disbaled">
                                        1 day
                                    </el-checkbox>
                                    <el-checkbox v-model="current_schedule_data.remind_by_phone_one_hour_before_pickup" :disabled = "reminder_checkbox_disbaled">
                                        1 hour
                                    </el-checkbox>
                                </div>
                                <div class = "more-text">
                                    before pickup
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div :class = "['ops-btns', {'fixed-position':is_even_patietnt_id}]">
            <div :class = "['cancel-btn', {'short-version':this.is_editing_existing_schedule}]" @click = "cancelEdits">Cancel</div>
            <div :class = "[
                'confirm-btn', 
                {'short-version':this.is_editing_existing_schedule}, 
                {'disabled': disable_confirm_btn}
            ]" 
            @click = "confirmEdits">
                {{ confirm_btn_text }}
            </div>
        </div>
    </div>
    <EditAddressInputsSection 
    v-if = "current_view == 'edit-address'" 
    :address_info = "current_pickup_address_object_for_this_schedule"
    @cancel-edits="cancelPickupAddressEdits"
    @save-edits="confirmPickupAddressEdits"
    />
    <EditContactInputsSection 
    v-if = "current_view == 'edit-contact'" 
    :contact_info = 'current_contact_object_for_this_schedule'
    @cancel-edits="cancelContactEdits"
    @save-edits="confirmContactEdits"
    /> 
</template>

<script>
import ShippingAddressInputUnit from "@/components/shippingAddress/ShippingAddressInputUnit.vue";
import EditAddressInputsSection from '@/components/fedexPickup/EditAddressInputsSection.vue';
import EditContactInputsSection from '@/components/fedexPickup/EditContactInputsSection.vue';
import SamplifiedNotification from "@/components/commons/SimplifiedNotification.vue";
import { pickup_time_section_inputs_structure_data } from "@/components/fedexPickup/FedexPickupData/fedexPickupInputsStructure.js";
import { useFedexPickupStore } from "@/stores/FedexPickupStore.ts";
import { mapState, mapActions } from "pinia";
import moment from "moment";
export default{
    props:{
        is_editing_existing_schedule:{
            type:Boolean,
            default:false,
        },
        schedule_info:{
            type:Object,
            default:()=>{},
        },
        show_fullscreen:{
            type:Boolean,
            default:false,
        }
    },
    components:{
        ShippingAddressInputUnit,
        SamplifiedNotification,
        EditAddressInputsSection,
        EditContactInputsSection,
    },
    async created(){
    // triggered every time when users open SchedulePickupDialog, as the v-if of each SchedulePickupDialog sync with its visibility
    // SchedulePickupContent is created every time when users open SchedulePickupDialog, and won't be unmounted until SchedulePickupDialog is closed.
        console.log('call backend to check address is valid and contact is valid', this.schedule_info);
        //if new schedule: need to firstly check validation of address and contact
        //NOTES:existing schedules definitely have valid contact and address
        if(!this.is_editing_existing_schedule){
            this.whole_view_loading = true;
            try{
                const { is_current_contact_valid, validation_details} = this.checkContactValidation(this.schedule_info.contact);
                this.is_current_contact_valid = is_current_contact_valid;
                this.is_prefilled_contact_phone_valid = validation_details.is_phone_valid;

                const { is_current_address_valid } = await this.checkAddressValidation(this.schedule_info.pickup_address, false);
                this.is_current_address_valid = is_current_address_valid;
                this.is_prefilled_address_valid = is_current_address_valid;
            }catch(err){
                console.log('checkAddressValidation or checkAddressValidation error', err);
            }
            //according to first validation check result, decide whether need to auto pop a dialog
            //if address is not valid:
            if(!this.is_current_address_valid){
                // we need to auto show the edit based on the view_history_stack:['home-view']'s default status
                this.view_history_stack.push('edit-address');
            }else if(!this.is_current_contact_valid){
            //if address is valid, but contact is not valid:
                // we need to auto show the edit based on the view_history_stack:['home-view']'s default status
                this.view_history_stack.push('edit-contact');
            }

            //initiate reminder section day and hour checkboxes
            //if phone is empty in area_code and phone_number: default uncheck both related day and hour checkboxes
            // console.log('initiate reminder section ', this.current_schedule_data);
            if(!this.current_schedule_data.reminder.phone.area_code && !this.current_schedule_data.reminder.phone.phone_number){
                this.current_schedule_data.remind_by_phone_one_day_before_pickup = false;
                this.current_schedule_data.remind_by_phone_one_hour_before_pickup = false;
            }
            //if email is empty: default uncheck both related day and hour checkboxes
            if(!this.current_schedule_data.reminder.email){
                this.current_schedule_data.remind_by_email_one_day_before_pickup = false;
                this.current_schedule_data.remind_by_email_one_hour_before_pickup = false;
            }
            this.whole_view_loading = false;
        }else{
        //if edit existing schedule: need to firstly get availability based on the current pickup_date and the current address,
            //1. call the backend api
            this.pickup_time_section_loading = true;
            const current_selected_date_object = moment(this.current_schedule_data.pickup_info.pickup_date);
            try{
                this.availabilities = await this.getAvailabilitiesBasedOnAddressAndDate(this.current_schedule_data.pickup_address, this.current_schedule_data.pickup_info.pickup_date);
                //2. find the matched date in availabilities 
                //NOTES: could have multiple matches, currently use the first found match
                for(const availability_unit of this.availabilities){
                    if(current_selected_date_object.isSame(moment(availability_unit.pickup_date), 'day')){
                        //store the selected availability's info
                        this.matched_availability_data = availability_unit;
                        break;
                    }
                }
                console.log('init availability', this.current_schedule_data.pickup_info.pickup_date, this.matched_availability_data);
                //2.1 if no match: nothing -> related computed data won't change
            }catch(err){
            //if error occurs when call availability api or during the following process
                console.log('init availability for editing existing schedule error', err);
            }
            this.pickup_time_section_loading = false;
        }
    },
    data(){
        return{
            //for all views
            //NOTES:currently, SchedulePickupContent only have one view -- 'home-view'
            view_history_stack:['home-view'],
            whole_view_loading:false,
            show_confirm_error:false,
            
            current_schedule_data: JSON.parse(JSON.stringify(this.schedule_info)),
            //default to be true, as the existing_schedule has valid address and contact for sure.
            is_current_address_valid:true,
            is_current_contact_valid:true,
            //cache of the origin value of is_current_address_valid and phone's valiation in s_current_contact_valid
            //used as part of params when call create new schedule's api
            //default to be true, as the existing_schedule has valid address and contact for sure.
            is_prefilled_address_valid:true,
            is_prefilled_contact_phone_valid:true,
            
            // for contact section
            apply_contact_info_to_reminder:false,

            // for pickup time section
            availabilities:[], // the availablilities of current address
            matched_availability_data:null,
            pickup_time_section_inputs_structure_data,
            pickup_time_required_input_fields:[],
            pickup_time_section_loading:false,

            // for reminder section
            // is_no_reminders_needed_checked:false,
            reminder_email_input_props:{
                input_id:"email",
                input_type:'email',
                reserve_input_value_when_invalid: true,
                check_input_value_valiation_when_mounted:true,
            },
            reminder_phone_input_props:{
                input_id:"phone",
                input_type:'customized_input',
                customized_component:'AreaCodePhoneNumberInput',
                //the event for the customized input itself
                event:{
                    emit_event:'blur',
                    triggered_parent_event:'handleAreaCodePhoneNumberInputChange',
                },
                customized_props:{
                    country_code: this.country_options_for_phone_input,
                },
                reserve_input_value_when_invalid:true,
                check_input_value_valiation_when_mounted:true,
            },
            reminder_section_invalid_value_input_fields:[],
            // is_email_one_day_before_checked:true,
            // is_email_one_hour_before_checked:true,
            // is_phone_one_day_before_checked:true,
            // is_phone_one_hour_before_checked:true,
        }
    },
    computed:{
        ...mapState(useFedexPickupStore,[
            'patient_id',
            'country_options_for_phone_input',
        ]),
        //for all views
        current_view(){
            //this.view_history_stack won't be empty
            return this.view_history_stack[this.view_history_stack.length-1];
        },
        is_even_patietnt_id(){
        //patient_id is always Number from backend
            return this.patient_id%2==0;
        },
        disable_confirm_btn(){
            return !this.all_required_informations_for_a_schedule_filled_and_valid 
            || !this.is_schedule_info_shown_in_dialog_changed;
        },
        all_required_informations_for_a_schedule_filled_and_valid(){
        //check whether all information needed to schedule a pickup or edit a pickup are gathered and valid
            return this.is_current_address_valid 
            && this.is_current_contact_valid 
            && this.are_all_required_pickup_time_section_inputs_filled
            && this.is_reminder_section_information_complete;
        },
        is_schedule_info_shown_in_dialog_changed(){
        //compare whether the content is changed (this.schedule_info vs this.current_schedule_info)
        //NOTES: if schedule new pickup, this value is definitely true after filled all required fields, since originally, there are some fields empty.
            //compare pick_address
            const original_address_object = this.schedule_info.pickup_address;
            const concate_original_address = original_address_object
            ? `${original_address_object.street}, ${original_address_object.city}, ${original_address_object.state_or_province_code}, ${original_address_object.postal_code}, ${original_address_object.country_code}`
            :'';
            if( concate_original_address != this.concate_address){
                return true;
            }
            //compare contact
            if(this.schedule_info.contact.email != this.current_schedule_data.contact.email){
            // email in contact section
                return true;
            }
            if(this.schedule_info.contact.phone.area_code != this.current_schedule_data.contact.phone.area_code || this.schedule_info.contact.phone.phone_number != this.current_schedule_data.contact.phone.phone_number){
            // phone in contact section
                return true;
            }
            //compare pickup_info
            if(this.schedule_info.pickup_info.pickup_date != this.current_schedule_data.pickup_info.pickup_date){
            // pickup_date (pickup date) in pickup time section
                return true;
            }
            if(this.schedule_info.pickup_info.ready_time != this.current_schedule_data.pickup_info.ready_time){
            // ready_time (earliest pickup time available)in pickup time section
                return true;
            }
            if(this.schedule_info.pickup_info.customer_close_time != this.current_schedule_data.pickup_info.customer_close_time){
            // customer_close_time (latest pickup time available) in pickup time section    
                return true;
            }
            if(this.schedule_info.pickup_info.remarks != this.current_schedule_data.pickup_info.remarks){
            // remarks (instructions ) in pickup time section     
                return true;
            }
            //compare reminder
            if(this.schedule_info.reminder.email != this.current_schedule_data.reminder.email){
            //email in reminder section
                return true;
            }
            if(this.schedule_info.reminder.phone.area_code != this.current_schedule_data.reminder.phone.area_code || this.schedule_info.reminder.phone.phone_number != this.current_schedule_data.reminder.phone.phone_number){
            //phone in reminder section
                return true;
            }
            if(
                this.schedule_info.no_reminders_needed!=this.current_schedule_data.no_reminders_needed 
                || this.schedule_info.remind_by_email_one_day_before_pickup!=this.current_schedule_data.remind_by_email_one_day_before_pickup
                || this.schedule_info.remind_by_email_one_hour_before_pickup!=this.current_schedule_data.remind_by_email_one_hour_before_pickup
                || this.schedule_info.remind_by_phone_one_day_before_pickup!=this.current_schedule_data.remind_by_phone_one_day_before_pickup
                || this.schedule_info.remind_by_phone_one_hour_before_pickup!=this.current_schedule_data.remind_by_phone_one_hour_before_pickup
            ){
            // all checkboxes related data in reminder section
                return true;
            }
            return false;
        },
        confirm_error_notification_props(){
            return this.is_editing_existing_schedule
            ?
            {
                title: "Failed",
                content: "Due to a system issue, we were unable to book a new pickup as requested. We have retained the existing appointment for your convenience. Please try again later.",
                build_in_theme: "red",
                with_close_btn: true,
            }
            :
            {
                title: "Failed",
                content: "Due to the system issue, we were unable to book a new pickup as requested. Please try again later.",
                build_in_theme: "red",
                with_close_btn: true,
            };
        },
        //for home-view: address section
        current_pickup_address_object_for_this_schedule(){
            return this.current_schedule_data.pickup_address;
        },
        pickup_address_notification_props(){
            return   {
                title: "Address Invalid",
                content: "Please confirm the accuracy of your Pickup Address to proceed to selecting a Pickup Time and setting a Reminder.",
                build_in_theme: "red",
                with_close_btn: false,
            };
        },
        concate_address(){
            const address_data = this.current_schedule_data.pickup_address;
            return address_data 
            ? 
            `${address_data.street}, ${address_data.city}, ${address_data.state_or_province_code}, ${address_data.postal_code}, ${address_data.country_code}`
            :
            '';
        },
        //for home-view: contact section
        current_contact_object_for_this_schedule(){
            return this.current_schedule_data.contact;
        },
        pickup_contact_notification_props(){
            return   {
                title: "Contact Invalid",
                content: "Please confirm the accuracy of your Contact Information to proceed to selecting a Pickup Time and setting a Reminder.",
                build_in_theme: "red",
                with_close_btn: false,
            };
        },
        email(){
            return this.current_schedule_data.contact.email;
        },
        phone(){
            var output = '';
            if(this.current_schedule_data.contact.phone.area_code){
                output += `+${this.current_schedule_data.contact.phone.area_code} `;
            }else{
                output += `+N/A `;
            }

            if(this.current_schedule_data.contact.phone.phone_number){
                output += this.current_schedule_data.contact.phone.phone_number;
            }else{
                output += 'N/A'
            }
            return output;
        },
        //for home-view: pickup time section
        adjusted_pickup_time_section_inputs_structure_data(){
        // the dropdown options of ready_time and latest_time are dynamic
        // so the changes of ready_time_options and latest_time_options will be directly shown from ready_time's dropdown_options and latest_time's dropdown_options
            return this.pickup_time_section_inputs_structure_data.filter(input_unit_info => {
                // sync ready_time's dropdown_options based on this.ready_time_options, formatted item with label and value fields
                if(input_unit_info.input_id == "ready_time"){
                    input_unit_info.dropdown_options = this.ready_time_options.map(item => {
                        return {
                            label: moment(item, 'HH:mm:ss').format('hh:mm A'),
                            // value: item,
                            value: moment(item, 'HH:mm:ss').format('hh:mm A'),
                        }
                    });
                }
                // sync latest_time's dropdown_options based on this.latest_time_options, formatted item with label and value fields
                if(input_unit_info.input_id == "customer_close_time"){
                    input_unit_info.dropdown_options = this.latest_time_options.map(item => {
                        return {
                            label: moment(item, 'HH:mm:ss').format('hh:mm A'),
                            // value: item,
                            value: moment(item, 'HH:mm:ss').format('hh:mm A'),
                        }
                    });
                }
                return input_unit_info;
            });
        },
        ready_time_options(){
            return this.matched_availability_data && this.matched_availability_data.ready_time_options 
            ?
            this.matched_availability_data.ready_time_options
            :
            [];
        },
        latest_time_options(){
            return this.matched_availability_data && this.matched_availability_data.latest_time_options 
            ?
            (
                //if ready_time has a selected option
                this.current_ready_time 
                ?
                // filter latest_time's options based on current_ready_time and access_time in availabilities
                this.filterLatestTimeOptions(this.matched_availability_data.latest_time_options, this.current_ready_time, this.access_time_in_minute)
                :
                this.matched_availability_data.latest_time_options
            )
            :
            [];
        },
        access_time_in_minute(){
            return this.matched_availability_data 
            ?
            this.matched_availability_data.access_time.hours*60 + this.matched_availability_data.access_time.minutes
            :
            0;
        },
        current_pickup_date(){
            return this.current_schedule_data.pickup_info['pickup_date'];
        },
        current_ready_time(){
            return this.current_schedule_data.pickup_info['ready_time'];
        },
        current_latest_time(){
            return this.current_schedule_data.pickup_info['customer_close_time'];
        },
        conditionalDisabledInput(){
            return (input_id)=>{
                if(input_id == "pickup_date"){
                    return !(this.is_current_address_valid && this.is_current_contact_valid);
                }else{
                    return !(this.is_current_address_valid && this.is_current_contact_valid && this.current_schedule_data.pickup_info.pickup_date);
                }
            }          
        },
        are_all_required_pickup_time_section_inputs_filled(){
            for (const input_id of this.pickup_time_required_input_fields){
                if(!this.current_schedule_data.pickup_info[input_id]){
                   return false;
                }
            }
            return true;
        },
        //for home-view: reminder section
        reminder_checkbox_disbaled(){
            return !(this.is_current_address_valid && this.is_current_contact_valid);
        },
        reminder_email(){
            return this.current_schedule_data.reminder.email;
        },
        reminder_phone(){
            return this.current_schedule_data.reminder.phone;
        },
        is_reminder_section_information_complete(){
            return this.current_schedule_data.no_reminders_needed
            ?
            true
            :
            (
                this.reminder_section_invalid_value_input_fields.length == 0 
                && 
                (!(this.current_schedule_data.remind_by_phone_one_day_before_pickup || this.current_schedule_data.remind_by_phone_one_hour_before_pickup) || (this.reminder_phone.area_code && this.reminder_phone.phone_number))
                && 
                (!(this.current_schedule_data.remind_by_email_one_day_before_pickup || this.current_schedule_data.remind_by_email_one_hour_before_pickup) || this.reminder_email)
            );
        },
        is_reminder_section_info_same_as_contact(){
            if(
                this.current_schedule_data.reminder.email != this.current_schedule_data.contact.email
                ||
                `${this.current_schedule_data.reminder.phone.area_code} ${this.current_schedule_data.reminder.phone.phone_number}` != `${this.current_schedule_data.contact.phone.area_code} ${this.current_schedule_data.contact.phone.phone_number}`
            ){
                return false;
            }
            return true;
        },
        //for home-view: operation buttons
        confirm_btn_text(){
            return this.is_editing_existing_schedule ? 'Confirm': 'Schedule Pickup';
        },
    },
    watch:{
        //for all views
        schedule_info(newVal){
            console.log('SchedulePickupContent schedule_info change', newVal);
            this.current_schedule_data = JSON.parse(JSON.stringify(newVal));
        },
        concate_address(){
        //triggered when the address_object for address field of current_schedule_data changed
            //1. empty matched_availability_data
            this.matched_availability_data = null;
            //2. empty pickup_date in pickup time section:
            //NOTES: all the other inputs(ready_time, latest_time, comment) will be also empty within watcher of current_pickup_date;
            this.current_schedule_data.pickup_info['pickup_date'] = '';
        },
        //for home-view: pickup time section
        async current_pickup_date(newVal, oldVal){
        //triggered when this.current_schedule_data.pickup_info['pickup_date'] changes
            console.log("current_pickup_date change", newVal, oldVal);
            //1. clear matched_availability_data for following matching process, and the other inputs (ready_time, latest_time, comment)
            this.matched_availability_data = null;
            this.current_schedule_data.pickup_info['ready_time'] = '';
            this.current_schedule_data.pickup_info['customer_close_time'] = '';
            this.current_schedule_data.pickup_info['remarks'] = '';
            //only get availability and reset error notes when pickup_date input has a value
            //since the pickup_date input will be cleared with an error msg when error occurs or when address is changed, which will also trigger this function,
            //but we don't want to call availabilities api or reset has_error to be false (if true) at that time. 
            if(newVal){
                //2. start loading in pickup time section 
                this.pickup_time_section_loading = true;
                //3. reset the error-related reactive data for pickup_date input
                this.$refs['pickup_date'][0].$data.has_error = false;
                //4. call the backend api to refreshed the availabilities based on the current address and selected date
                const current_selected_date_object = moment(newVal);
                try{
                    this.availabilities = await this.getAvailabilitiesBasedOnAddressAndDate(this.current_schedule_data.pickup_address, newVal);
                    //5. find the matched date in availabilities 
                    //NOTES: could have multiple matches, currently use the first found match
                    for(const availability_unit of this.availabilities){
                        if(current_selected_date_object.isSame(moment(availability_unit.pickup_date), 'day')){
                            //store the selected availability's info
                            this.matched_availability_data = availability_unit;
                            break;
                        }
                    }
                    console.log('current_pickup_date change', newVal, this.matched_availability_data);
                    //5.1 if no match: 
                    if(!this.matched_availability_data){
                        // highlight the pickup_date input and show error notes "No pickup time slots are available for the selected date. Please choose a different date."
                        this.$refs['pickup_date'][0].$data.has_error = true;
                        this.$refs['pickup_date'][0].$data.error_text = "No pickup time slots are available for the selected date. Please choose a different date.";
                        // empty option for ready_time's option and latest_time's option (automatically assigned via computed due to the change of this.matched_availability_data)
                    }
                    //5.2 if yes : 
                    // assign the corresponding options for ready_time's option and latest_time's option (automatically assigned via computed due to the change of this.matched_availability_data)
                    this.pickup_time_section_loading = false;
                }catch(err){
                //if error occurs when call availability api or during the following process
                    console.log('getAvailabilitiesBasedOnAddressAndDate error', err);
                    //clear pickup_date input in pickup time section
                    this.current_schedule_data.pickup_info['pickup_date'] = "";
                    //wait a while for ShippingAddressInputUnit's watcher of modalValue ends related functions, then set error-related reactive data
                    //NOTES: has_error will be set to be false, as long as modalValue changes
                    setTimeout(()=>{
                        this.$refs['pickup_date'][0].$data.has_error = true;
                        this.$refs['pickup_date'][0].$data.error_text = "Server error. Failed to get availability information. Please try again.";
                        //continue loading till the error msg is set.
                        this.pickup_time_section_loading = false;
                    },500);
                }
            }
        },
        current_ready_time(newVal){
            console.log('current_ready_time', newVal);
            //1. clear latest_time input
            this.current_schedule_data.pickup_info['customer_close_time'] = '';
            //2. filter latest_time's options based on current_ready_time and access_time in availabilities (automatically assigned via computed due to the change of this.current_ready_time)
        },
        current_latest_time(newVal){
            console.log('current_latest_time change', newVal, this.current_schedule_data, this.schedule_info);
        },
        //for home-view: reminder section
        apply_contact_info_to_reminder(newVal){
            //if check apply_contact_info_to_reminder: update reminder field in current_schedule_data based on the contact field
            if(newVal){
                this.current_schedule_data.reminder =JSON.parse(JSON.stringify(this.current_schedule_data.contact));
            }
        },
        is_reminder_section_info_same_as_contact(newVal){
        //by default, the reminder section's information is the same as the contact section's
        //so generally, the first time that this value changes should be from 'true' to 'false'
            //if the information are not same in both sections:
            if(!newVal){
                //uncheck the apply_contact_info_to_reminder checkbox, if it is checked
                if(this.apply_contact_info_to_reminder){
                    this.apply_contact_info_to_reminder = false;
                }
            }
        }
    },
    emits:['edit-address','edit-contact','cancel-edits','confirm-edits'],
    methods:{
        ...mapActions(useFedexPickupStore,[
            'checkContactValidation',
            'checkAddressValidation',
            'getAvailabilitiesBasedOnAddressAndDate',
            'createNewSchedule',
        ]),
        //for all views
        clickGoBack(){
            //if view_history_stack record more than one history
            if(this.view_history_stack.length >1){
                //go back to previous view
                this.view_history_stack.pop();
            }else{
            //if view_history_stack record only has one history record: already at the first view
                //close this section component
                this.$emit('cancel-edits');
            }
        },
        // for home-view: address section
        editAddress(){
            // this.$emit('edit-address', this.address);
            this.view_history_stack.push('edit-address');
        },
        cancelPickupAddressEdits(){
        //triggered when the user doesn't want to save any edits and want to close the EditAddressSection
            //1. close edit address section and back to dialog home view
            //NOTES:the previous view should be 'home-view', since EditAddressSection can only be opened in home-view
            this.view_history_stack.pop();
        },
        confirmPickupAddressEdits(cur_address_inputs_data_under_edit_address_section){
        //triggered when the address in EditAddressSection is valid and the user wants to save the chosen one (entered or recommended)
            //1. close edit address section and back to dialog home view (the previous view should be 'home-view', since EditAddressSection can only be opened in home-view)
            this.view_history_stack.pop();
            //2. update address field in current_schedule_data
            this.current_schedule_data.pickup_address = cur_address_inputs_data_under_edit_address_section; 
            //3. update is_current_address_valid to be true
            this.is_current_address_valid = true;
            //4. if is_current_contact_valid == false: auto pop up edit contact dialog after save valid address
            if(!this.is_current_contact_valid){
                this.view_history_stack.push('edit-contact');
            }
        },
        // for home-view: contact section
        editContact(){
            // this.$emit('edit-contact', this.contact);
            this.view_history_stack.push('edit-contact');
        },
        cancelContactEdits(){
        //triggered when the user doesn't want to save any edits in EditContactSection and want to close the EditContactSection
            //1. close Contact section and back to dialog home view
            //NOTES:the previous view should be 'home-view', since EditContactSection can only be opened in home-view
            this.view_history_stack.pop();
        },
        confirmContactEdits(cur_contact_inputs_data_under_contact_section){
        //triggered when the email and phone in EditContactSection is valid and the user wants to save them.
            //1. close Contact section and back to dialog home view
            //NOTES:the previous view should be 'home-view', since EditContactSection can only be opened in home-view
            this.view_history_stack.pop();
            //2. update contact field in current_schedule_data
            this.current_schedule_data.contact = cur_contact_inputs_data_under_contact_section;
            //3. update is_current_contact_valid to be true
            this.is_current_contact_valid = true;
            //4. if check apply_contact_info_to_reminder: update reminder field in current_schedule_data
            if(this.apply_contact_info_to_reminder){
                this.current_schedule_data.reminder = JSON.parse(JSON.stringify(cur_contact_inputs_data_under_contact_section));
            }
        },
        // for home-view: pickup time section
        updatePickupTimeRequiredInputFields(input_id, operation_type){
            if(operation_type == 'add'){
                if(!this.pickup_time_required_input_fields.includes(input_id)){
                    this.pickup_time_required_input_fields.push(input_id);
                }
            }else if(operation_type=='remove'){
                if(this.pickup_time_required_input_fields.includes(input_id)){
                    this.pickup_time_required_input_fields = this.pickup_time_required_input_fields.filter(item => item != input_id);
                }
            }
        },
        filterLatestTimeOptions(all_latest_time_options, current_ready_time, access_time_in_minute){
            const current_ready_time_obj = moment(current_ready_time, 'hh:mm A');
            return all_latest_time_options.filter(time_option => {
                const option_time_obj = moment(time_option, 'HH:mm:ss');
                // Calculate the difference in minutes between the option time and the current_ready_time
                const time_difference_in_minutes = option_time_obj.diff(current_ready_time_obj, 'minutes');
                // console.log(current_ready_time_obj,"current_ready_time_obj")
                // console.log(option_time_obj,"option_time_obj")
                // console.log(time_difference_in_minutes,"time_difference_in_minutes")
                return time_difference_in_minutes >= access_time_in_minute;
            });
        },
        // for home-view: reminder section
        updateReminderSectionInvalidValueInputFields(input_id,operation_type){
            if(operation_type=='add'){
                if(!this.reminder_section_invalid_value_input_fields.includes(input_id)){
                    this.reminder_section_invalid_value_input_fields.push(input_id);
                }
            }
            if(operation_type=='remove'){
                if(this.reminder_section_invalid_value_input_fields.includes(input_id)){
                    this.reminder_section_invalid_value_input_fields = this.reminder_section_invalid_value_input_fields.filter(item=>item!=input_id);
                }
            }
        },
        cancelEdits(){
            this.$emit('cancel-edits');
        },
        async confirmEdits(){
            if(!this.disable_confirm_btn){
                try{
                    this.show_confirm_error = false;
                    this.whole_view_loading = true;
                    //call api to create the new schedule based on current info
                    //the params are conditional; new_schedule_info is the new schedule's info returned by the backend
                    const new_schedule_info = await this.createNewSchedule(
                        {...this.current_schedule_data},
                        // save_patient_address if prefilled address is invalid
                        !this.is_prefilled_address_valid,
                        // save_patient_contact_phone if prefilled contact phone is invalid
                        !this.is_prefilled_contact_phone_valid,
                    );
                    this.whole_view_loading = false;
                    this.$emit('confirm-edits', new_schedule_info);
                }catch(err){
                    this.show_confirm_error = true;
                    this.$nextTick(()=>{
                        this.$refs['confirm_error_notification'].$el.scrollIntoView({ behavior: 'smooth', block:'center' });
                        this.whole_view_loading = false;
                    });
                }
            }
        }
    }
}
</script>

<style scoped>
/* general layout */
.schedule-pickup-dialog-content{
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    padding:12px 28px 0px 28px;
}
.go-back-btn{
    width: fit-content;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor:pointer;
}
.go-back-btn-icon{
    width:20px;
    height: 20px;
}
.go-back-btn-text{
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}
.section{
    display: flex;
    flex-direction: column;
    row-gap:12px;
}
.section-title-bar{
    padding:12px 30px 11px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap:8px;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid  #BCCCDC;
}
.section-title{
    display: flex;
    column-gap: 8px;
    align-items: center;
}
.section-title-name{
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.175px;
}
.section-ops-btn{
    color: #20A8C3;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-decoration: underline;
    cursor:pointer;
}
.section-content{
    padding: 8px 30px;
}
.address-section .section-content,
.contact-section .section-content{
    display: flex;
    flex-direction: column;
    gap:12px;
}
.section-content-row{
    display: flex;
    align-items: center;
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: 0.25px;
}
.section-content-type{
    width:100px;
}
.ops-btns{
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;
    padding:24px;
}
.cancel-btn{
    width: 155px;
    padding:9px 23px;
    border-radius: 4px;
    border: 1px solid #1E4670;
    background: #FFF;
    color: #1E4670;
    text-align: center;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    cursor:pointer;
}
.confirm-btn{
    width: 155px;
    padding:9px 23px;
    border-radius: 4px;
    border: 1px solid #1E4670;
    background: #1E4670;
    color: #FFF;
    text-align: center;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    cursor:pointer;
}
.confirm-btn.disabled{
    background: #979797;
    border: 1px solid  #979797;
    cursor: not-allowed;
}
.cancel-btn.short-version
.confirm-btn.short-version{
    width:99px;
}
/* pickup time section */
.pickup-time-section .section-content{
    padding:0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 16px;
}
/* unit wrapper width */
/* for pickup time section */
.Pickup-Date-unit-wrapper,
.Instructions-Comments-unit-wrapper,
.Ready-Time-unit-wrapper,
.Latest-Time-unit-wrapper{
    width:calc(50% - 6px);
}
/* reminder section */
.reminder-section .section-content{
    padding:0px 30px;
    display: flex;
    flex-direction: column;
    row-gap:16px;
}
.reminder-methods-container{
    display: flex;
    flex-direction: column;
    row-gap:16px;
}
/* checkbox in dialog */
:deep(.el-checkbox){
    margin-right:0px;
    --el-checkbox-input-width:18px;
    --el-checkbox-input-height:18px;
    --el-checkbox-border-radius: 4px;
    --el-checkbox-input-border: 2px solid #1C1B1F;
    --el-checkbox-bg-color: #FFF;
    --el-checkbox-checked-input-border-color:#20A8C3;
    --el-checkbox-input-border-color-hover:#1C1B1F;
    --el-checkbox-checked-bg-color:#20A8C3;
    --el-checkbox-checked-icon-color: #fff;
    --el-checkbox-checked-text-color: #1C1B1F;
    --el-checkbox-disabled-border-color:#979797;
    --el-checkbox-disabled-input-fill:#F2F2F2;
    --el-checkbox-disabled-checked-input-border-color:#979797;
    --el-checkbox-disabled-checked-input-fill:#979797;
    --el-checkbox-disabled-checked-icon-color:#fff;
    height:fit-content;
    padding:4px 0px;
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner::after){
    transform:rotate(45deg) scale(1.5) translate3D(1px,0px,0px);
}
:deep(.el-checkbox__label){
    padding:4px 8px;
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}
:deep(.el-checkbox.is-disabled.is-cheked .el-checkbox__label),
:deep(.el-checkbox.is-disabled .el-checkbox__label){
    color: #555555;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: 0.25px;
}

.method-section{
    display: flex;
    column-gap: 12px;
    align-items: center;
    justify-content: space-between;
}
.method-type{
    flex:0 0 auto;
    width:100px;
    padding:8px 19px;
    border-radius: 4px;
    border: 1px solid #BCCCDC;
    background: #F2F2F2;
    color: #555;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}
.method-type-data{
    flex:1;
    height:42px;
}
.alert-options-section{
    flex:0 0 auto;
    display: flex;
    align-items: center;
}
.alert-options{
    display: flex;
    flex-direction: column;
}
.more-text{
    color: #1C1B1F;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}

@media only screen and (max-width:767px){
    /* general layout */
    .schedule-pickup-dialog-content{
        padding-top: 4px;
        row-gap: 16px;
    }
    .schedule-pickup-dialog-content.with-fixed-position-ops-btns{
        padding-bottom: 90px;
    }
    .section{
        row-gap:8px;
    }
    .section-title-bar{
        padding:8px 12px 7px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap:8px;
        border-radius: 4px 4px 0px 0px;
        border-bottom: 1px solid #D8D8D8;
    }
    .section-title{
        display: flex;
        column-gap: 8px;
        align-items: center;
    }
    .section-ops-btn{
        line-height: 20px;
        letter-spacing: 0.25px;
    }
    .section-content{
        padding:0px 12px;
    }
    .section-content-row{
        padding:4px 0px;
        color: #1C1B1F;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; 
        letter-spacing: 0.25px;
    }
    .ops-btns{
        margin-top:20px;
        display: flex;
        justify-content: space-between;
        column-gap: 19px;
        padding:12px 28px;
    }
    .ops-btns.fixed-position{
        position: fixed;
        bottom:0px;
        z-index: 100;
        width: 100%;
        margin-top:0px;

        display: flex;
        justify-content: space-between;
        column-gap: 19px;
        padding:20px 28px;

        background: #FFF;
        box-shadow: 0px -4px 7px 0px rgba(0, 0, 0, 0.15);
    }
    .cancel-btn{
        max-width: 150px;
        width:calc(50% - 9px);
        padding:7px 20px;
        border-radius: 4px;
        border: 1px solid #1E4670;
        background: #FFF;
        color: #1E4670;
        text-align: center;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        cursor:pointer;
    }
    .confirm-btn{
        max-width: 150px;
        width:calc(50% - 9px);
        padding:7px 3px;
        border-radius: 4px;
        border: 1px solid #1E4670;
        background: #1E4670;
        color: #FFF;
        text-align: center;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        cursor:pointer;
    }
    /* pickup section */
    .pickup-time-section .section-content{
        padding:4px 12px;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }
    /* unit wrapper width */
    /* for pickup time section */
    .Pickup-Date-unit-wrapper,
    .Instructions-Comments-unit-wrapper,
    .Ready-Time-unit-wrapper,
    .Latest-Time-unit-wrapper{
        width:100%;
    }
    /* reminder section */
    .reminder-section .section-content{
        padding:0px 12px;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }
    .reminder-methods-container{
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }
    .method-section{
        align-items: flex-start;
        flex-wrap: wrap;
        row-gap:8px;
        column-gap: 8px;
    }
    .method-type{
        width:65px;
        padding:7px 11px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
    }
    .method-type-data{
        height:fit-content;
    }
    .alert-options-section{
        width:100%;
    }
    .alert-options{
        flex-direction: row;
        column-gap: 8px;
    }
    .more-text{
        padding:4px 12px;
    }
}
</style>