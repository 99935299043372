import { COUNTRIES } from "./countries.js";
import { STATES } from "./usStates.js";
export const basic_information_inputs_structure_data = [
    {
        input_id:'name',
        input_display_name:'Name',
        required:true,
        input_type:'input',
        disabled:true,
        unit_wrapper_classname:'Name-unit-wrapper',
    },
    {
        input_id:'birthdate',
        input_display_name:'DOB',
        required:true,
        input_type:'date-input-with-auto-format',
        unit_wrapper_classname:'DOB-unit-wrapper',
    },
    {
        input_id:'gender',
        input_display_name:'Gender',
        required:true,
        input_type:'selector',
        dropdown_options:[
            {
                label:"Male",
                value:"Male"
            },
            {
                label:"Female",
                value:"Female"
            }
        ],
        unit_wrapper_classname:'Gender-unit-wrapper',
    },
    {
        input_id:'phone',
        input_display_name:'Phone',
        input_type:'input',
        unit_wrapper_classname:'Phone-unit-wrapper',
    },

];

export const shipping_address_inputs_structure_data = [
    {
        input_id:'name',
        input_display_name:'Recipient Name',
        input_display_note:'(To change the recipient name, please contact us at 866-364-0963)',
        required:true,
        input_type:'input',
        disabled:true,
        unit_wrapper_classname:'Name-unit-wrapper',
    },
    {
        input_id:'street',
        input_display_name:'Address Line',
        required: true,
        input_type:'customized_input',
        customized_component:'AddressAutoFilledInput',
        event:{
            emit_event:'selected-option-change',
            triggered_parent_event:'autofillAdressComponentSelectedOptionChange',
        },
        unit_wrapper_classname:'Address-Line-unit-wrapper',
    },
    {
        input_id:'zipcode',
        input_display_name:'Zip Code',
        required:true,
        input_type:'input',
        unit_wrapper_classname:'Zip-Code-unit-wrapper',
    },
    {
        input_id:'city',
        input_display_name:'City',
        required:true,
        input_type:'input',
        unit_wrapper_classname:'City-unit-wrapper',
    },
    {
        input_id:'state',
        input_display_name:'State',
        required:true,
        input_type:'selector',
        dropdown_options:getUSAStateDropwdownOptions(STATES),
        unit_wrapper_classname:'State-unit-wrapper',
    },
    {
        input_id:'country',
        input_display_name:'Country',
        required: true,
        input_type:'selector',
        dropdown_options:getCountryDropwdownOptions(COUNTRIES),
        unit_wrapper_classname:'Country-unit-wrapper',
    },
    
];
export function getCountryDropwdownOptions(countries){
    const output = countries.map((item)=>{
        return {
            label:`${item.name}(${item.code})`,
            value: item.code
        };
    })
    return output;
}
export function getUSAStateDropwdownOptions(states){
    const output = states.map((item)=>{
        return {
            label:`${item.name}(${item.abbreviation})`,
            value: item.abbreviation
        };
    })
    return output;
}
//find the corresponding country's country code based on param val
export function getCountryCode(val){
    //if val has two char -> assume this is the country code
    if(val.length==2){
        return val.toUpperCase();
    }else{
        for(const country of COUNTRIES){
            const upperCaseVal = val.toUpperCase();
            if(country.name.toUpperCase() == upperCaseVal || country.code == upperCaseVal){
                return country.code;
            }
        }
        return val;
    }
}

// export const units_wrappers_styles = {
//     patient_name:{
//         width:'100%'
//     },
//     street:{
//         width:'100%'
//     },
//     zipcode:{
//         width:'48%'
//     },
//     city:{
//         width:'48%'
//     },
//     state:{
//         width:'48%'
//     },
//     country:{
//         width:'48%'
//     }
// }