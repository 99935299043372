<template>
    <div v-if = "loading" class = "fedex-pickup-page"></div>
</template>

<script>
import { useFedexPickupStore } from '@/stores/FedexPickupStore.ts';
import { mapActions } from 'pinia';
export default{
    mounted(){
        this.loading = true;
        //set data in ShippingAddressStore: for related apis after redirect to /informationLanding
        this.storeAllQueryInfomation(this.$route.query);
        //save query info in localStorage: for user refresh on /informationLanding
        localStorage.setItem('fedex_pickup_query',JSON.stringify(this.$route.query));
        this.$router.push('/fedexPickup/search');
        this.loading = false;
    },
    data(){
        return{
            loading:false,
        }
    },
    methods:{
        ...mapActions(useFedexPickupStore,[
            'storeAllQueryInfomation'
        ])
    }
}
</script>

<style scoped>
.fedex-pickup-page{
    height:100%;
}
@media only screen and (max-width:767px){
    .fedex-pickup-page{
        padding-top:8px;
        height: 100%;
    } 
}
</style>